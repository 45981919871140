/*!
 * /*
 * taucharts@2.8.0 (2020-02-26)
 * Copyright 2020 Targetprocess, Inc.
 * Licensed under Apache License 2.0
 * * /
 * 
 */
.tau-chart__layout {
  line-height: 1;
  font-family: Helvetica Neue, Segoe UI, Open Sans, Ubuntu, sans-serif;
}
.tau-chart__layout text {
  font: normal 13px Helvetica Neue, Segoe UI, Open Sans, Ubuntu, sans-serif;
}
.tau-chart__chart {
  font-family: Helvetica Neue, Segoe UI, Open Sans, Ubuntu, sans-serif;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.tau-chart__layout {
  display: -webkit-box;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: transparent;
  color: #333;
}
.tau-chart__layout__header {
  -ms-flex: 0 0.1 auto;
  -webkit-box-flex: 0 0.1 auto;
  flex: 0 0.1 auto;
  position: relative;
}
.tau-chart__layout__container {
  display: -webkit-box;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 100%;
}
.tau-chart__layout__footer {
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0 1 auto;
  flex: 0 1 auto;
}
.tau-chart__layout__sidebar {
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0 1 auto;
  flex: 0 1 auto;
}
.tau-chart__layout__content {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden;
}
.tau-chart__layout__sidebar-right {
  position: relative;
  overflow: hidden;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0 0 auto;
  flex: 0 0 auto;
}
.tau-chart__layout__sidebar-right__wrap {
  max-height: 100%;
  box-sizing: border-box;
}
.tau-chart__layout text {
  fill: #333;
}
.tau-chart__layout.tau-chart__layout_rendering-error {
  opacity: 0.75;
}
.tau-chart__rendering-timeout-warning {
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.tau-chart__rendering-timeout-warning svg {
  height: 100%;
  max-width: 32em;
  width: 100%;
}
.tau-chart__rendering-timeout-warning text {
  font-weight: 300;
}
.tau-chart__progress {
  box-sizing: border-box;
  height: 0.25em;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 1s 0.75s;
  width: 100%;
}
.tau-chart__progress_active {
  opacity: 1;
}
.tau-chart__progress__value {
  background: rgba(51, 51, 51, 0.25);
  height: 100%;
  transition: width 0.75s;
}
.tau-chart {
  /* region Select --------------------------------------------------*/
}
.tau-chart__checkbox {
  position: relative;
  display: block;
}
.tau-chart__checkbox__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.tau-chart__checkbox__icon {
  position: relative;
  width: 14px;
  height: 14px;
  top: 3px;
  display: inline-block;
  border: 1px solid #c3c3c3;
  border-radius: 2px;
  background: linear-gradient(to bottom, #fff 0%, #dbdbde 100%);
}
.tau-chart__checkbox__icon:before {
  display: none;
  content: '';
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAFoTx1HAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEQ4M0RDOTE4NDQ2MTFFNEE5RTdBRERDQzRBQzNEMTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEQ4M0RDOTI4NDQ2MTFFNEE5RTdBRERDQzRBQzNEMTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowRDgzREM4Rjg0NDYxMUU0QTlFN0FERENDNEFDM0QxNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowRDgzREM5MDg0NDYxMUU0QTlFN0FERENDNEFDM0QxNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pn2UjdoAAAEGSURBVHjaYvz//z8DGIAYSUlJdwECiBEukpiY/BDEAQggBrgIVBkLjAEDAAHEiMyBywBNOwDmJCYm/cdQBhBAqHrQAUgSojV5P8QtSY+A+D7cPTDdMAUwTQABhNdYJgZ8AF1nRkaGAgjDvQzi/AOCP3+YWX7+/HmXiYlRAcXY37//AEPs511OTg65uXPnPkQxNi0tTTklJUWGaNcCBBj+EMIDmBjIBCwo1jMyYigAul/x79//B4CulwOqODBv3hxHDKcmJycfAHLtgfrvMTExJf/7938xUF4GaOB9FhZmh1mzZj2CqUdNEkAdSUmZSsAgBNrAIAsUAQYlu+O0adMeo0cS/QMHAGJZps83N5ZDAAAAAElFTkSuQmCC');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.tau-chart__checkbox__text {
  margin-left: 5px;
}
.tau-chart__checkbox__input ~ .tau-chart__checkbox__text {
  cursor: pointer;
}
.tau-chart__checkbox__input:disabled ~ .tau-chart__checkbox__text {
  cursor: default;
  opacity: 0.3;
}
.tau-chart__checkbox__input:not(:disabled):focus + .tau-chart__checkbox__icon {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3), 0 0 7px 0 #52a8ec;
  outline: none;
}
.tau-chart__checkbox:hover .tau-chart__checkbox__input:not(:disabled) ~ .tau-chart__checkbox__icon {
  border-color: #999;
}
.tau-chart__checkbox__input:checked + .tau-chart__checkbox__icon {
  background: linear-gradient(to bottom, #fff 0%, #dbdbde 100%);
}
.tau-chart__checkbox__input:checked + .tau-chart__checkbox__icon:before {
  display: block;
}
.tau-chart__select {
  font-size: 13px;
  font-family: inherit;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  padding: 2px;
  background-color: #fff;
  border: 1px solid #c3c3c3;
  border-radius: 2px;
  color: #333;
}
.tau-chart__select:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3), 0 0 7px 0 #52a8ec;
  outline: none;
}
.tau-chart__select[disabled] {
  opacity: 0.3;
  cursor: default;
}
.tau-chart__select[multiple] {
  height: auto;
}
.tau-chart__select option[disabled] {
  opacity: 0.6;
}
.tau-chart__button {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid currentColor;
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: #b3b3b3;
  cursor: pointer;
  font-size: 13px;
  padding: 0 6px;
  line-height: 1.5em;
  height: calc(1.5em + 2px);
}
.tau-chart__button:hover {
  border-color: #999999;
  color: #333;
}
/* region Generate .color@{n}-@{i}  function  */
.tau-chart__svg .color20-1 {
  stroke: #6FA1D9;
  fill: #6FA1D9;
}
.tau-chart__svg .color20-2 {
  stroke: #DF2B59;
  fill: #DF2B59;
}
.tau-chart__svg .color20-3 {
  stroke: #66DA26;
  fill: #66DA26;
}
.tau-chart__svg .color20-4 {
  stroke: #4C3862;
  fill: #4C3862;
}
.tau-chart__svg .color20-5 {
  stroke: #E5B011;
  fill: #E5B011;
}
.tau-chart__svg .color20-6 {
  stroke: #3A3226;
  fill: #3A3226;
}
.tau-chart__svg .color20-7 {
  stroke: #CB461A;
  fill: #CB461A;
}
.tau-chart__svg .color20-8 {
  stroke: #C7CE23;
  fill: #C7CE23;
}
.tau-chart__svg .color20-9 {
  stroke: #7FCDC2;
  fill: #7FCDC2;
}
.tau-chart__svg .color20-10 {
  stroke: #CCA1C8;
  fill: #CCA1C8;
}
.tau-chart__svg .color20-11 {
  stroke: #C84CCE;
  fill: #C84CCE;
}
.tau-chart__svg .color20-12 {
  stroke: #54762E;
  fill: #54762E;
}
.tau-chart__svg .color20-13 {
  stroke: #746BC9;
  fill: #746BC9;
}
.tau-chart__svg .color20-14 {
  stroke: #953441;
  fill: #953441;
}
.tau-chart__svg .color20-15 {
  stroke: #5C7A76;
  fill: #5C7A76;
}
.tau-chart__svg .color20-16 {
  stroke: #C8BF87;
  fill: #C8BF87;
}
.tau-chart__svg .color20-17 {
  stroke: #BFC1C3;
  fill: #BFC1C3;
}
.tau-chart__svg .color20-18 {
  stroke: #8E5C31;
  fill: #8E5C31;
}
.tau-chart__svg .color20-19 {
  stroke: #71CE7B;
  fill: #71CE7B;
}
.tau-chart__svg .color20-20 {
  stroke: #BE478B;
  fill: #BE478B;
}
.tau-chart__svg .color-default {
  stroke: #6FA1D9;
  fill: #6FA1D9;
}
/* endregion */
/* region Generate .line-params-@{n}  function  */
/* Generate .line-size-@{n} */
.tau-chart__line-width-1 {
  stroke-width: 1px;
}
.tau-chart__line-width-2 {
  stroke-width: 1.5px;
}
.tau-chart__line-width-3 {
  stroke-width: 2px;
}
.tau-chart__line-width-4 {
  stroke-width: 2.5px;
}
.tau-chart__line-width-5 {
  stroke-width: 3px;
}
/* Generate .line-opacity-@{n} */
.tau-chart__line-opacity-1 {
  stroke-opacity: 1;
}
.tau-chart__line-opacity-2 {
  stroke-opacity: 0.95;
}
.tau-chart__line-opacity-3 {
  stroke-opacity: 0.9;
}
.tau-chart__line-opacity-4 {
  stroke-opacity: 0.85;
}
.tau-chart__line-opacity-5 {
  stroke-opacity: 0.8;
}
/* endregion */
/* endregion */
.tau-chart {
  /* Links */
  /* Axises and Grid */
  /* Scatterplot  */
  /* Linechart  */
  /*  Bar  */
  /* TODO: fix to avoid conflict on "stroke" with color brewer */
  /* TODO: remove this when CSS for color brewer is fixed */
  /*  PLUGINS  */
  /*  Highlighter  */
}
.tau-chart a {
  color: #3962FF;
  border-bottom: 1px solid rgba(57, 98, 255, 0.3);
  text-decoration: none;
}
.tau-chart a:hover {
  color: #E17152;
  border-bottom: 1px solid rgba(225, 113, 82, 0.3);
}
.tau-chart__time-axis-overflow .tick:nth-child(even) {
  display: none;
}
.tau-chart__svg {
  display: block;
  overflow: hidden;
}
.tau-chart__svg .place {
  fill: #fff;
  stroke: #000;
  stroke-opacity: 0.7;
  stroke-width: 0.5;
}
.tau-chart__svg .place-label {
  opacity: 0.7;
  font-size: 11px;
  color: #000000;
  line-height: 13px;
  text-anchor: start;
}
.tau-chart__svg .place-label-countries,
.tau-chart__svg .place-label-subunits,
.tau-chart__svg .place-label-states {
  text-anchor: middle;
  font-size: 10px;
  fill: rgba(51, 51, 51, 0.5);
  line-height: 10px;
  text-transform: capitalize;
}
.tau-chart__svg .map-contour-level path {
  stroke-opacity: 0.5;
  stroke-linejoin: 'round';
}
.tau-chart__svg .map-contour-level-0 path {
  stroke: #fff;
}
.tau-chart__svg .map-contour-level-1 path {
  stroke: #fff;
}
.tau-chart__svg .map-contour-level-2 path {
  stroke: #fff;
}
.tau-chart__svg .map-contour-level-3 path {
  stroke: #fff;
}
.tau-chart__svg .map-contour-level-4 path {
  stroke: #fff;
}
.tau-chart__svg .map-contour-highlighted,
.tau-chart__svg .map-contour:hover {
  fill: #FFBF00;
}
.tau-chart__svg .map-contour-highlighted path,
.tau-chart__svg .map-contour:hover path {
  stroke: #fff;
}
.tau-chart__svg .map-contour-highlighted text,
.tau-chart__svg .map-contour:hover text {
  fill: #000;
}
.tau-chart__svg .axis line,
.tau-chart__svg .axis path {
  stroke-width: 1;
  fill: none;
  stroke: rgba(189, 195, 205, 0.4);
  shape-rendering: crispEdges;
}
.tau-chart__svg .axis.facet-axis .tick line {
  opacity: 0;
}
.tau-chart__svg .axis.facet-axis .tick line.label-ref {
  opacity: 1;
}
.tau-chart__svg .axis.facet-axis .tick text {
  font-size: 12px;
  font-weight: 600;
}
.tau-chart__svg .axis.facet-axis path.domain {
  opacity: 0;
}
.tau-chart__svg .axis.facet-axis.compact .tick text {
  font-weight: normal;
}
.tau-chart__svg .axis.facet-axis.compact .label {
  font-weight: normal;
}
.tau-chart__svg .axis.facet-axis.compact .label .label-token {
  font-weight: normal;
}
.tau-chart__svg .tick text {
  font-size: 11px;
}
.tau-chart__svg .grid .grid-lines path {
  shape-rendering: crispEdges;
}
.tau-chart__svg .grid .line path,
.tau-chart__svg .grid path.line,
.tau-chart__svg .grid path.domain {
  fill: none;
}
.tau-chart__svg .grid .tick > line,
.tau-chart__svg .grid .extra-tick-line {
  fill: none;
  stroke: rgba(189, 195, 205, 0.4);
  stroke-width: 1px;
  shape-rendering: crispEdges;
}
.tau-chart__svg .grid .tick.zero-tick > line {
  stroke: rgba(126, 129, 134, 0.505);
}
.tau-chart__svg .grid .line path {
  shape-rendering: auto;
}
.tau-chart__svg .grid .cursor-line {
  shape-rendering: crispEdges;
  stroke: #adadad;
  stroke-width: 1px;
}
.tau-chart__svg .label {
  font-size: 12px;
  font-weight: 600;
}
.tau-chart__svg .label .label-token {
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}
.tau-chart__svg .label .label-token-1,
.tau-chart__svg .label .label-token-2 {
  font-weight: normal;
}
.tau-chart__svg .label .label-token-2 {
  fill: gray;
}
.tau-chart__svg .label .label-token-delimiter {
  font-weight: normal;
  fill: gray;
}
.tau-chart__svg .label.inline .label-token {
  font-weight: normal;
  fill: gray;
  text-transform: none;
}
.tau-chart__svg .brush .selection {
  fill-opacity: 0.3;
  stroke: #fff;
  shape-rendering: crispEdges;
}
.tau-chart__svg .background {
  stroke: #f2f2f2;
}
.tau-chart__dot {
  opacity: 0.7;
  stroke-width: 0;
  transition: stroke-width 0.1s ease, opacity 0.2s ease;
}
.tau-chart__line {
  fill: none;
  transition: stroke-opacity 0.2s ease, stroke-width 0.2s ease;
}
.tau-chart__dot-line {
  opacity: 1;
  transition: stroke-opacity 0.2s ease;
}
.tau-chart__bar {
  opacity: 0.7;
  shape-rendering: geometricPrecision;
  stroke-opacity: 0.5;
  stroke-width: 1;
  stroke: #fff;
  transition: opacity 0.2s ease;
}
.tau-chart__area {
  transition: opacity 0.2s ease;
}
.tau-chart__area path:not(.i-data-anchor),
.tau-chart__area polygon {
  opacity: 0.6;
  transition: stroke-opacity 0.2s ease, stroke-width 0.2s ease;
}
.tau-chart__svg .tau-chart__bar {
  stroke: #fff;
}
.tau-chart__dot.tau-chart__highlighted {
  stroke-width: 1;
  opacity: 1;
}
.tau-chart__dot.tau-chart__dimmed {
  opacity: 0.2;
}
.tau-chart__line.tau-chart__highlighted {
  stroke-opacity: 1;
  stroke-width: 3;
}
.tau-chart__line.tau-chart__dimmed {
  stroke-opacity: 0.2;
}
.i-role-label.tau-chart__highlighted,
.tau-chart__area.tau-chart__highlighted,
.tau-chart__bar.tau-chart__highlighted {
  stroke-opacity: 1;
  opacity: 1;
}
.i-role-label.tau-chart__dimmed,
.tau-chart__area.tau-chart__dimmed,
.tau-chart__bar.tau-chart__dimmed {
  opacity: 0.2;
}
.tau-chart__annotation-line {
  stroke-width: 2px;
  stroke-dasharray: 1,1;
  shape-rendering: crispEdges;
}
.tau-chart__annotation-area.tau-chart__area polygon {
  opacity: 0.1;
}
.tau-chart__category-filter {
  box-sizing: border-box;
  margin-right: 30px;
  padding: 20px 0 10px 10px;
  width: 160px;
}
.tau-chart__category-filter__category__label {
  font-weight: 600;
  font-size: 13px;
  margin: 0 0 10px 10px;
  text-transform: capitalize;
}
.tau-chart__category-filter__category__values {
  margin-bottom: 10px;
}
.tau-chart__category-filter__value {
  align-items: center;
  color: #ccc;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  width: 100%;
}
.tau-chart__category-filter__value:hover {
  background-color: rgba(189, 195, 205, 0.2);
}
.tau-chart__category-filter__value_checked {
  color: #333;
}
.tau-chart__category-filter__value__toggle {
  flex: none;
  padding: 10px 10px 8px 10px;
}
.tau-chart__category-filter__value__toggle__icon {
  background-color: transparent;
  border: 1px solid #8694a3;
  box-sizing: border-box;
  border-radius: 50%;
  display: inline-block;
  height: 16px;
  pointer-events: none;
  position: relative;
  width: 16px;
}
.tau-chart__category-filter__value__toggle__icon::before,
.tau-chart__category-filter__value__toggle__icon::after {
  background-color: #333;
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
}
.tau-chart__category-filter__value__toggle__icon::before {
  height: 2px;
  left: 3px;
  top: 6px;
  width: 8px;
}
.tau-chart__category-filter__value__toggle__icon::after {
  height: 8px;
  left: 6px;
  top: 3px;
  width: 2px;
}
.tau-chart__category-filter__value__toggle:hover .tau-chart__category-filter__value__toggle__icon::before,
.tau-chart__category-filter__value__toggle:hover .tau-chart__category-filter__value__toggle__icon::after {
  opacity: 1;
}
.tau-chart__category-filter__value_checked .tau-chart__category-filter__value__toggle__icon {
  background-color: #8694a3;
  border-color: transparent;
}
.tau-chart__category-filter__value_checked .tau-chart__category-filter__value__toggle__icon::before,
.tau-chart__category-filter__value_checked .tau-chart__category-filter__value__toggle__icon::after {
  background-color: #fff;
  transform: rotate(45deg);
}
.tau-chart__category-filter__value__label {
  padding-left: 4px;
}
.tau-chart__layout .tau-crosshair__line {
  shape-rendering: crispEdges;
  stroke-dasharray: 1px 1px;
  stroke-width: 1px;
}
.tau-chart__layout .tau-crosshair__label__text {
  fill: #fff;
  stroke: none;
}
.tau-chart__layout .tau-crosshair__label__text,
.tau-chart__layout .tau-crosshair__label__text-shadow {
  font-size: 12px;
  font-weight: normal;
}
.tau-chart__layout .tau-crosshair__line-shadow {
  shape-rendering: crispEdges;
  stroke: #cccccc;
  stroke-width: 1px;
}
.tau-chart__layout .tau-crosshair__group.y .tau-crosshair__line-shadow {
  transform: translateX(-0.5px);
}
.tau-chart__layout .tau-crosshair__group.x .tau-crosshair__line-shadow {
  transform: translateY(0.5px);
}
.tau-chart__layout .tau-crosshair__label__text-shadow {
  stroke-linejoin: round;
  stroke-width: 3px;
  visibility: hidden;
}
.tau-chart__layout .tau-crosshair__label__box {
  fill-opacity: 0.85;
  rx: 3px;
  ry: 3px;
  stroke: none;
}
.tau-chart__layout .tau-crosshair__line.color20-1 {
  stroke: #6FA1D9;
}
.tau-chart__layout .tau-crosshair__label.color20-1 .tau-crosshair__label__text-shadow {
  stroke: #6FA1D9;
}
.tau-chart__layout .tau-crosshair__label.color20-1 .tau-crosshair__label__box {
  fill: #6FA1D9;
}
.tau-chart__layout .tau-crosshair__line.color20-2 {
  stroke: #DF2B59;
}
.tau-chart__layout .tau-crosshair__label.color20-2 .tau-crosshair__label__text-shadow {
  stroke: #DF2B59;
}
.tau-chart__layout .tau-crosshair__label.color20-2 .tau-crosshair__label__box {
  fill: #DF2B59;
}
.tau-chart__layout .tau-crosshair__line.color20-3 {
  stroke: #66DA26;
}
.tau-chart__layout .tau-crosshair__label.color20-3 .tau-crosshair__label__text-shadow {
  stroke: #66DA26;
}
.tau-chart__layout .tau-crosshair__label.color20-3 .tau-crosshair__label__box {
  fill: #66DA26;
}
.tau-chart__layout .tau-crosshair__line.color20-4 {
  stroke: #4C3862;
}
.tau-chart__layout .tau-crosshair__label.color20-4 .tau-crosshair__label__text-shadow {
  stroke: #4C3862;
}
.tau-chart__layout .tau-crosshair__label.color20-4 .tau-crosshair__label__box {
  fill: #4C3862;
}
.tau-chart__layout .tau-crosshair__line.color20-5 {
  stroke: #E5B011;
}
.tau-chart__layout .tau-crosshair__label.color20-5 .tau-crosshair__label__text-shadow {
  stroke: #E5B011;
}
.tau-chart__layout .tau-crosshair__label.color20-5 .tau-crosshair__label__box {
  fill: #E5B011;
}
.tau-chart__layout .tau-crosshair__line.color20-6 {
  stroke: #3A3226;
}
.tau-chart__layout .tau-crosshair__label.color20-6 .tau-crosshair__label__text-shadow {
  stroke: #3A3226;
}
.tau-chart__layout .tau-crosshair__label.color20-6 .tau-crosshair__label__box {
  fill: #3A3226;
}
.tau-chart__layout .tau-crosshair__line.color20-7 {
  stroke: #CB461A;
}
.tau-chart__layout .tau-crosshair__label.color20-7 .tau-crosshair__label__text-shadow {
  stroke: #CB461A;
}
.tau-chart__layout .tau-crosshair__label.color20-7 .tau-crosshair__label__box {
  fill: #CB461A;
}
.tau-chart__layout .tau-crosshair__line.color20-8 {
  stroke: #C7CE23;
}
.tau-chart__layout .tau-crosshair__label.color20-8 .tau-crosshair__label__text-shadow {
  stroke: #C7CE23;
}
.tau-chart__layout .tau-crosshair__label.color20-8 .tau-crosshair__label__box {
  fill: #C7CE23;
}
.tau-chart__layout .tau-crosshair__line.color20-9 {
  stroke: #7FCDC2;
}
.tau-chart__layout .tau-crosshair__label.color20-9 .tau-crosshair__label__text-shadow {
  stroke: #7FCDC2;
}
.tau-chart__layout .tau-crosshair__label.color20-9 .tau-crosshair__label__box {
  fill: #7FCDC2;
}
.tau-chart__layout .tau-crosshair__line.color20-10 {
  stroke: #CCA1C8;
}
.tau-chart__layout .tau-crosshair__label.color20-10 .tau-crosshair__label__text-shadow {
  stroke: #CCA1C8;
}
.tau-chart__layout .tau-crosshair__label.color20-10 .tau-crosshair__label__box {
  fill: #CCA1C8;
}
.tau-chart__layout .tau-crosshair__line.color20-11 {
  stroke: #C84CCE;
}
.tau-chart__layout .tau-crosshair__label.color20-11 .tau-crosshair__label__text-shadow {
  stroke: #C84CCE;
}
.tau-chart__layout .tau-crosshair__label.color20-11 .tau-crosshair__label__box {
  fill: #C84CCE;
}
.tau-chart__layout .tau-crosshair__line.color20-12 {
  stroke: #54762E;
}
.tau-chart__layout .tau-crosshair__label.color20-12 .tau-crosshair__label__text-shadow {
  stroke: #54762E;
}
.tau-chart__layout .tau-crosshair__label.color20-12 .tau-crosshair__label__box {
  fill: #54762E;
}
.tau-chart__layout .tau-crosshair__line.color20-13 {
  stroke: #746BC9;
}
.tau-chart__layout .tau-crosshair__label.color20-13 .tau-crosshair__label__text-shadow {
  stroke: #746BC9;
}
.tau-chart__layout .tau-crosshair__label.color20-13 .tau-crosshair__label__box {
  fill: #746BC9;
}
.tau-chart__layout .tau-crosshair__line.color20-14 {
  stroke: #953441;
}
.tau-chart__layout .tau-crosshair__label.color20-14 .tau-crosshair__label__text-shadow {
  stroke: #953441;
}
.tau-chart__layout .tau-crosshair__label.color20-14 .tau-crosshair__label__box {
  fill: #953441;
}
.tau-chart__layout .tau-crosshair__line.color20-15 {
  stroke: #5C7A76;
}
.tau-chart__layout .tau-crosshair__label.color20-15 .tau-crosshair__label__text-shadow {
  stroke: #5C7A76;
}
.tau-chart__layout .tau-crosshair__label.color20-15 .tau-crosshair__label__box {
  fill: #5C7A76;
}
.tau-chart__layout .tau-crosshair__line.color20-16 {
  stroke: #C8BF87;
}
.tau-chart__layout .tau-crosshair__label.color20-16 .tau-crosshair__label__text-shadow {
  stroke: #C8BF87;
}
.tau-chart__layout .tau-crosshair__label.color20-16 .tau-crosshair__label__box {
  fill: #C8BF87;
}
.tau-chart__layout .tau-crosshair__line.color20-17 {
  stroke: #BFC1C3;
}
.tau-chart__layout .tau-crosshair__label.color20-17 .tau-crosshair__label__text-shadow {
  stroke: #BFC1C3;
}
.tau-chart__layout .tau-crosshair__label.color20-17 .tau-crosshair__label__box {
  fill: #BFC1C3;
}
.tau-chart__layout .tau-crosshair__line.color20-18 {
  stroke: #8E5C31;
}
.tau-chart__layout .tau-crosshair__label.color20-18 .tau-crosshair__label__text-shadow {
  stroke: #8E5C31;
}
.tau-chart__layout .tau-crosshair__label.color20-18 .tau-crosshair__label__box {
  fill: #8E5C31;
}
.tau-chart__layout .tau-crosshair__line.color20-19 {
  stroke: #71CE7B;
}
.tau-chart__layout .tau-crosshair__label.color20-19 .tau-crosshair__label__text-shadow {
  stroke: #71CE7B;
}
.tau-chart__layout .tau-crosshair__label.color20-19 .tau-crosshair__label__box {
  fill: #71CE7B;
}
.tau-chart__layout .tau-crosshair__line.color20-20 {
  stroke: #BE478B;
}
.tau-chart__layout .tau-crosshair__label.color20-20 .tau-crosshair__label__text-shadow {
  stroke: #BE478B;
}
.tau-chart__layout .tau-crosshair__label.color20-20 .tau-crosshair__label__box {
  fill: #BE478B;
}
.diff-tooltip__table {
  border-top: 1px solid rgba(51, 51, 51, 0.2);
  margin-top: 5px;
  padding-top: 5px;
  width: calc(100% + 15px);
}
.diff-tooltip__header {
  align-items: stretch;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  padding: 2px 0px;
  position: relative;
}
.diff-tooltip__header__text {
  align-items: center;
  display: inline-flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  max-width: 120px;
}
.diff-tooltip__header__value {
  align-items: center;
  display: inline-flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-right: 15px;
  max-width: 120px;
  padding-left: 10px;
  text-align: right;
}
.diff-tooltip__header__updown {
  align-items: center;
  display: inline-flex;
  flex: 1 1 auto;
  font-size: 75%;
  height: 100%;
  justify-content: flex-start;
  padding-left: 2px;
  position: absolute;
  right: 0;
  visibility: hidden;
}
.diff-tooltip__body {
  max-height: 250px;
  overflow: hidden;
  padding: 1px;
  position: relative;
}
.diff-tooltip__body__content {
  padding-bottom: 1px;
}
.diff-tooltip__body_overflow-top::before,
.diff-tooltip__body_overflow-bottom::after {
  align-items: center;
  color: rgba(51, 51, 51, 0.7);
  content: "...";
  display: flex;
  flex-direction: column;
  height: 26px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.diff-tooltip__body_overflow-top::before {
  background: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0));
  justify-content: flex-start;
  top: 0;
}
.diff-tooltip__body_overflow-bottom::after {
  background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
  justify-content: flex-end;
  bottom: 0;
}
.diff-tooltip__item {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  min-width: 100px;
  position: relative;
}
.diff-tooltip__item_highlighted {
  background-color: rgba(241, 233, 255, 0.5);
  box-shadow: 0 0 0 1px #877aa1;
  z-index: 1;
}
.diff-tooltip__item__bg {
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  min-width: 3px;
  opacity: 0.6;
  position: absolute;
  z-index: 0;
}
.diff-tooltip__item__text {
  flex: 1 1 auto;
  overflow: hidden;
  padding: 2px 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}
.diff-tooltip__item__value {
  flex: none;
  display: table-cell;
  padding: 2px 4px 2px 30px;
  z-index: 1;
}
.diff-tooltip__item__updown {
  align-items: center;
  display: inline-flex;
  flex: 4;
  justify-content: flex-start;
  left: 100%;
  height: 100%;
  padding: 0 4px 0 4px;
  position: absolute;
}
.diff-tooltip__item__updown_positive {
  color: #4ca383;
}
.diff-tooltip__item__updown_negative {
  color: #df6772;
}
.diff-tooltip__field__updown_positive {
  color: #4ca383;
}
.diff-tooltip__field__updown_negative {
  color: #df6772;
}
.interval-highlight__range {
  shape-rendering: crispEdges;
}
.interval-highlight__range-start {
  shape-rendering: crispEdges;
  stroke: #b8aecb;
  stroke-dasharray: 2 1;
}
.interval-highlight__range-end {
  shape-rendering: crispEdges;
  stroke: #b8aecb;
}
.interval-highlight__gradient-start {
  stop-color: #c4b3e6;
  stop-opacity: 0.02;
}
.interval-highlight__gradient-end {
  stop-color: #c4b3e6;
  stop-opacity: 0.2;
}
.diff-tooltip__item__bg.color20-1 {
  background-color: #6FA1D9;
}
.diff-tooltip__item__bg.color20-2 {
  background-color: #DF2B59;
}
.diff-tooltip__item__bg.color20-3 {
  background-color: #66DA26;
}
.diff-tooltip__item__bg.color20-4 {
  background-color: #4C3862;
}
.diff-tooltip__item__bg.color20-5 {
  background-color: #E5B011;
}
.diff-tooltip__item__bg.color20-6 {
  background-color: #3A3226;
}
.diff-tooltip__item__bg.color20-7 {
  background-color: #CB461A;
}
.diff-tooltip__item__bg.color20-8 {
  background-color: #C7CE23;
}
.diff-tooltip__item__bg.color20-9 {
  background-color: #7FCDC2;
}
.diff-tooltip__item__bg.color20-10 {
  background-color: #CCA1C8;
}
.diff-tooltip__item__bg.color20-11 {
  background-color: #C84CCE;
}
.diff-tooltip__item__bg.color20-12 {
  background-color: #54762E;
}
.diff-tooltip__item__bg.color20-13 {
  background-color: #746BC9;
}
.diff-tooltip__item__bg.color20-14 {
  background-color: #953441;
}
.diff-tooltip__item__bg.color20-15 {
  background-color: #5C7A76;
}
.diff-tooltip__item__bg.color20-16 {
  background-color: #C8BF87;
}
.diff-tooltip__item__bg.color20-17 {
  background-color: #BFC1C3;
}
.diff-tooltip__item__bg.color20-18 {
  background-color: #8E5C31;
}
.diff-tooltip__item__bg.color20-19 {
  background-color: #71CE7B;
}
.diff-tooltip__item__bg.color20-20 {
  background-color: #BE478B;
}
.tau-chart__print-block {
  display: none;
}
.tau-chart__export {
  float: right;
  margin: 0 20px 0 0;
  display: block;
  text-indent: 20px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+ZXhwb3J0PC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIGZpbGw9IiMwMDAiPjxwYXRoIGQ9Ik0xNyAxLjY3bC04LjMyOCA4LjM2Nkw4IDkuNSAxNi4zNTMgMUgxMlYwaDZ2NmgtMVYxLjY3eiIgb3BhY2l0eT0iLjgiLz48cGF0aCBkPSJNMCA1LjAxQzAgMy4zNDYgMS4zMzcgMiAzLjAxIDJIMTZ2MTIuOTljMCAxLjY2My0xLjMzNyAzLjAxLTMuMDEgMy4wMUgzLjAxQzEuMzQ2IDE4IDAgMTYuNjYzIDAgMTQuOTlWNS4wMXpNMTUgMTVDMTUgMTYuMTA1IDE0LjEwMyAxNyAxMi45OTQgMTdIMy4wMDZDMS44OTggMTcgMSAxNi4xMDMgMSAxNC45OTRWNS4wMDZDMSAzLjg5OCAxLjg4NyAzIDIuOTk4IDNIOVYyaDd2N2gtMXY2LjAwMnoiIG9wYWNpdHk9Ii40Ii8+PC9nPjwvZz48L3N2Zz4=);
  width: 20px;
  height: 20px;
  color: transparent;
  opacity: 0.6;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  z-index: 2;
}
.tau-chart__export:hover {
  opacity: 1;
  text-decoration: none;
}
.tau-chart__export__list {
  font-size: 11px;
  margin: 0;
  padding: 0;
}
.tau-chart__export__item {
  overflow: hidden;
  box-sizing: border-box;
}
.tau-chart__export__item > a {
  display: block;
  padding: 7px 15px;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.tau-chart__export__item > a:hover,
.tau-chart__export__item > a:focus {
  background: #EAF2FC;
  outline: none;
  box-shadow: none;
}
.tau-chart__legend {
  padding: 20px 0 10px 10px;
  position: relative;
  margin-right: 30px;
  width: 160px;
  box-sizing: border-box;
}
.tau-chart__legend__wrap {
  margin-bottom: 30px;
  position: relative;
}
.tau-chart__legend__wrap:last-child {
  margin-bottom: 0;
}
.tau-chart__legend__title {
  margin: 0 0 10px 10px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 13px;
}
.tau-chart__legend__reset {
  margin-top: -4px;
  position: absolute;
  right: -25px;
  top: 0;
  z-index: 1;
}
.tau-chart__legend__reset.disabled {
  display: none;
}
.tau-chart__legend__reset + .tau-chart__legend__title {
  margin-right: 1.7em;
}
.tau-chart__legend__item {
  padding: 10px 20px 8px 40px;
  position: relative;
  font-size: 13px;
  line-height: 1.2em;
  cursor: pointer;
}
.tau-chart__legend__item:hover {
  background-color: rgba(189, 195, 205, 0.2);
}
.tau-chart__legend__item--size {
  cursor: default;
}
.tau-chart__legend__item--size:hover {
  background: none;
}
.tau-chart__legend__item .color-default {
  background: #6FA1D9;
  border-color: #6FA1D9;
}
.tau-chart__legend__item:disabled,
.tau-chart__legend__item.disabled {
  color: #ccc;
}
.tau-chart__legend__item.disabled .tau-chart__legend__guide {
  background: transparent;
}
.tau-chart__legend__guide {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid transparent;
  border-radius: 50%;
}
.tau-chart__legend__guide__wrap {
  position: absolute;
  top: calc((10px - 8px) + 0.6em);
  left: 10px;
  width: 16px;
  height: 16px;
}
.tau-chart__legend__guide--size {
  stroke: #6FA1D9;
  fill: #6FA1D9;
}
.tau-chart__legend__guide--color__overlay {
  background-color: transparent;
  height: 36px;
  left: -12px;
  position: absolute;
  top: -12px;
  width: 36px;
}
.tau-chart__legend__guide--color::before {
  content: "";
  display: none;
  height: 2px;
  left: 3px;
  pointer-events: none;
  position: absolute;
  top: 6px;
  width: 8px;
}
.tau-chart__legend__guide--color::after {
  content: "";
  display: none;
  height: 8px;
  left: 6px;
  pointer-events: none;
  position: absolute;
  top: 3px;
  width: 2px;
}
.tau-chart__legend__item .tau-chart__legend__guide--color:hover::before,
.tau-chart__legend__item .tau-chart__legend__guide--color:hover::after {
  background-color: #fff;
  display: inline-block;
  transform: rotate(45deg);
}
.tau-chart__legend__item.disabled .tau-chart__legend__guide--color:hover {
  background: #fff;
}
.tau-chart__legend__item.disabled .tau-chart__legend__guide--color:hover::before,
.tau-chart__legend__item.disabled .tau-chart__legend__guide--color:hover::after {
  background-color: #333;
  transform: none;
}
.tau-chart__legend__size-wrapper,
.tau-chart__legend__gradient-wrapper {
  box-sizing: border-box;
  margin: 10px;
  overflow: visible;
  width: 100%;
}
.tau-chart__legend__size,
.tau-chart__legend__gradient {
  overflow: visible;
}
.tau-chart__legend__size__item__circle.color-definite {
  stroke: #cacaca;
  fill: #cacaca;
}
.tau-chart__legend__size__item__circle.color-default-size {
  stroke: #6FA1D9;
  fill: #6FA1D9;
}
.tau-chart__legend__gradient__bar {
  rx: 4px;
  ry: 4px;
}
.tau-chart__legend__item .color20-1 {
  background: #6FA1D9;
  border: 1px solid #6FA1D9;
}
.tau-chart__legend__item.disabled .color20-1 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-2 {
  background: #DF2B59;
  border: 1px solid #DF2B59;
}
.tau-chart__legend__item.disabled .color20-2 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-3 {
  background: #66DA26;
  border: 1px solid #66DA26;
}
.tau-chart__legend__item.disabled .color20-3 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-4 {
  background: #4C3862;
  border: 1px solid #4C3862;
}
.tau-chart__legend__item.disabled .color20-4 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-5 {
  background: #E5B011;
  border: 1px solid #E5B011;
}
.tau-chart__legend__item.disabled .color20-5 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-6 {
  background: #3A3226;
  border: 1px solid #3A3226;
}
.tau-chart__legend__item.disabled .color20-6 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-7 {
  background: #CB461A;
  border: 1px solid #CB461A;
}
.tau-chart__legend__item.disabled .color20-7 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-8 {
  background: #C7CE23;
  border: 1px solid #C7CE23;
}
.tau-chart__legend__item.disabled .color20-8 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-9 {
  background: #7FCDC2;
  border: 1px solid #7FCDC2;
}
.tau-chart__legend__item.disabled .color20-9 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-10 {
  background: #CCA1C8;
  border: 1px solid #CCA1C8;
}
.tau-chart__legend__item.disabled .color20-10 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-11 {
  background: #C84CCE;
  border: 1px solid #C84CCE;
}
.tau-chart__legend__item.disabled .color20-11 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-12 {
  background: #54762E;
  border: 1px solid #54762E;
}
.tau-chart__legend__item.disabled .color20-12 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-13 {
  background: #746BC9;
  border: 1px solid #746BC9;
}
.tau-chart__legend__item.disabled .color20-13 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-14 {
  background: #953441;
  border: 1px solid #953441;
}
.tau-chart__legend__item.disabled .color20-14 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-15 {
  background: #5C7A76;
  border: 1px solid #5C7A76;
}
.tau-chart__legend__item.disabled .color20-15 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-16 {
  background: #C8BF87;
  border: 1px solid #C8BF87;
}
.tau-chart__legend__item.disabled .color20-16 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-17 {
  background: #BFC1C3;
  border: 1px solid #BFC1C3;
}
.tau-chart__legend__item.disabled .color20-17 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-18 {
  background: #8E5C31;
  border: 1px solid #8E5C31;
}
.tau-chart__legend__item.disabled .color20-18 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-19 {
  background: #71CE7B;
  border: 1px solid #71CE7B;
}
.tau-chart__legend__item.disabled .color20-19 {
  background-color: transparent;
}
.tau-chart__legend__item .color20-20 {
  background: #BE478B;
  border: 1px solid #BE478B;
}
.tau-chart__legend__item.disabled .color20-20 {
  background-color: transparent;
}
.tau-chart__filter__wrap {
  padding: 20px 0 10px 10px;
  margin-right: 30px;
  width: 160px;
  box-sizing: border-box;
}
.tau-chart__filter__wrap__title {
  margin: 0 0 10px 10px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 13px;
}
.tau-chart__filter__wrap rect {
  fill: rgba(0, 0, 0, 0.2);
}
.tau-chart__filter__wrap .brush .overlay,
.tau-chart__filter__wrap .brush .handle {
  opacity: 0;
}
.tau-chart__filter__wrap .brush .selection {
  shape-rendering: crispEdges;
  fill-opacity: 0.4;
  fill: #0074FF;
}
.tau-chart__filter__wrap text.date-label {
  text-anchor: middle;
  font-size: 12px;
}
.tau-chart__filter__wrap text.date-label .common {
  font-weight: 600;
}
.tau-chart__filter__wrap .resize line {
  stroke: #000;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}
.tau-chart__filter__wrap .resize.e text {
  text-anchor: middle;
  font-size: 12px;
}
.tau-chart__filter__wrap .resize.w text {
  text-anchor: middle;
  font-size: 12px;
}
.tau-chart__tooltip {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  z-index: 900;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.005);
  font-size: 11px;
  font-family: Helvetica Neue, Segoe UI, Open Sans, Ubuntu, sans-serif;
  /* Fade */
}
.tau-chart__tooltip.fade {
  opacity: 0;
  transition: opacity 200ms ease-out;
}
.tau-chart__tooltip.fade.in {
  opacity: 1;
  transition-duration: 500ms;
}
.tau-chart__tooltip.top-right,
.tau-chart__tooltip.bottom-right {
  margin-left: 8px;
}
.tau-chart__tooltip.top-left,
.tau-chart__tooltip.bottom-left {
  margin-left: -8px;
}
.tau-chart__tooltip.top,
.tau-chart__tooltip.top-right,
.tau-chart__tooltip.top-left {
  margin-top: 8px;
}
.tau-chart__tooltip__content {
  box-sizing: border-box;
  max-width: 500px;
  min-width: 100px;
  overflow: hidden;
  padding: 15px 15px 10px 15px;
}
.tau-chart__tooltip__buttons {
  background: #EBEEF1;
  bottom: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.005);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 500px;
  min-width: 86px;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.tau-chart__tooltip__buttons::after {
  background: linear-gradient(to bottom, #fff 50%, rgba(255, 255, 255, 0));
  content: "";
  display: block;
  height: 8px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  width: 100%;
}
.tau-chart__tooltip__button {
  color: #65717F;
  cursor: pointer;
  display: inline-flex;
  flex: 1 0 auto;
  height: 0;
  overflow: hidden;
  transition: height 500ms;
}
.tau-chart__tooltip__button__wrap {
  line-height: 26px;
  padding: 0 15px;
}
.tau-chart__tooltip__button:hover {
  background: #f5f7f8;
  color: #333;
}
.tau-chart__tooltip__button .tau-icon-close-gray {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiIHZpZXdCb3g9IjAgMCAzMCAzMCI+PHBhdGggZmlsbD0iIzg0OTZBNyIgZD0iTTEwLDAuNzE1TDkuMjg1LDBMNSw0LjI4NUwwLjcxNSwwTDAsMC43MTVMNC4yODUsNUwwLDkuMjg1TDAuNzE1LDEwTDUsNS43MTVMOS4yODUsMTBMMTAsOS4yODVMNS43MTUsNUwxMCwwLjcxNXoiLz48L3N2Zz4=);
  display: inline-block;
  width: 12px;
  height: 12px;
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.tau-chart__tooltip.stuck .tau-chart__tooltip__button {
  height: 26px;
}
.tau-chart__tooltip.top .tau-chart__tooltip__buttons,
.tau-chart__tooltip.top-right .tau-chart__tooltip__buttons,
.tau-chart__tooltip.top-left .tau-chart__tooltip__buttons {
  bottom: initial;
  top: 100%;
}
.tau-chart__tooltip.top .tau-chart__tooltip__buttons__wrap,
.tau-chart__tooltip.top-right .tau-chart__tooltip__buttons__wrap,
.tau-chart__tooltip.top-left .tau-chart__tooltip__buttons__wrap {
  position: relative;
  top: calc(100% - 26px);
}
.tau-chart__tooltip.top .tau-chart__tooltip__buttons::after,
.tau-chart__tooltip.top-right .tau-chart__tooltip__buttons::after,
.tau-chart__tooltip.top-left .tau-chart__tooltip__buttons::after {
  background: linear-gradient(to top, #fff 50%, rgba(255, 255, 255, 0));
  bottom: 100%;
  top: initial;
}
.tau-chart__tooltip.top-right .tau-chart__tooltip__button__wrap,
.tau-chart__tooltip.top-left .tau-chart__tooltip__button__wrap {
  position: relative;
  top: calc(100% - 26px);
}
.tau-chart__tooltip__list {
  display: table;
}
.tau-chart__tooltip__list__item {
  display: table-row;
}
.tau-chart__tooltip__list__elem {
  display: table-cell;
  padding-bottom: 4px;
  line-height: 1.3;
  color: #000;
}
.tau-chart__tooltip__list__elem:not(:first-child) {
  padding-left: 15px;
}
.tau-chart__tooltip__list__elem:first-child {
  color: #8e8e8e;
}
.tau-chart__tooltip__gray-text {
  color: #8e8e8e;
}
.tau-chart__tooltip-target {
  cursor: pointer;
}
.tau-chart__tooltip-target .tau-chart__dot.tau-chart__highlighted,
.tau-chart__tooltip-target .tau-chart__bar.tau-chart__highlighted,
.tau-chart__tooltip-target .i-data-anchor.tau-chart__highlighted {
  stroke: #333;
  stroke-width: 1;
}
.tau-chart__tooltip-target .tau-chart__bar.tau-chart__highlighted {
  shape-rendering: crispEdges;
}
.tau-chart__svg .tau-chart__trendline.color20-1 {
  stroke: #357ac7;
}
.tau-chart__svg .tau-chart__trendline.color20-2 {
  stroke: #a5193d;
}
.tau-chart__svg .tau-chart__trendline.color20-3 {
  stroke: #47991a;
}
.tau-chart__svg .tau-chart__trendline.color20-4 {
  stroke: #261c31;
}
.tau-chart__svg .tau-chart__trendline.color20-5 {
  stroke: #9e790c;
}
.tau-chart__svg .tau-chart__trendline.color20-6 {
  stroke: #0c0a08;
}
.tau-chart__svg .tau-chart__trendline.color20-7 {
  stroke: #872f11;
}
.tau-chart__svg .tau-chart__trendline.color20-8 {
  stroke: #888d18;
}
.tau-chart__svg .tau-chart__trendline.color20-9 {
  stroke: #48b8a8;
}
.tau-chart__svg .tau-chart__trendline.color20-10 {
  stroke: #b16fab;
}
.tau-chart__svg .tau-chart__trendline.color20-11 {
  stroke: #9c2ca1;
}
.tau-chart__svg .tau-chart__trendline.color20-12 {
  stroke: #2d3f19;
}
.tau-chart__svg .tau-chart__trendline.color20-13 {
  stroke: #483eaa;
}
.tau-chart__svg .tau-chart__trendline.color20-14 {
  stroke: #5c2028;
}
.tau-chart__svg .tau-chart__trendline.color20-15 {
  stroke: #3b4e4c;
}
.tau-chart__svg .tau-chart__trendline.color20-16 {
  stroke: #b0a353;
}
.tau-chart__svg .tau-chart__trendline.color20-17 {
  stroke: #989b9e;
}
.tau-chart__svg .tau-chart__trendline.color20-18 {
  stroke: #55371d;
}
.tau-chart__svg .tau-chart__trendline.color20-19 {
  stroke: #3eb44b;
}
.tau-chart__svg .tau-chart__trendline.color20-20 {
  stroke: #883063;
}
.tau-chart__svg .tau-chart__trendline.color-default {
  stroke: #357ac7;
}
.tau-chart {
  /* TrendLine */
}
.tau-chart__trendlinepanel {
  padding: 20px 0 20px 20px;
  margin-right: 20px;
  width: 160px;
  box-sizing: border-box;
}
.tau-chart__trendlinepanel__title {
  margin: 0 0 10px 0;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 13px;
}
.tau-chart__trendlinepanel__control {
  width: 100%;
}
.tau-chart__trendlinepanel__error-message {
  font-size: 11px;
  line-height: 16px;
  margin-left: 5px;
}
.tau-chart__trendlinepanel.applicable-false.hide-trendline-error,
.tau-chart__trendlinepanel.applicable-false .tau-chart__checkbox__input,
.tau-chart__trendlinepanel.applicable-false .tau-chart__trendlinepanel__control,
.tau-chart__trendlinepanel.applicable-false .tau-chart__checkbox__icon {
  display: none;
}
.tau-chart__trendline {
  stroke-dasharray: 4, 4;
}
/* This product includes color specifications and designs developed by Cynthia Brewer (http://colorbrewer.org/). */
/*
 generate from addons color-brewer.js
 copy(_.flatten(_.map(res, function(value, hue){
   return _.map(value, function(value, number) {
         return _.map(value,function(value,index) {
            return ['.', hue, '.', 'q', index, '-', number, '{fill:', value, ';stroke:', value, ';}'].join('');
         })
   })
})).join(''))
*/
.YlGn.q0-3 {
  fill: #f7fcb9;
  background: #f7fcb9;
  stroke: #f7fcb9;
}
.YlGn.q1-3 {
  fill: #addd8e;
  background: #addd8e;
  stroke: #addd8e;
}
.YlGn.q2-3 {
  fill: #31a354;
  background: #31a354;
  stroke: #31a354;
}
.YlGn.q0-4 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.YlGn.q1-4 {
  fill: #c2e699;
  background: #c2e699;
  stroke: #c2e699;
}
.YlGn.q2-4 {
  fill: #78c679;
  background: #78c679;
  stroke: #78c679;
}
.YlGn.q3-4 {
  fill: #238443;
  background: #238443;
  stroke: #238443;
}
.YlGn.q0-5 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.YlGn.q1-5 {
  fill: #c2e699;
  background: #c2e699;
  stroke: #c2e699;
}
.YlGn.q2-5 {
  fill: #78c679;
  background: #78c679;
  stroke: #78c679;
}
.YlGn.q3-5 {
  fill: #31a354;
  background: #31a354;
  stroke: #31a354;
}
.YlGn.q4-5 {
  fill: #006837;
  background: #006837;
  stroke: #006837;
}
.YlGn.q0-6 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.YlGn.q1-6 {
  fill: #d9f0a3;
  background: #d9f0a3;
  stroke: #d9f0a3;
}
.YlGn.q2-6 {
  fill: #addd8e;
  background: #addd8e;
  stroke: #addd8e;
}
.YlGn.q3-6 {
  fill: #78c679;
  background: #78c679;
  stroke: #78c679;
}
.YlGn.q4-6 {
  fill: #31a354;
  background: #31a354;
  stroke: #31a354;
}
.YlGn.q5-6 {
  fill: #006837;
  background: #006837;
  stroke: #006837;
}
.YlGn.q0-7 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.YlGn.q1-7 {
  fill: #d9f0a3;
  background: #d9f0a3;
  stroke: #d9f0a3;
}
.YlGn.q2-7 {
  fill: #addd8e;
  background: #addd8e;
  stroke: #addd8e;
}
.YlGn.q3-7 {
  fill: #78c679;
  background: #78c679;
  stroke: #78c679;
}
.YlGn.q4-7 {
  fill: #41ab5d;
  background: #41ab5d;
  stroke: #41ab5d;
}
.YlGn.q5-7 {
  fill: #238443;
  background: #238443;
  stroke: #238443;
}
.YlGn.q6-7 {
  fill: #005a32;
  background: #005a32;
  stroke: #005a32;
}
.YlGn.q0-8 {
  fill: #ffffe5;
  background: #ffffe5;
  stroke: #ffffe5;
}
.YlGn.q1-8 {
  fill: #f7fcb9;
  background: #f7fcb9;
  stroke: #f7fcb9;
}
.YlGn.q2-8 {
  fill: #d9f0a3;
  background: #d9f0a3;
  stroke: #d9f0a3;
}
.YlGn.q3-8 {
  fill: #addd8e;
  background: #addd8e;
  stroke: #addd8e;
}
.YlGn.q4-8 {
  fill: #78c679;
  background: #78c679;
  stroke: #78c679;
}
.YlGn.q5-8 {
  fill: #41ab5d;
  background: #41ab5d;
  stroke: #41ab5d;
}
.YlGn.q6-8 {
  fill: #238443;
  background: #238443;
  stroke: #238443;
}
.YlGn.q7-8 {
  fill: #005a32;
  background: #005a32;
  stroke: #005a32;
}
.YlGn.q0-9 {
  fill: #ffffe5;
  background: #ffffe5;
  stroke: #ffffe5;
}
.YlGn.q1-9 {
  fill: #f7fcb9;
  background: #f7fcb9;
  stroke: #f7fcb9;
}
.YlGn.q2-9 {
  fill: #d9f0a3;
  background: #d9f0a3;
  stroke: #d9f0a3;
}
.YlGn.q3-9 {
  fill: #addd8e;
  background: #addd8e;
  stroke: #addd8e;
}
.YlGn.q4-9 {
  fill: #78c679;
  background: #78c679;
  stroke: #78c679;
}
.YlGn.q5-9 {
  fill: #41ab5d;
  background: #41ab5d;
  stroke: #41ab5d;
}
.YlGn.q6-9 {
  fill: #238443;
  background: #238443;
  stroke: #238443;
}
.YlGn.q7-9 {
  fill: #006837;
  background: #006837;
  stroke: #006837;
}
.YlGn.q8-9 {
  fill: #004529;
  background: #004529;
  stroke: #004529;
}
.YlGnBu.q0-3 {
  fill: #edf8b1;
  background: #edf8b1;
  stroke: #edf8b1;
}
.YlGnBu.q1-3 {
  fill: #7fcdbb;
  background: #7fcdbb;
  stroke: #7fcdbb;
}
.YlGnBu.q2-3 {
  fill: #2c7fb8;
  background: #2c7fb8;
  stroke: #2c7fb8;
}
.YlGnBu.q0-4 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.YlGnBu.q1-4 {
  fill: #a1dab4;
  background: #a1dab4;
  stroke: #a1dab4;
}
.YlGnBu.q2-4 {
  fill: #41b6c4;
  background: #41b6c4;
  stroke: #41b6c4;
}
.YlGnBu.q3-4 {
  fill: #225ea8;
  background: #225ea8;
  stroke: #225ea8;
}
.YlGnBu.q0-5 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.YlGnBu.q1-5 {
  fill: #a1dab4;
  background: #a1dab4;
  stroke: #a1dab4;
}
.YlGnBu.q2-5 {
  fill: #41b6c4;
  background: #41b6c4;
  stroke: #41b6c4;
}
.YlGnBu.q3-5 {
  fill: #2c7fb8;
  background: #2c7fb8;
  stroke: #2c7fb8;
}
.YlGnBu.q4-5 {
  fill: #253494;
  background: #253494;
  stroke: #253494;
}
.YlGnBu.q0-6 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.YlGnBu.q1-6 {
  fill: #c7e9b4;
  background: #c7e9b4;
  stroke: #c7e9b4;
}
.YlGnBu.q2-6 {
  fill: #7fcdbb;
  background: #7fcdbb;
  stroke: #7fcdbb;
}
.YlGnBu.q3-6 {
  fill: #41b6c4;
  background: #41b6c4;
  stroke: #41b6c4;
}
.YlGnBu.q4-6 {
  fill: #2c7fb8;
  background: #2c7fb8;
  stroke: #2c7fb8;
}
.YlGnBu.q5-6 {
  fill: #253494;
  background: #253494;
  stroke: #253494;
}
.YlGnBu.q0-7 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.YlGnBu.q1-7 {
  fill: #c7e9b4;
  background: #c7e9b4;
  stroke: #c7e9b4;
}
.YlGnBu.q2-7 {
  fill: #7fcdbb;
  background: #7fcdbb;
  stroke: #7fcdbb;
}
.YlGnBu.q3-7 {
  fill: #41b6c4;
  background: #41b6c4;
  stroke: #41b6c4;
}
.YlGnBu.q4-7 {
  fill: #1d91c0;
  background: #1d91c0;
  stroke: #1d91c0;
}
.YlGnBu.q5-7 {
  fill: #225ea8;
  background: #225ea8;
  stroke: #225ea8;
}
.YlGnBu.q6-7 {
  fill: #0c2c84;
  background: #0c2c84;
  stroke: #0c2c84;
}
.YlGnBu.q0-8 {
  fill: #ffffd9;
  background: #ffffd9;
  stroke: #ffffd9;
}
.YlGnBu.q1-8 {
  fill: #edf8b1;
  background: #edf8b1;
  stroke: #edf8b1;
}
.YlGnBu.q2-8 {
  fill: #c7e9b4;
  background: #c7e9b4;
  stroke: #c7e9b4;
}
.YlGnBu.q3-8 {
  fill: #7fcdbb;
  background: #7fcdbb;
  stroke: #7fcdbb;
}
.YlGnBu.q4-8 {
  fill: #41b6c4;
  background: #41b6c4;
  stroke: #41b6c4;
}
.YlGnBu.q5-8 {
  fill: #1d91c0;
  background: #1d91c0;
  stroke: #1d91c0;
}
.YlGnBu.q6-8 {
  fill: #225ea8;
  background: #225ea8;
  stroke: #225ea8;
}
.YlGnBu.q7-8 {
  fill: #0c2c84;
  background: #0c2c84;
  stroke: #0c2c84;
}
.YlGnBu.q0-9 {
  fill: #ffffd9;
  background: #ffffd9;
  stroke: #ffffd9;
}
.YlGnBu.q1-9 {
  fill: #edf8b1;
  background: #edf8b1;
  stroke: #edf8b1;
}
.YlGnBu.q2-9 {
  fill: #c7e9b4;
  background: #c7e9b4;
  stroke: #c7e9b4;
}
.YlGnBu.q3-9 {
  fill: #7fcdbb;
  background: #7fcdbb;
  stroke: #7fcdbb;
}
.YlGnBu.q4-9 {
  fill: #41b6c4;
  background: #41b6c4;
  stroke: #41b6c4;
}
.YlGnBu.q5-9 {
  fill: #1d91c0;
  background: #1d91c0;
  stroke: #1d91c0;
}
.YlGnBu.q6-9 {
  fill: #225ea8;
  background: #225ea8;
  stroke: #225ea8;
}
.YlGnBu.q7-9 {
  fill: #253494;
  background: #253494;
  stroke: #253494;
}
.YlGnBu.q8-9 {
  fill: #081d58;
  background: #081d58;
  stroke: #081d58;
}
.GnBu.q0-3 {
  fill: #e0f3db;
  background: #e0f3db;
  stroke: #e0f3db;
}
.GnBu.q1-3 {
  fill: #a8ddb5;
  background: #a8ddb5;
  stroke: #a8ddb5;
}
.GnBu.q2-3 {
  fill: #43a2ca;
  background: #43a2ca;
  stroke: #43a2ca;
}
.GnBu.q0-4 {
  fill: #f0f9e8;
  background: #f0f9e8;
  stroke: #f0f9e8;
}
.GnBu.q1-4 {
  fill: #bae4bc;
  background: #bae4bc;
  stroke: #bae4bc;
}
.GnBu.q2-4 {
  fill: #7bccc4;
  background: #7bccc4;
  stroke: #7bccc4;
}
.GnBu.q3-4 {
  fill: #2b8cbe;
  background: #2b8cbe;
  stroke: #2b8cbe;
}
.GnBu.q0-5 {
  fill: #f0f9e8;
  background: #f0f9e8;
  stroke: #f0f9e8;
}
.GnBu.q1-5 {
  fill: #bae4bc;
  background: #bae4bc;
  stroke: #bae4bc;
}
.GnBu.q2-5 {
  fill: #7bccc4;
  background: #7bccc4;
  stroke: #7bccc4;
}
.GnBu.q3-5 {
  fill: #43a2ca;
  background: #43a2ca;
  stroke: #43a2ca;
}
.GnBu.q4-5 {
  fill: #0868ac;
  background: #0868ac;
  stroke: #0868ac;
}
.GnBu.q0-6 {
  fill: #f0f9e8;
  background: #f0f9e8;
  stroke: #f0f9e8;
}
.GnBu.q1-6 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.GnBu.q2-6 {
  fill: #a8ddb5;
  background: #a8ddb5;
  stroke: #a8ddb5;
}
.GnBu.q3-6 {
  fill: #7bccc4;
  background: #7bccc4;
  stroke: #7bccc4;
}
.GnBu.q4-6 {
  fill: #43a2ca;
  background: #43a2ca;
  stroke: #43a2ca;
}
.GnBu.q5-6 {
  fill: #0868ac;
  background: #0868ac;
  stroke: #0868ac;
}
.GnBu.q0-7 {
  fill: #f0f9e8;
  background: #f0f9e8;
  stroke: #f0f9e8;
}
.GnBu.q1-7 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.GnBu.q2-7 {
  fill: #a8ddb5;
  background: #a8ddb5;
  stroke: #a8ddb5;
}
.GnBu.q3-7 {
  fill: #7bccc4;
  background: #7bccc4;
  stroke: #7bccc4;
}
.GnBu.q4-7 {
  fill: #4eb3d3;
  background: #4eb3d3;
  stroke: #4eb3d3;
}
.GnBu.q5-7 {
  fill: #2b8cbe;
  background: #2b8cbe;
  stroke: #2b8cbe;
}
.GnBu.q6-7 {
  fill: #08589e;
  background: #08589e;
  stroke: #08589e;
}
.GnBu.q0-8 {
  fill: #f7fcf0;
  background: #f7fcf0;
  stroke: #f7fcf0;
}
.GnBu.q1-8 {
  fill: #e0f3db;
  background: #e0f3db;
  stroke: #e0f3db;
}
.GnBu.q2-8 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.GnBu.q3-8 {
  fill: #a8ddb5;
  background: #a8ddb5;
  stroke: #a8ddb5;
}
.GnBu.q4-8 {
  fill: #7bccc4;
  background: #7bccc4;
  stroke: #7bccc4;
}
.GnBu.q5-8 {
  fill: #4eb3d3;
  background: #4eb3d3;
  stroke: #4eb3d3;
}
.GnBu.q6-8 {
  fill: #2b8cbe;
  background: #2b8cbe;
  stroke: #2b8cbe;
}
.GnBu.q7-8 {
  fill: #08589e;
  background: #08589e;
  stroke: #08589e;
}
.GnBu.q0-9 {
  fill: #f7fcf0;
  background: #f7fcf0;
  stroke: #f7fcf0;
}
.GnBu.q1-9 {
  fill: #e0f3db;
  background: #e0f3db;
  stroke: #e0f3db;
}
.GnBu.q2-9 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.GnBu.q3-9 {
  fill: #a8ddb5;
  background: #a8ddb5;
  stroke: #a8ddb5;
}
.GnBu.q4-9 {
  fill: #7bccc4;
  background: #7bccc4;
  stroke: #7bccc4;
}
.GnBu.q5-9 {
  fill: #4eb3d3;
  background: #4eb3d3;
  stroke: #4eb3d3;
}
.GnBu.q6-9 {
  fill: #2b8cbe;
  background: #2b8cbe;
  stroke: #2b8cbe;
}
.GnBu.q7-9 {
  fill: #0868ac;
  background: #0868ac;
  stroke: #0868ac;
}
.GnBu.q8-9 {
  fill: #084081;
  background: #084081;
  stroke: #084081;
}
.BuGn.q0-3 {
  fill: #e5f5f9;
  background: #e5f5f9;
  stroke: #e5f5f9;
}
.BuGn.q1-3 {
  fill: #99d8c9;
  background: #99d8c9;
  stroke: #99d8c9;
}
.BuGn.q2-3 {
  fill: #2ca25f;
  background: #2ca25f;
  stroke: #2ca25f;
}
.BuGn.q0-4 {
  fill: #edf8fb;
  background: #edf8fb;
  stroke: #edf8fb;
}
.BuGn.q1-4 {
  fill: #b2e2e2;
  background: #b2e2e2;
  stroke: #b2e2e2;
}
.BuGn.q2-4 {
  fill: #66c2a4;
  background: #66c2a4;
  stroke: #66c2a4;
}
.BuGn.q3-4 {
  fill: #238b45;
  background: #238b45;
  stroke: #238b45;
}
.BuGn.q0-5 {
  fill: #edf8fb;
  background: #edf8fb;
  stroke: #edf8fb;
}
.BuGn.q1-5 {
  fill: #b2e2e2;
  background: #b2e2e2;
  stroke: #b2e2e2;
}
.BuGn.q2-5 {
  fill: #66c2a4;
  background: #66c2a4;
  stroke: #66c2a4;
}
.BuGn.q3-5 {
  fill: #2ca25f;
  background: #2ca25f;
  stroke: #2ca25f;
}
.BuGn.q4-5 {
  fill: #006d2c;
  background: #006d2c;
  stroke: #006d2c;
}
.BuGn.q0-6 {
  fill: #edf8fb;
  background: #edf8fb;
  stroke: #edf8fb;
}
.BuGn.q1-6 {
  fill: #ccece6;
  background: #ccece6;
  stroke: #ccece6;
}
.BuGn.q2-6 {
  fill: #99d8c9;
  background: #99d8c9;
  stroke: #99d8c9;
}
.BuGn.q3-6 {
  fill: #66c2a4;
  background: #66c2a4;
  stroke: #66c2a4;
}
.BuGn.q4-6 {
  fill: #2ca25f;
  background: #2ca25f;
  stroke: #2ca25f;
}
.BuGn.q5-6 {
  fill: #006d2c;
  background: #006d2c;
  stroke: #006d2c;
}
.BuGn.q0-7 {
  fill: #edf8fb;
  background: #edf8fb;
  stroke: #edf8fb;
}
.BuGn.q1-7 {
  fill: #ccece6;
  background: #ccece6;
  stroke: #ccece6;
}
.BuGn.q2-7 {
  fill: #99d8c9;
  background: #99d8c9;
  stroke: #99d8c9;
}
.BuGn.q3-7 {
  fill: #66c2a4;
  background: #66c2a4;
  stroke: #66c2a4;
}
.BuGn.q4-7 {
  fill: #41ae76;
  background: #41ae76;
  stroke: #41ae76;
}
.BuGn.q5-7 {
  fill: #238b45;
  background: #238b45;
  stroke: #238b45;
}
.BuGn.q6-7 {
  fill: #005824;
  background: #005824;
  stroke: #005824;
}
.BuGn.q0-8 {
  fill: #f7fcfd;
  background: #f7fcfd;
  stroke: #f7fcfd;
}
.BuGn.q1-8 {
  fill: #e5f5f9;
  background: #e5f5f9;
  stroke: #e5f5f9;
}
.BuGn.q2-8 {
  fill: #ccece6;
  background: #ccece6;
  stroke: #ccece6;
}
.BuGn.q3-8 {
  fill: #99d8c9;
  background: #99d8c9;
  stroke: #99d8c9;
}
.BuGn.q4-8 {
  fill: #66c2a4;
  background: #66c2a4;
  stroke: #66c2a4;
}
.BuGn.q5-8 {
  fill: #41ae76;
  background: #41ae76;
  stroke: #41ae76;
}
.BuGn.q6-8 {
  fill: #238b45;
  background: #238b45;
  stroke: #238b45;
}
.BuGn.q7-8 {
  fill: #005824;
  background: #005824;
  stroke: #005824;
}
.BuGn.q0-9 {
  fill: #f7fcfd;
  background: #f7fcfd;
  stroke: #f7fcfd;
}
.BuGn.q1-9 {
  fill: #e5f5f9;
  background: #e5f5f9;
  stroke: #e5f5f9;
}
.BuGn.q2-9 {
  fill: #ccece6;
  background: #ccece6;
  stroke: #ccece6;
}
.BuGn.q3-9 {
  fill: #99d8c9;
  background: #99d8c9;
  stroke: #99d8c9;
}
.BuGn.q4-9 {
  fill: #66c2a4;
  background: #66c2a4;
  stroke: #66c2a4;
}
.BuGn.q5-9 {
  fill: #41ae76;
  background: #41ae76;
  stroke: #41ae76;
}
.BuGn.q6-9 {
  fill: #238b45;
  background: #238b45;
  stroke: #238b45;
}
.BuGn.q7-9 {
  fill: #006d2c;
  background: #006d2c;
  stroke: #006d2c;
}
.BuGn.q8-9 {
  fill: #00441b;
  background: #00441b;
  stroke: #00441b;
}
.PuBuGn.q0-3 {
  fill: #ece2f0;
  background: #ece2f0;
  stroke: #ece2f0;
}
.PuBuGn.q1-3 {
  fill: #a6bddb;
  background: #a6bddb;
  stroke: #a6bddb;
}
.PuBuGn.q2-3 {
  fill: #1c9099;
  background: #1c9099;
  stroke: #1c9099;
}
.PuBuGn.q0-4 {
  fill: #f6eff7;
  background: #f6eff7;
  stroke: #f6eff7;
}
.PuBuGn.q1-4 {
  fill: #bdc9e1;
  background: #bdc9e1;
  stroke: #bdc9e1;
}
.PuBuGn.q2-4 {
  fill: #67a9cf;
  background: #67a9cf;
  stroke: #67a9cf;
}
.PuBuGn.q3-4 {
  fill: #02818a;
  background: #02818a;
  stroke: #02818a;
}
.PuBuGn.q0-5 {
  fill: #f6eff7;
  background: #f6eff7;
  stroke: #f6eff7;
}
.PuBuGn.q1-5 {
  fill: #bdc9e1;
  background: #bdc9e1;
  stroke: #bdc9e1;
}
.PuBuGn.q2-5 {
  fill: #67a9cf;
  background: #67a9cf;
  stroke: #67a9cf;
}
.PuBuGn.q3-5 {
  fill: #1c9099;
  background: #1c9099;
  stroke: #1c9099;
}
.PuBuGn.q4-5 {
  fill: #016c59;
  background: #016c59;
  stroke: #016c59;
}
.PuBuGn.q0-6 {
  fill: #f6eff7;
  background: #f6eff7;
  stroke: #f6eff7;
}
.PuBuGn.q1-6 {
  fill: #d0d1e6;
  background: #d0d1e6;
  stroke: #d0d1e6;
}
.PuBuGn.q2-6 {
  fill: #a6bddb;
  background: #a6bddb;
  stroke: #a6bddb;
}
.PuBuGn.q3-6 {
  fill: #67a9cf;
  background: #67a9cf;
  stroke: #67a9cf;
}
.PuBuGn.q4-6 {
  fill: #1c9099;
  background: #1c9099;
  stroke: #1c9099;
}
.PuBuGn.q5-6 {
  fill: #016c59;
  background: #016c59;
  stroke: #016c59;
}
.PuBuGn.q0-7 {
  fill: #f6eff7;
  background: #f6eff7;
  stroke: #f6eff7;
}
.PuBuGn.q1-7 {
  fill: #d0d1e6;
  background: #d0d1e6;
  stroke: #d0d1e6;
}
.PuBuGn.q2-7 {
  fill: #a6bddb;
  background: #a6bddb;
  stroke: #a6bddb;
}
.PuBuGn.q3-7 {
  fill: #67a9cf;
  background: #67a9cf;
  stroke: #67a9cf;
}
.PuBuGn.q4-7 {
  fill: #3690c0;
  background: #3690c0;
  stroke: #3690c0;
}
.PuBuGn.q5-7 {
  fill: #02818a;
  background: #02818a;
  stroke: #02818a;
}
.PuBuGn.q6-7 {
  fill: #016450;
  background: #016450;
  stroke: #016450;
}
.PuBuGn.q0-8 {
  fill: #fff7fb;
  background: #fff7fb;
  stroke: #fff7fb;
}
.PuBuGn.q1-8 {
  fill: #ece2f0;
  background: #ece2f0;
  stroke: #ece2f0;
}
.PuBuGn.q2-8 {
  fill: #d0d1e6;
  background: #d0d1e6;
  stroke: #d0d1e6;
}
.PuBuGn.q3-8 {
  fill: #a6bddb;
  background: #a6bddb;
  stroke: #a6bddb;
}
.PuBuGn.q4-8 {
  fill: #67a9cf;
  background: #67a9cf;
  stroke: #67a9cf;
}
.PuBuGn.q5-8 {
  fill: #3690c0;
  background: #3690c0;
  stroke: #3690c0;
}
.PuBuGn.q6-8 {
  fill: #02818a;
  background: #02818a;
  stroke: #02818a;
}
.PuBuGn.q7-8 {
  fill: #016450;
  background: #016450;
  stroke: #016450;
}
.PuBuGn.q0-9 {
  fill: #fff7fb;
  background: #fff7fb;
  stroke: #fff7fb;
}
.PuBuGn.q1-9 {
  fill: #ece2f0;
  background: #ece2f0;
  stroke: #ece2f0;
}
.PuBuGn.q2-9 {
  fill: #d0d1e6;
  background: #d0d1e6;
  stroke: #d0d1e6;
}
.PuBuGn.q3-9 {
  fill: #a6bddb;
  background: #a6bddb;
  stroke: #a6bddb;
}
.PuBuGn.q4-9 {
  fill: #67a9cf;
  background: #67a9cf;
  stroke: #67a9cf;
}
.PuBuGn.q5-9 {
  fill: #3690c0;
  background: #3690c0;
  stroke: #3690c0;
}
.PuBuGn.q6-9 {
  fill: #02818a;
  background: #02818a;
  stroke: #02818a;
}
.PuBuGn.q7-9 {
  fill: #016c59;
  background: #016c59;
  stroke: #016c59;
}
.PuBuGn.q8-9 {
  fill: #014636;
  background: #014636;
  stroke: #014636;
}
.PuBu.q0-3 {
  fill: #ece7f2;
  background: #ece7f2;
  stroke: #ece7f2;
}
.PuBu.q1-3 {
  fill: #a6bddb;
  background: #a6bddb;
  stroke: #a6bddb;
}
.PuBu.q2-3 {
  fill: #2b8cbe;
  background: #2b8cbe;
  stroke: #2b8cbe;
}
.PuBu.q0-4 {
  fill: #f1eef6;
  background: #f1eef6;
  stroke: #f1eef6;
}
.PuBu.q1-4 {
  fill: #bdc9e1;
  background: #bdc9e1;
  stroke: #bdc9e1;
}
.PuBu.q2-4 {
  fill: #74a9cf;
  background: #74a9cf;
  stroke: #74a9cf;
}
.PuBu.q3-4 {
  fill: #0570b0;
  background: #0570b0;
  stroke: #0570b0;
}
.PuBu.q0-5 {
  fill: #f1eef6;
  background: #f1eef6;
  stroke: #f1eef6;
}
.PuBu.q1-5 {
  fill: #bdc9e1;
  background: #bdc9e1;
  stroke: #bdc9e1;
}
.PuBu.q2-5 {
  fill: #74a9cf;
  background: #74a9cf;
  stroke: #74a9cf;
}
.PuBu.q3-5 {
  fill: #2b8cbe;
  background: #2b8cbe;
  stroke: #2b8cbe;
}
.PuBu.q4-5 {
  fill: #045a8d;
  background: #045a8d;
  stroke: #045a8d;
}
.PuBu.q0-6 {
  fill: #f1eef6;
  background: #f1eef6;
  stroke: #f1eef6;
}
.PuBu.q1-6 {
  fill: #d0d1e6;
  background: #d0d1e6;
  stroke: #d0d1e6;
}
.PuBu.q2-6 {
  fill: #a6bddb;
  background: #a6bddb;
  stroke: #a6bddb;
}
.PuBu.q3-6 {
  fill: #74a9cf;
  background: #74a9cf;
  stroke: #74a9cf;
}
.PuBu.q4-6 {
  fill: #2b8cbe;
  background: #2b8cbe;
  stroke: #2b8cbe;
}
.PuBu.q5-6 {
  fill: #045a8d;
  background: #045a8d;
  stroke: #045a8d;
}
.PuBu.q0-7 {
  fill: #f1eef6;
  background: #f1eef6;
  stroke: #f1eef6;
}
.PuBu.q1-7 {
  fill: #d0d1e6;
  background: #d0d1e6;
  stroke: #d0d1e6;
}
.PuBu.q2-7 {
  fill: #a6bddb;
  background: #a6bddb;
  stroke: #a6bddb;
}
.PuBu.q3-7 {
  fill: #74a9cf;
  background: #74a9cf;
  stroke: #74a9cf;
}
.PuBu.q4-7 {
  fill: #3690c0;
  background: #3690c0;
  stroke: #3690c0;
}
.PuBu.q5-7 {
  fill: #0570b0;
  background: #0570b0;
  stroke: #0570b0;
}
.PuBu.q6-7 {
  fill: #034e7b;
  background: #034e7b;
  stroke: #034e7b;
}
.PuBu.q0-8 {
  fill: #fff7fb;
  background: #fff7fb;
  stroke: #fff7fb;
}
.PuBu.q1-8 {
  fill: #ece7f2;
  background: #ece7f2;
  stroke: #ece7f2;
}
.PuBu.q2-8 {
  fill: #d0d1e6;
  background: #d0d1e6;
  stroke: #d0d1e6;
}
.PuBu.q3-8 {
  fill: #a6bddb;
  background: #a6bddb;
  stroke: #a6bddb;
}
.PuBu.q4-8 {
  fill: #74a9cf;
  background: #74a9cf;
  stroke: #74a9cf;
}
.PuBu.q5-8 {
  fill: #3690c0;
  background: #3690c0;
  stroke: #3690c0;
}
.PuBu.q6-8 {
  fill: #0570b0;
  background: #0570b0;
  stroke: #0570b0;
}
.PuBu.q7-8 {
  fill: #034e7b;
  background: #034e7b;
  stroke: #034e7b;
}
.PuBu.q0-9 {
  fill: #fff7fb;
  background: #fff7fb;
  stroke: #fff7fb;
}
.PuBu.q1-9 {
  fill: #ece7f2;
  background: #ece7f2;
  stroke: #ece7f2;
}
.PuBu.q2-9 {
  fill: #d0d1e6;
  background: #d0d1e6;
  stroke: #d0d1e6;
}
.PuBu.q3-9 {
  fill: #a6bddb;
  background: #a6bddb;
  stroke: #a6bddb;
}
.PuBu.q4-9 {
  fill: #74a9cf;
  background: #74a9cf;
  stroke: #74a9cf;
}
.PuBu.q5-9 {
  fill: #3690c0;
  background: #3690c0;
  stroke: #3690c0;
}
.PuBu.q6-9 {
  fill: #0570b0;
  background: #0570b0;
  stroke: #0570b0;
}
.PuBu.q7-9 {
  fill: #045a8d;
  background: #045a8d;
  stroke: #045a8d;
}
.PuBu.q8-9 {
  fill: #023858;
  background: #023858;
  stroke: #023858;
}
.BuPu.q0-3 {
  fill: #e0ecf4;
  background: #e0ecf4;
  stroke: #e0ecf4;
}
.BuPu.q1-3 {
  fill: #9ebcda;
  background: #9ebcda;
  stroke: #9ebcda;
}
.BuPu.q2-3 {
  fill: #8856a7;
  background: #8856a7;
  stroke: #8856a7;
}
.BuPu.q0-4 {
  fill: #edf8fb;
  background: #edf8fb;
  stroke: #edf8fb;
}
.BuPu.q1-4 {
  fill: #b3cde3;
  background: #b3cde3;
  stroke: #b3cde3;
}
.BuPu.q2-4 {
  fill: #8c96c6;
  background: #8c96c6;
  stroke: #8c96c6;
}
.BuPu.q3-4 {
  fill: #88419d;
  background: #88419d;
  stroke: #88419d;
}
.BuPu.q0-5 {
  fill: #edf8fb;
  background: #edf8fb;
  stroke: #edf8fb;
}
.BuPu.q1-5 {
  fill: #b3cde3;
  background: #b3cde3;
  stroke: #b3cde3;
}
.BuPu.q2-5 {
  fill: #8c96c6;
  background: #8c96c6;
  stroke: #8c96c6;
}
.BuPu.q3-5 {
  fill: #8856a7;
  background: #8856a7;
  stroke: #8856a7;
}
.BuPu.q4-5 {
  fill: #810f7c;
  background: #810f7c;
  stroke: #810f7c;
}
.BuPu.q0-6 {
  fill: #edf8fb;
  background: #edf8fb;
  stroke: #edf8fb;
}
.BuPu.q1-6 {
  fill: #bfd3e6;
  background: #bfd3e6;
  stroke: #bfd3e6;
}
.BuPu.q2-6 {
  fill: #9ebcda;
  background: #9ebcda;
  stroke: #9ebcda;
}
.BuPu.q3-6 {
  fill: #8c96c6;
  background: #8c96c6;
  stroke: #8c96c6;
}
.BuPu.q4-6 {
  fill: #8856a7;
  background: #8856a7;
  stroke: #8856a7;
}
.BuPu.q5-6 {
  fill: #810f7c;
  background: #810f7c;
  stroke: #810f7c;
}
.BuPu.q0-7 {
  fill: #edf8fb;
  background: #edf8fb;
  stroke: #edf8fb;
}
.BuPu.q1-7 {
  fill: #bfd3e6;
  background: #bfd3e6;
  stroke: #bfd3e6;
}
.BuPu.q2-7 {
  fill: #9ebcda;
  background: #9ebcda;
  stroke: #9ebcda;
}
.BuPu.q3-7 {
  fill: #8c96c6;
  background: #8c96c6;
  stroke: #8c96c6;
}
.BuPu.q4-7 {
  fill: #8c6bb1;
  background: #8c6bb1;
  stroke: #8c6bb1;
}
.BuPu.q5-7 {
  fill: #88419d;
  background: #88419d;
  stroke: #88419d;
}
.BuPu.q6-7 {
  fill: #6e016b;
  background: #6e016b;
  stroke: #6e016b;
}
.BuPu.q0-8 {
  fill: #f7fcfd;
  background: #f7fcfd;
  stroke: #f7fcfd;
}
.BuPu.q1-8 {
  fill: #e0ecf4;
  background: #e0ecf4;
  stroke: #e0ecf4;
}
.BuPu.q2-8 {
  fill: #bfd3e6;
  background: #bfd3e6;
  stroke: #bfd3e6;
}
.BuPu.q3-8 {
  fill: #9ebcda;
  background: #9ebcda;
  stroke: #9ebcda;
}
.BuPu.q4-8 {
  fill: #8c96c6;
  background: #8c96c6;
  stroke: #8c96c6;
}
.BuPu.q5-8 {
  fill: #8c6bb1;
  background: #8c6bb1;
  stroke: #8c6bb1;
}
.BuPu.q6-8 {
  fill: #88419d;
  background: #88419d;
  stroke: #88419d;
}
.BuPu.q7-8 {
  fill: #6e016b;
  background: #6e016b;
  stroke: #6e016b;
}
.BuPu.q0-9 {
  fill: #f7fcfd;
  background: #f7fcfd;
  stroke: #f7fcfd;
}
.BuPu.q1-9 {
  fill: #e0ecf4;
  background: #e0ecf4;
  stroke: #e0ecf4;
}
.BuPu.q2-9 {
  fill: #bfd3e6;
  background: #bfd3e6;
  stroke: #bfd3e6;
}
.BuPu.q3-9 {
  fill: #9ebcda;
  background: #9ebcda;
  stroke: #9ebcda;
}
.BuPu.q4-9 {
  fill: #8c96c6;
  background: #8c96c6;
  stroke: #8c96c6;
}
.BuPu.q5-9 {
  fill: #8c6bb1;
  background: #8c6bb1;
  stroke: #8c6bb1;
}
.BuPu.q6-9 {
  fill: #88419d;
  background: #88419d;
  stroke: #88419d;
}
.BuPu.q7-9 {
  fill: #810f7c;
  background: #810f7c;
  stroke: #810f7c;
}
.BuPu.q8-9 {
  fill: #4d004b;
  background: #4d004b;
  stroke: #4d004b;
}
.RdPu.q0-3 {
  fill: #fde0dd;
  background: #fde0dd;
  stroke: #fde0dd;
}
.RdPu.q1-3 {
  fill: #fa9fb5;
  background: #fa9fb5;
  stroke: #fa9fb5;
}
.RdPu.q2-3 {
  fill: #c51b8a;
  background: #c51b8a;
  stroke: #c51b8a;
}
.RdPu.q0-4 {
  fill: #feebe2;
  background: #feebe2;
  stroke: #feebe2;
}
.RdPu.q1-4 {
  fill: #fbb4b9;
  background: #fbb4b9;
  stroke: #fbb4b9;
}
.RdPu.q2-4 {
  fill: #f768a1;
  background: #f768a1;
  stroke: #f768a1;
}
.RdPu.q3-4 {
  fill: #ae017e;
  background: #ae017e;
  stroke: #ae017e;
}
.RdPu.q0-5 {
  fill: #feebe2;
  background: #feebe2;
  stroke: #feebe2;
}
.RdPu.q1-5 {
  fill: #fbb4b9;
  background: #fbb4b9;
  stroke: #fbb4b9;
}
.RdPu.q2-5 {
  fill: #f768a1;
  background: #f768a1;
  stroke: #f768a1;
}
.RdPu.q3-5 {
  fill: #c51b8a;
  background: #c51b8a;
  stroke: #c51b8a;
}
.RdPu.q4-5 {
  fill: #7a0177;
  background: #7a0177;
  stroke: #7a0177;
}
.RdPu.q0-6 {
  fill: #feebe2;
  background: #feebe2;
  stroke: #feebe2;
}
.RdPu.q1-6 {
  fill: #fcc5c0;
  background: #fcc5c0;
  stroke: #fcc5c0;
}
.RdPu.q2-6 {
  fill: #fa9fb5;
  background: #fa9fb5;
  stroke: #fa9fb5;
}
.RdPu.q3-6 {
  fill: #f768a1;
  background: #f768a1;
  stroke: #f768a1;
}
.RdPu.q4-6 {
  fill: #c51b8a;
  background: #c51b8a;
  stroke: #c51b8a;
}
.RdPu.q5-6 {
  fill: #7a0177;
  background: #7a0177;
  stroke: #7a0177;
}
.RdPu.q0-7 {
  fill: #feebe2;
  background: #feebe2;
  stroke: #feebe2;
}
.RdPu.q1-7 {
  fill: #fcc5c0;
  background: #fcc5c0;
  stroke: #fcc5c0;
}
.RdPu.q2-7 {
  fill: #fa9fb5;
  background: #fa9fb5;
  stroke: #fa9fb5;
}
.RdPu.q3-7 {
  fill: #f768a1;
  background: #f768a1;
  stroke: #f768a1;
}
.RdPu.q4-7 {
  fill: #dd3497;
  background: #dd3497;
  stroke: #dd3497;
}
.RdPu.q5-7 {
  fill: #ae017e;
  background: #ae017e;
  stroke: #ae017e;
}
.RdPu.q6-7 {
  fill: #7a0177;
  background: #7a0177;
  stroke: #7a0177;
}
.RdPu.q0-8 {
  fill: #fff7f3;
  background: #fff7f3;
  stroke: #fff7f3;
}
.RdPu.q1-8 {
  fill: #fde0dd;
  background: #fde0dd;
  stroke: #fde0dd;
}
.RdPu.q2-8 {
  fill: #fcc5c0;
  background: #fcc5c0;
  stroke: #fcc5c0;
}
.RdPu.q3-8 {
  fill: #fa9fb5;
  background: #fa9fb5;
  stroke: #fa9fb5;
}
.RdPu.q4-8 {
  fill: #f768a1;
  background: #f768a1;
  stroke: #f768a1;
}
.RdPu.q5-8 {
  fill: #dd3497;
  background: #dd3497;
  stroke: #dd3497;
}
.RdPu.q6-8 {
  fill: #ae017e;
  background: #ae017e;
  stroke: #ae017e;
}
.RdPu.q7-8 {
  fill: #7a0177;
  background: #7a0177;
  stroke: #7a0177;
}
.RdPu.q0-9 {
  fill: #fff7f3;
  background: #fff7f3;
  stroke: #fff7f3;
}
.RdPu.q1-9 {
  fill: #fde0dd;
  background: #fde0dd;
  stroke: #fde0dd;
}
.RdPu.q2-9 {
  fill: #fcc5c0;
  background: #fcc5c0;
  stroke: #fcc5c0;
}
.RdPu.q3-9 {
  fill: #fa9fb5;
  background: #fa9fb5;
  stroke: #fa9fb5;
}
.RdPu.q4-9 {
  fill: #f768a1;
  background: #f768a1;
  stroke: #f768a1;
}
.RdPu.q5-9 {
  fill: #dd3497;
  background: #dd3497;
  stroke: #dd3497;
}
.RdPu.q6-9 {
  fill: #ae017e;
  background: #ae017e;
  stroke: #ae017e;
}
.RdPu.q7-9 {
  fill: #7a0177;
  background: #7a0177;
  stroke: #7a0177;
}
.RdPu.q8-9 {
  fill: #49006a;
  background: #49006a;
  stroke: #49006a;
}
.PuRd.q0-3 {
  fill: #e7e1ef;
  background: #e7e1ef;
  stroke: #e7e1ef;
}
.PuRd.q1-3 {
  fill: #c994c7;
  background: #c994c7;
  stroke: #c994c7;
}
.PuRd.q2-3 {
  fill: #dd1c77;
  background: #dd1c77;
  stroke: #dd1c77;
}
.PuRd.q0-4 {
  fill: #f1eef6;
  background: #f1eef6;
  stroke: #f1eef6;
}
.PuRd.q1-4 {
  fill: #d7b5d8;
  background: #d7b5d8;
  stroke: #d7b5d8;
}
.PuRd.q2-4 {
  fill: #df65b0;
  background: #df65b0;
  stroke: #df65b0;
}
.PuRd.q3-4 {
  fill: #ce1256;
  background: #ce1256;
  stroke: #ce1256;
}
.PuRd.q0-5 {
  fill: #f1eef6;
  background: #f1eef6;
  stroke: #f1eef6;
}
.PuRd.q1-5 {
  fill: #d7b5d8;
  background: #d7b5d8;
  stroke: #d7b5d8;
}
.PuRd.q2-5 {
  fill: #df65b0;
  background: #df65b0;
  stroke: #df65b0;
}
.PuRd.q3-5 {
  fill: #dd1c77;
  background: #dd1c77;
  stroke: #dd1c77;
}
.PuRd.q4-5 {
  fill: #980043;
  background: #980043;
  stroke: #980043;
}
.PuRd.q0-6 {
  fill: #f1eef6;
  background: #f1eef6;
  stroke: #f1eef6;
}
.PuRd.q1-6 {
  fill: #d4b9da;
  background: #d4b9da;
  stroke: #d4b9da;
}
.PuRd.q2-6 {
  fill: #c994c7;
  background: #c994c7;
  stroke: #c994c7;
}
.PuRd.q3-6 {
  fill: #df65b0;
  background: #df65b0;
  stroke: #df65b0;
}
.PuRd.q4-6 {
  fill: #dd1c77;
  background: #dd1c77;
  stroke: #dd1c77;
}
.PuRd.q5-6 {
  fill: #980043;
  background: #980043;
  stroke: #980043;
}
.PuRd.q0-7 {
  fill: #f1eef6;
  background: #f1eef6;
  stroke: #f1eef6;
}
.PuRd.q1-7 {
  fill: #d4b9da;
  background: #d4b9da;
  stroke: #d4b9da;
}
.PuRd.q2-7 {
  fill: #c994c7;
  background: #c994c7;
  stroke: #c994c7;
}
.PuRd.q3-7 {
  fill: #df65b0;
  background: #df65b0;
  stroke: #df65b0;
}
.PuRd.q4-7 {
  fill: #e7298a;
  background: #e7298a;
  stroke: #e7298a;
}
.PuRd.q5-7 {
  fill: #ce1256;
  background: #ce1256;
  stroke: #ce1256;
}
.PuRd.q6-7 {
  fill: #91003f;
  background: #91003f;
  stroke: #91003f;
}
.PuRd.q0-8 {
  fill: #f7f4f9;
  background: #f7f4f9;
  stroke: #f7f4f9;
}
.PuRd.q1-8 {
  fill: #e7e1ef;
  background: #e7e1ef;
  stroke: #e7e1ef;
}
.PuRd.q2-8 {
  fill: #d4b9da;
  background: #d4b9da;
  stroke: #d4b9da;
}
.PuRd.q3-8 {
  fill: #c994c7;
  background: #c994c7;
  stroke: #c994c7;
}
.PuRd.q4-8 {
  fill: #df65b0;
  background: #df65b0;
  stroke: #df65b0;
}
.PuRd.q5-8 {
  fill: #e7298a;
  background: #e7298a;
  stroke: #e7298a;
}
.PuRd.q6-8 {
  fill: #ce1256;
  background: #ce1256;
  stroke: #ce1256;
}
.PuRd.q7-8 {
  fill: #91003f;
  background: #91003f;
  stroke: #91003f;
}
.PuRd.q0-9 {
  fill: #f7f4f9;
  background: #f7f4f9;
  stroke: #f7f4f9;
}
.PuRd.q1-9 {
  fill: #e7e1ef;
  background: #e7e1ef;
  stroke: #e7e1ef;
}
.PuRd.q2-9 {
  fill: #d4b9da;
  background: #d4b9da;
  stroke: #d4b9da;
}
.PuRd.q3-9 {
  fill: #c994c7;
  background: #c994c7;
  stroke: #c994c7;
}
.PuRd.q4-9 {
  fill: #df65b0;
  background: #df65b0;
  stroke: #df65b0;
}
.PuRd.q5-9 {
  fill: #e7298a;
  background: #e7298a;
  stroke: #e7298a;
}
.PuRd.q6-9 {
  fill: #ce1256;
  background: #ce1256;
  stroke: #ce1256;
}
.PuRd.q7-9 {
  fill: #980043;
  background: #980043;
  stroke: #980043;
}
.PuRd.q8-9 {
  fill: #67001f;
  background: #67001f;
  stroke: #67001f;
}
.OrRd.q0-3 {
  fill: #fee8c8;
  background: #fee8c8;
  stroke: #fee8c8;
}
.OrRd.q1-3 {
  fill: #fdbb84;
  background: #fdbb84;
  stroke: #fdbb84;
}
.OrRd.q2-3 {
  fill: #e34a33;
  background: #e34a33;
  stroke: #e34a33;
}
.OrRd.q0-4 {
  fill: #fef0d9;
  background: #fef0d9;
  stroke: #fef0d9;
}
.OrRd.q1-4 {
  fill: #fdcc8a;
  background: #fdcc8a;
  stroke: #fdcc8a;
}
.OrRd.q2-4 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.OrRd.q3-4 {
  fill: #d7301f;
  background: #d7301f;
  stroke: #d7301f;
}
.OrRd.q0-5 {
  fill: #fef0d9;
  background: #fef0d9;
  stroke: #fef0d9;
}
.OrRd.q1-5 {
  fill: #fdcc8a;
  background: #fdcc8a;
  stroke: #fdcc8a;
}
.OrRd.q2-5 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.OrRd.q3-5 {
  fill: #e34a33;
  background: #e34a33;
  stroke: #e34a33;
}
.OrRd.q4-5 {
  fill: #b30000;
  background: #b30000;
  stroke: #b30000;
}
.OrRd.q0-6 {
  fill: #fef0d9;
  background: #fef0d9;
  stroke: #fef0d9;
}
.OrRd.q1-6 {
  fill: #fdd49e;
  background: #fdd49e;
  stroke: #fdd49e;
}
.OrRd.q2-6 {
  fill: #fdbb84;
  background: #fdbb84;
  stroke: #fdbb84;
}
.OrRd.q3-6 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.OrRd.q4-6 {
  fill: #e34a33;
  background: #e34a33;
  stroke: #e34a33;
}
.OrRd.q5-6 {
  fill: #b30000;
  background: #b30000;
  stroke: #b30000;
}
.OrRd.q0-7 {
  fill: #fef0d9;
  background: #fef0d9;
  stroke: #fef0d9;
}
.OrRd.q1-7 {
  fill: #fdd49e;
  background: #fdd49e;
  stroke: #fdd49e;
}
.OrRd.q2-7 {
  fill: #fdbb84;
  background: #fdbb84;
  stroke: #fdbb84;
}
.OrRd.q3-7 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.OrRd.q4-7 {
  fill: #ef6548;
  background: #ef6548;
  stroke: #ef6548;
}
.OrRd.q5-7 {
  fill: #d7301f;
  background: #d7301f;
  stroke: #d7301f;
}
.OrRd.q6-7 {
  fill: #990000;
  background: #990000;
  stroke: #990000;
}
.OrRd.q0-8 {
  fill: #fff7ec;
  background: #fff7ec;
  stroke: #fff7ec;
}
.OrRd.q1-8 {
  fill: #fee8c8;
  background: #fee8c8;
  stroke: #fee8c8;
}
.OrRd.q2-8 {
  fill: #fdd49e;
  background: #fdd49e;
  stroke: #fdd49e;
}
.OrRd.q3-8 {
  fill: #fdbb84;
  background: #fdbb84;
  stroke: #fdbb84;
}
.OrRd.q4-8 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.OrRd.q5-8 {
  fill: #ef6548;
  background: #ef6548;
  stroke: #ef6548;
}
.OrRd.q6-8 {
  fill: #d7301f;
  background: #d7301f;
  stroke: #d7301f;
}
.OrRd.q7-8 {
  fill: #990000;
  background: #990000;
  stroke: #990000;
}
.OrRd.q0-9 {
  fill: #fff7ec;
  background: #fff7ec;
  stroke: #fff7ec;
}
.OrRd.q1-9 {
  fill: #fee8c8;
  background: #fee8c8;
  stroke: #fee8c8;
}
.OrRd.q2-9 {
  fill: #fdd49e;
  background: #fdd49e;
  stroke: #fdd49e;
}
.OrRd.q3-9 {
  fill: #fdbb84;
  background: #fdbb84;
  stroke: #fdbb84;
}
.OrRd.q4-9 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.OrRd.q5-9 {
  fill: #ef6548;
  background: #ef6548;
  stroke: #ef6548;
}
.OrRd.q6-9 {
  fill: #d7301f;
  background: #d7301f;
  stroke: #d7301f;
}
.OrRd.q7-9 {
  fill: #b30000;
  background: #b30000;
  stroke: #b30000;
}
.OrRd.q8-9 {
  fill: #7f0000;
  background: #7f0000;
  stroke: #7f0000;
}
.YlOrRd.q0-3 {
  fill: #ffeda0;
  background: #ffeda0;
  stroke: #ffeda0;
}
.YlOrRd.q1-3 {
  fill: #feb24c;
  background: #feb24c;
  stroke: #feb24c;
}
.YlOrRd.q2-3 {
  fill: #f03b20;
  background: #f03b20;
  stroke: #f03b20;
}
.YlOrRd.q0-4 {
  fill: #ffffb2;
  background: #ffffb2;
  stroke: #ffffb2;
}
.YlOrRd.q1-4 {
  fill: #fecc5c;
  background: #fecc5c;
  stroke: #fecc5c;
}
.YlOrRd.q2-4 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.YlOrRd.q3-4 {
  fill: #e31a1c;
  background: #e31a1c;
  stroke: #e31a1c;
}
.YlOrRd.q0-5 {
  fill: #ffffb2;
  background: #ffffb2;
  stroke: #ffffb2;
}
.YlOrRd.q1-5 {
  fill: #fecc5c;
  background: #fecc5c;
  stroke: #fecc5c;
}
.YlOrRd.q2-5 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.YlOrRd.q3-5 {
  fill: #f03b20;
  background: #f03b20;
  stroke: #f03b20;
}
.YlOrRd.q4-5 {
  fill: #bd0026;
  background: #bd0026;
  stroke: #bd0026;
}
.YlOrRd.q0-6 {
  fill: #ffffb2;
  background: #ffffb2;
  stroke: #ffffb2;
}
.YlOrRd.q1-6 {
  fill: #fed976;
  background: #fed976;
  stroke: #fed976;
}
.YlOrRd.q2-6 {
  fill: #feb24c;
  background: #feb24c;
  stroke: #feb24c;
}
.YlOrRd.q3-6 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.YlOrRd.q4-6 {
  fill: #f03b20;
  background: #f03b20;
  stroke: #f03b20;
}
.YlOrRd.q5-6 {
  fill: #bd0026;
  background: #bd0026;
  stroke: #bd0026;
}
.YlOrRd.q0-7 {
  fill: #ffffb2;
  background: #ffffb2;
  stroke: #ffffb2;
}
.YlOrRd.q1-7 {
  fill: #fed976;
  background: #fed976;
  stroke: #fed976;
}
.YlOrRd.q2-7 {
  fill: #feb24c;
  background: #feb24c;
  stroke: #feb24c;
}
.YlOrRd.q3-7 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.YlOrRd.q4-7 {
  fill: #fc4e2a;
  background: #fc4e2a;
  stroke: #fc4e2a;
}
.YlOrRd.q5-7 {
  fill: #e31a1c;
  background: #e31a1c;
  stroke: #e31a1c;
}
.YlOrRd.q6-7 {
  fill: #b10026;
  background: #b10026;
  stroke: #b10026;
}
.YlOrRd.q0-8 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.YlOrRd.q1-8 {
  fill: #ffeda0;
  background: #ffeda0;
  stroke: #ffeda0;
}
.YlOrRd.q2-8 {
  fill: #fed976;
  background: #fed976;
  stroke: #fed976;
}
.YlOrRd.q3-8 {
  fill: #feb24c;
  background: #feb24c;
  stroke: #feb24c;
}
.YlOrRd.q4-8 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.YlOrRd.q5-8 {
  fill: #fc4e2a;
  background: #fc4e2a;
  stroke: #fc4e2a;
}
.YlOrRd.q6-8 {
  fill: #e31a1c;
  background: #e31a1c;
  stroke: #e31a1c;
}
.YlOrRd.q7-8 {
  fill: #b10026;
  background: #b10026;
  stroke: #b10026;
}
.YlOrRd.q0-9 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.YlOrRd.q1-9 {
  fill: #ffeda0;
  background: #ffeda0;
  stroke: #ffeda0;
}
.YlOrRd.q2-9 {
  fill: #fed976;
  background: #fed976;
  stroke: #fed976;
}
.YlOrRd.q3-9 {
  fill: #feb24c;
  background: #feb24c;
  stroke: #feb24c;
}
.YlOrRd.q4-9 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.YlOrRd.q5-9 {
  fill: #fc4e2a;
  background: #fc4e2a;
  stroke: #fc4e2a;
}
.YlOrRd.q6-9 {
  fill: #e31a1c;
  background: #e31a1c;
  stroke: #e31a1c;
}
.YlOrRd.q7-9 {
  fill: #bd0026;
  background: #bd0026;
  stroke: #bd0026;
}
.YlOrRd.q8-9 {
  fill: #800026;
  background: #800026;
  stroke: #800026;
}
.YlOrBr.q0-3 {
  fill: #fff7bc;
  background: #fff7bc;
  stroke: #fff7bc;
}
.YlOrBr.q1-3 {
  fill: #fec44f;
  background: #fec44f;
  stroke: #fec44f;
}
.YlOrBr.q2-3 {
  fill: #d95f0e;
  background: #d95f0e;
  stroke: #d95f0e;
}
.YlOrBr.q0-4 {
  fill: #ffffd4;
  background: #ffffd4;
  stroke: #ffffd4;
}
.YlOrBr.q1-4 {
  fill: #fed98e;
  background: #fed98e;
  stroke: #fed98e;
}
.YlOrBr.q2-4 {
  fill: #fe9929;
  background: #fe9929;
  stroke: #fe9929;
}
.YlOrBr.q3-4 {
  fill: #cc4c02;
  background: #cc4c02;
  stroke: #cc4c02;
}
.YlOrBr.q0-5 {
  fill: #ffffd4;
  background: #ffffd4;
  stroke: #ffffd4;
}
.YlOrBr.q1-5 {
  fill: #fed98e;
  background: #fed98e;
  stroke: #fed98e;
}
.YlOrBr.q2-5 {
  fill: #fe9929;
  background: #fe9929;
  stroke: #fe9929;
}
.YlOrBr.q3-5 {
  fill: #d95f0e;
  background: #d95f0e;
  stroke: #d95f0e;
}
.YlOrBr.q4-5 {
  fill: #993404;
  background: #993404;
  stroke: #993404;
}
.YlOrBr.q0-6 {
  fill: #ffffd4;
  background: #ffffd4;
  stroke: #ffffd4;
}
.YlOrBr.q1-6 {
  fill: #fee391;
  background: #fee391;
  stroke: #fee391;
}
.YlOrBr.q2-6 {
  fill: #fec44f;
  background: #fec44f;
  stroke: #fec44f;
}
.YlOrBr.q3-6 {
  fill: #fe9929;
  background: #fe9929;
  stroke: #fe9929;
}
.YlOrBr.q4-6 {
  fill: #d95f0e;
  background: #d95f0e;
  stroke: #d95f0e;
}
.YlOrBr.q5-6 {
  fill: #993404;
  background: #993404;
  stroke: #993404;
}
.YlOrBr.q0-7 {
  fill: #ffffd4;
  background: #ffffd4;
  stroke: #ffffd4;
}
.YlOrBr.q1-7 {
  fill: #fee391;
  background: #fee391;
  stroke: #fee391;
}
.YlOrBr.q2-7 {
  fill: #fec44f;
  background: #fec44f;
  stroke: #fec44f;
}
.YlOrBr.q3-7 {
  fill: #fe9929;
  background: #fe9929;
  stroke: #fe9929;
}
.YlOrBr.q4-7 {
  fill: #ec7014;
  background: #ec7014;
  stroke: #ec7014;
}
.YlOrBr.q5-7 {
  fill: #cc4c02;
  background: #cc4c02;
  stroke: #cc4c02;
}
.YlOrBr.q6-7 {
  fill: #8c2d04;
  background: #8c2d04;
  stroke: #8c2d04;
}
.YlOrBr.q0-8 {
  fill: #ffffe5;
  background: #ffffe5;
  stroke: #ffffe5;
}
.YlOrBr.q1-8 {
  fill: #fff7bc;
  background: #fff7bc;
  stroke: #fff7bc;
}
.YlOrBr.q2-8 {
  fill: #fee391;
  background: #fee391;
  stroke: #fee391;
}
.YlOrBr.q3-8 {
  fill: #fec44f;
  background: #fec44f;
  stroke: #fec44f;
}
.YlOrBr.q4-8 {
  fill: #fe9929;
  background: #fe9929;
  stroke: #fe9929;
}
.YlOrBr.q5-8 {
  fill: #ec7014;
  background: #ec7014;
  stroke: #ec7014;
}
.YlOrBr.q6-8 {
  fill: #cc4c02;
  background: #cc4c02;
  stroke: #cc4c02;
}
.YlOrBr.q7-8 {
  fill: #8c2d04;
  background: #8c2d04;
  stroke: #8c2d04;
}
.YlOrBr.q0-9 {
  fill: #ffffe5;
  background: #ffffe5;
  stroke: #ffffe5;
}
.YlOrBr.q1-9 {
  fill: #fff7bc;
  background: #fff7bc;
  stroke: #fff7bc;
}
.YlOrBr.q2-9 {
  fill: #fee391;
  background: #fee391;
  stroke: #fee391;
}
.YlOrBr.q3-9 {
  fill: #fec44f;
  background: #fec44f;
  stroke: #fec44f;
}
.YlOrBr.q4-9 {
  fill: #fe9929;
  background: #fe9929;
  stroke: #fe9929;
}
.YlOrBr.q5-9 {
  fill: #ec7014;
  background: #ec7014;
  stroke: #ec7014;
}
.YlOrBr.q6-9 {
  fill: #cc4c02;
  background: #cc4c02;
  stroke: #cc4c02;
}
.YlOrBr.q7-9 {
  fill: #993404;
  background: #993404;
  stroke: #993404;
}
.YlOrBr.q8-9 {
  fill: #662506;
  background: #662506;
  stroke: #662506;
}
.Purples.q0-3 {
  fill: #efedf5;
  background: #efedf5;
  stroke: #efedf5;
}
.Purples.q1-3 {
  fill: #bcbddc;
  background: #bcbddc;
  stroke: #bcbddc;
}
.Purples.q2-3 {
  fill: #756bb1;
  background: #756bb1;
  stroke: #756bb1;
}
.Purples.q0-4 {
  fill: #f2f0f7;
  background: #f2f0f7;
  stroke: #f2f0f7;
}
.Purples.q1-4 {
  fill: #cbc9e2;
  background: #cbc9e2;
  stroke: #cbc9e2;
}
.Purples.q2-4 {
  fill: #9e9ac8;
  background: #9e9ac8;
  stroke: #9e9ac8;
}
.Purples.q3-4 {
  fill: #6a51a3;
  background: #6a51a3;
  stroke: #6a51a3;
}
.Purples.q0-5 {
  fill: #f2f0f7;
  background: #f2f0f7;
  stroke: #f2f0f7;
}
.Purples.q1-5 {
  fill: #cbc9e2;
  background: #cbc9e2;
  stroke: #cbc9e2;
}
.Purples.q2-5 {
  fill: #9e9ac8;
  background: #9e9ac8;
  stroke: #9e9ac8;
}
.Purples.q3-5 {
  fill: #756bb1;
  background: #756bb1;
  stroke: #756bb1;
}
.Purples.q4-5 {
  fill: #54278f;
  background: #54278f;
  stroke: #54278f;
}
.Purples.q0-6 {
  fill: #f2f0f7;
  background: #f2f0f7;
  stroke: #f2f0f7;
}
.Purples.q1-6 {
  fill: #dadaeb;
  background: #dadaeb;
  stroke: #dadaeb;
}
.Purples.q2-6 {
  fill: #bcbddc;
  background: #bcbddc;
  stroke: #bcbddc;
}
.Purples.q3-6 {
  fill: #9e9ac8;
  background: #9e9ac8;
  stroke: #9e9ac8;
}
.Purples.q4-6 {
  fill: #756bb1;
  background: #756bb1;
  stroke: #756bb1;
}
.Purples.q5-6 {
  fill: #54278f;
  background: #54278f;
  stroke: #54278f;
}
.Purples.q0-7 {
  fill: #f2f0f7;
  background: #f2f0f7;
  stroke: #f2f0f7;
}
.Purples.q1-7 {
  fill: #dadaeb;
  background: #dadaeb;
  stroke: #dadaeb;
}
.Purples.q2-7 {
  fill: #bcbddc;
  background: #bcbddc;
  stroke: #bcbddc;
}
.Purples.q3-7 {
  fill: #9e9ac8;
  background: #9e9ac8;
  stroke: #9e9ac8;
}
.Purples.q4-7 {
  fill: #807dba;
  background: #807dba;
  stroke: #807dba;
}
.Purples.q5-7 {
  fill: #6a51a3;
  background: #6a51a3;
  stroke: #6a51a3;
}
.Purples.q6-7 {
  fill: #4a1486;
  background: #4a1486;
  stroke: #4a1486;
}
.Purples.q0-8 {
  fill: #fcfbfd;
  background: #fcfbfd;
  stroke: #fcfbfd;
}
.Purples.q1-8 {
  fill: #efedf5;
  background: #efedf5;
  stroke: #efedf5;
}
.Purples.q2-8 {
  fill: #dadaeb;
  background: #dadaeb;
  stroke: #dadaeb;
}
.Purples.q3-8 {
  fill: #bcbddc;
  background: #bcbddc;
  stroke: #bcbddc;
}
.Purples.q4-8 {
  fill: #9e9ac8;
  background: #9e9ac8;
  stroke: #9e9ac8;
}
.Purples.q5-8 {
  fill: #807dba;
  background: #807dba;
  stroke: #807dba;
}
.Purples.q6-8 {
  fill: #6a51a3;
  background: #6a51a3;
  stroke: #6a51a3;
}
.Purples.q7-8 {
  fill: #4a1486;
  background: #4a1486;
  stroke: #4a1486;
}
.Purples.q0-9 {
  fill: #fcfbfd;
  background: #fcfbfd;
  stroke: #fcfbfd;
}
.Purples.q1-9 {
  fill: #efedf5;
  background: #efedf5;
  stroke: #efedf5;
}
.Purples.q2-9 {
  fill: #dadaeb;
  background: #dadaeb;
  stroke: #dadaeb;
}
.Purples.q3-9 {
  fill: #bcbddc;
  background: #bcbddc;
  stroke: #bcbddc;
}
.Purples.q4-9 {
  fill: #9e9ac8;
  background: #9e9ac8;
  stroke: #9e9ac8;
}
.Purples.q5-9 {
  fill: #807dba;
  background: #807dba;
  stroke: #807dba;
}
.Purples.q6-9 {
  fill: #6a51a3;
  background: #6a51a3;
  stroke: #6a51a3;
}
.Purples.q7-9 {
  fill: #54278f;
  background: #54278f;
  stroke: #54278f;
}
.Purples.q8-9 {
  fill: #3f007d;
  background: #3f007d;
  stroke: #3f007d;
}
.Blues.q0-3 {
  fill: #deebf7;
  background: #deebf7;
  stroke: #deebf7;
}
.Blues.q1-3 {
  fill: #9ecae1;
  background: #9ecae1;
  stroke: #9ecae1;
}
.Blues.q2-3 {
  fill: #3182bd;
  background: #3182bd;
  stroke: #3182bd;
}
.Blues.q0-4 {
  fill: #eff3ff;
  background: #eff3ff;
  stroke: #eff3ff;
}
.Blues.q1-4 {
  fill: #bdd7e7;
  background: #bdd7e7;
  stroke: #bdd7e7;
}
.Blues.q2-4 {
  fill: #6baed6;
  background: #6baed6;
  stroke: #6baed6;
}
.Blues.q3-4 {
  fill: #2171b5;
  background: #2171b5;
  stroke: #2171b5;
}
.Blues.q0-5 {
  fill: #eff3ff;
  background: #eff3ff;
  stroke: #eff3ff;
}
.Blues.q1-5 {
  fill: #bdd7e7;
  background: #bdd7e7;
  stroke: #bdd7e7;
}
.Blues.q2-5 {
  fill: #6baed6;
  background: #6baed6;
  stroke: #6baed6;
}
.Blues.q3-5 {
  fill: #3182bd;
  background: #3182bd;
  stroke: #3182bd;
}
.Blues.q4-5 {
  fill: #08519c;
  background: #08519c;
  stroke: #08519c;
}
.Blues.q0-6 {
  fill: #eff3ff;
  background: #eff3ff;
  stroke: #eff3ff;
}
.Blues.q1-6 {
  fill: #c6dbef;
  background: #c6dbef;
  stroke: #c6dbef;
}
.Blues.q2-6 {
  fill: #9ecae1;
  background: #9ecae1;
  stroke: #9ecae1;
}
.Blues.q3-6 {
  fill: #6baed6;
  background: #6baed6;
  stroke: #6baed6;
}
.Blues.q4-6 {
  fill: #3182bd;
  background: #3182bd;
  stroke: #3182bd;
}
.Blues.q5-6 {
  fill: #08519c;
  background: #08519c;
  stroke: #08519c;
}
.Blues.q0-7 {
  fill: #eff3ff;
  background: #eff3ff;
  stroke: #eff3ff;
}
.Blues.q1-7 {
  fill: #c6dbef;
  background: #c6dbef;
  stroke: #c6dbef;
}
.Blues.q2-7 {
  fill: #9ecae1;
  background: #9ecae1;
  stroke: #9ecae1;
}
.Blues.q3-7 {
  fill: #6baed6;
  background: #6baed6;
  stroke: #6baed6;
}
.Blues.q4-7 {
  fill: #4292c6;
  background: #4292c6;
  stroke: #4292c6;
}
.Blues.q5-7 {
  fill: #2171b5;
  background: #2171b5;
  stroke: #2171b5;
}
.Blues.q6-7 {
  fill: #084594;
  background: #084594;
  stroke: #084594;
}
.Blues.q0-8 {
  fill: #f7fbff;
  background: #f7fbff;
  stroke: #f7fbff;
}
.Blues.q1-8 {
  fill: #deebf7;
  background: #deebf7;
  stroke: #deebf7;
}
.Blues.q2-8 {
  fill: #c6dbef;
  background: #c6dbef;
  stroke: #c6dbef;
}
.Blues.q3-8 {
  fill: #9ecae1;
  background: #9ecae1;
  stroke: #9ecae1;
}
.Blues.q4-8 {
  fill: #6baed6;
  background: #6baed6;
  stroke: #6baed6;
}
.Blues.q5-8 {
  fill: #4292c6;
  background: #4292c6;
  stroke: #4292c6;
}
.Blues.q6-8 {
  fill: #2171b5;
  background: #2171b5;
  stroke: #2171b5;
}
.Blues.q7-8 {
  fill: #084594;
  background: #084594;
  stroke: #084594;
}
.Blues.q0-9 {
  fill: #f7fbff;
  background: #f7fbff;
  stroke: #f7fbff;
}
.Blues.q1-9 {
  fill: #deebf7;
  background: #deebf7;
  stroke: #deebf7;
}
.Blues.q2-9 {
  fill: #c6dbef;
  background: #c6dbef;
  stroke: #c6dbef;
}
.Blues.q3-9 {
  fill: #9ecae1;
  background: #9ecae1;
  stroke: #9ecae1;
}
.Blues.q4-9 {
  fill: #6baed6;
  background: #6baed6;
  stroke: #6baed6;
}
.Blues.q5-9 {
  fill: #4292c6;
  background: #4292c6;
  stroke: #4292c6;
}
.Blues.q6-9 {
  fill: #2171b5;
  background: #2171b5;
  stroke: #2171b5;
}
.Blues.q7-9 {
  fill: #08519c;
  background: #08519c;
  stroke: #08519c;
}
.Blues.q8-9 {
  fill: #08306b;
  background: #08306b;
  stroke: #08306b;
}
.Greens.q0-3 {
  fill: #e5f5e0;
  background: #e5f5e0;
  stroke: #e5f5e0;
}
.Greens.q1-3 {
  fill: #a1d99b;
  background: #a1d99b;
  stroke: #a1d99b;
}
.Greens.q2-3 {
  fill: #31a354;
  background: #31a354;
  stroke: #31a354;
}
.Greens.q0-4 {
  fill: #edf8e9;
  background: #edf8e9;
  stroke: #edf8e9;
}
.Greens.q1-4 {
  fill: #bae4b3;
  background: #bae4b3;
  stroke: #bae4b3;
}
.Greens.q2-4 {
  fill: #74c476;
  background: #74c476;
  stroke: #74c476;
}
.Greens.q3-4 {
  fill: #238b45;
  background: #238b45;
  stroke: #238b45;
}
.Greens.q0-5 {
  fill: #edf8e9;
  background: #edf8e9;
  stroke: #edf8e9;
}
.Greens.q1-5 {
  fill: #bae4b3;
  background: #bae4b3;
  stroke: #bae4b3;
}
.Greens.q2-5 {
  fill: #74c476;
  background: #74c476;
  stroke: #74c476;
}
.Greens.q3-5 {
  fill: #31a354;
  background: #31a354;
  stroke: #31a354;
}
.Greens.q4-5 {
  fill: #006d2c;
  background: #006d2c;
  stroke: #006d2c;
}
.Greens.q0-6 {
  fill: #edf8e9;
  background: #edf8e9;
  stroke: #edf8e9;
}
.Greens.q1-6 {
  fill: #c7e9c0;
  background: #c7e9c0;
  stroke: #c7e9c0;
}
.Greens.q2-6 {
  fill: #a1d99b;
  background: #a1d99b;
  stroke: #a1d99b;
}
.Greens.q3-6 {
  fill: #74c476;
  background: #74c476;
  stroke: #74c476;
}
.Greens.q4-6 {
  fill: #31a354;
  background: #31a354;
  stroke: #31a354;
}
.Greens.q5-6 {
  fill: #006d2c;
  background: #006d2c;
  stroke: #006d2c;
}
.Greens.q0-7 {
  fill: #edf8e9;
  background: #edf8e9;
  stroke: #edf8e9;
}
.Greens.q1-7 {
  fill: #c7e9c0;
  background: #c7e9c0;
  stroke: #c7e9c0;
}
.Greens.q2-7 {
  fill: #a1d99b;
  background: #a1d99b;
  stroke: #a1d99b;
}
.Greens.q3-7 {
  fill: #74c476;
  background: #74c476;
  stroke: #74c476;
}
.Greens.q4-7 {
  fill: #41ab5d;
  background: #41ab5d;
  stroke: #41ab5d;
}
.Greens.q5-7 {
  fill: #238b45;
  background: #238b45;
  stroke: #238b45;
}
.Greens.q6-7 {
  fill: #005a32;
  background: #005a32;
  stroke: #005a32;
}
.Greens.q0-8 {
  fill: #f7fcf5;
  background: #f7fcf5;
  stroke: #f7fcf5;
}
.Greens.q1-8 {
  fill: #e5f5e0;
  background: #e5f5e0;
  stroke: #e5f5e0;
}
.Greens.q2-8 {
  fill: #c7e9c0;
  background: #c7e9c0;
  stroke: #c7e9c0;
}
.Greens.q3-8 {
  fill: #a1d99b;
  background: #a1d99b;
  stroke: #a1d99b;
}
.Greens.q4-8 {
  fill: #74c476;
  background: #74c476;
  stroke: #74c476;
}
.Greens.q5-8 {
  fill: #41ab5d;
  background: #41ab5d;
  stroke: #41ab5d;
}
.Greens.q6-8 {
  fill: #238b45;
  background: #238b45;
  stroke: #238b45;
}
.Greens.q7-8 {
  fill: #005a32;
  background: #005a32;
  stroke: #005a32;
}
.Greens.q0-9 {
  fill: #f7fcf5;
  background: #f7fcf5;
  stroke: #f7fcf5;
}
.Greens.q1-9 {
  fill: #e5f5e0;
  background: #e5f5e0;
  stroke: #e5f5e0;
}
.Greens.q2-9 {
  fill: #c7e9c0;
  background: #c7e9c0;
  stroke: #c7e9c0;
}
.Greens.q3-9 {
  fill: #a1d99b;
  background: #a1d99b;
  stroke: #a1d99b;
}
.Greens.q4-9 {
  fill: #74c476;
  background: #74c476;
  stroke: #74c476;
}
.Greens.q5-9 {
  fill: #41ab5d;
  background: #41ab5d;
  stroke: #41ab5d;
}
.Greens.q6-9 {
  fill: #238b45;
  background: #238b45;
  stroke: #238b45;
}
.Greens.q7-9 {
  fill: #006d2c;
  background: #006d2c;
  stroke: #006d2c;
}
.Greens.q8-9 {
  fill: #00441b;
  background: #00441b;
  stroke: #00441b;
}
.Oranges.q0-3 {
  fill: #fee6ce;
  background: #fee6ce;
  stroke: #fee6ce;
}
.Oranges.q1-3 {
  fill: #fdae6b;
  background: #fdae6b;
  stroke: #fdae6b;
}
.Oranges.q2-3 {
  fill: #e6550d;
  background: #e6550d;
  stroke: #e6550d;
}
.Oranges.q0-4 {
  fill: #feedde;
  background: #feedde;
  stroke: #feedde;
}
.Oranges.q1-4 {
  fill: #fdbe85;
  background: #fdbe85;
  stroke: #fdbe85;
}
.Oranges.q2-4 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.Oranges.q3-4 {
  fill: #d94701;
  background: #d94701;
  stroke: #d94701;
}
.Oranges.q0-5 {
  fill: #feedde;
  background: #feedde;
  stroke: #feedde;
}
.Oranges.q1-5 {
  fill: #fdbe85;
  background: #fdbe85;
  stroke: #fdbe85;
}
.Oranges.q2-5 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.Oranges.q3-5 {
  fill: #e6550d;
  background: #e6550d;
  stroke: #e6550d;
}
.Oranges.q4-5 {
  fill: #a63603;
  background: #a63603;
  stroke: #a63603;
}
.Oranges.q0-6 {
  fill: #feedde;
  background: #feedde;
  stroke: #feedde;
}
.Oranges.q1-6 {
  fill: #fdd0a2;
  background: #fdd0a2;
  stroke: #fdd0a2;
}
.Oranges.q2-6 {
  fill: #fdae6b;
  background: #fdae6b;
  stroke: #fdae6b;
}
.Oranges.q3-6 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.Oranges.q4-6 {
  fill: #e6550d;
  background: #e6550d;
  stroke: #e6550d;
}
.Oranges.q5-6 {
  fill: #a63603;
  background: #a63603;
  stroke: #a63603;
}
.Oranges.q0-7 {
  fill: #feedde;
  background: #feedde;
  stroke: #feedde;
}
.Oranges.q1-7 {
  fill: #fdd0a2;
  background: #fdd0a2;
  stroke: #fdd0a2;
}
.Oranges.q2-7 {
  fill: #fdae6b;
  background: #fdae6b;
  stroke: #fdae6b;
}
.Oranges.q3-7 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.Oranges.q4-7 {
  fill: #f16913;
  background: #f16913;
  stroke: #f16913;
}
.Oranges.q5-7 {
  fill: #d94801;
  background: #d94801;
  stroke: #d94801;
}
.Oranges.q6-7 {
  fill: #8c2d04;
  background: #8c2d04;
  stroke: #8c2d04;
}
.Oranges.q0-8 {
  fill: #fff5eb;
  background: #fff5eb;
  stroke: #fff5eb;
}
.Oranges.q1-8 {
  fill: #fee6ce;
  background: #fee6ce;
  stroke: #fee6ce;
}
.Oranges.q2-8 {
  fill: #fdd0a2;
  background: #fdd0a2;
  stroke: #fdd0a2;
}
.Oranges.q3-8 {
  fill: #fdae6b;
  background: #fdae6b;
  stroke: #fdae6b;
}
.Oranges.q4-8 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.Oranges.q5-8 {
  fill: #f16913;
  background: #f16913;
  stroke: #f16913;
}
.Oranges.q6-8 {
  fill: #d94801;
  background: #d94801;
  stroke: #d94801;
}
.Oranges.q7-8 {
  fill: #8c2d04;
  background: #8c2d04;
  stroke: #8c2d04;
}
.Oranges.q0-9 {
  fill: #fff5eb;
  background: #fff5eb;
  stroke: #fff5eb;
}
.Oranges.q1-9 {
  fill: #fee6ce;
  background: #fee6ce;
  stroke: #fee6ce;
}
.Oranges.q2-9 {
  fill: #fdd0a2;
  background: #fdd0a2;
  stroke: #fdd0a2;
}
.Oranges.q3-9 {
  fill: #fdae6b;
  background: #fdae6b;
  stroke: #fdae6b;
}
.Oranges.q4-9 {
  fill: #fd8d3c;
  background: #fd8d3c;
  stroke: #fd8d3c;
}
.Oranges.q5-9 {
  fill: #f16913;
  background: #f16913;
  stroke: #f16913;
}
.Oranges.q6-9 {
  fill: #d94801;
  background: #d94801;
  stroke: #d94801;
}
.Oranges.q7-9 {
  fill: #a63603;
  background: #a63603;
  stroke: #a63603;
}
.Oranges.q8-9 {
  fill: #7f2704;
  background: #7f2704;
  stroke: #7f2704;
}
.Reds.q0-3 {
  fill: #fee0d2;
  background: #fee0d2;
  stroke: #fee0d2;
}
.Reds.q1-3 {
  fill: #fc9272;
  background: #fc9272;
  stroke: #fc9272;
}
.Reds.q2-3 {
  fill: #de2d26;
  background: #de2d26;
  stroke: #de2d26;
}
.Reds.q0-4 {
  fill: #fee5d9;
  background: #fee5d9;
  stroke: #fee5d9;
}
.Reds.q1-4 {
  fill: #fcae91;
  background: #fcae91;
  stroke: #fcae91;
}
.Reds.q2-4 {
  fill: #fb6a4a;
  background: #fb6a4a;
  stroke: #fb6a4a;
}
.Reds.q3-4 {
  fill: #cb181d;
  background: #cb181d;
  stroke: #cb181d;
}
.Reds.q0-5 {
  fill: #fee5d9;
  background: #fee5d9;
  stroke: #fee5d9;
}
.Reds.q1-5 {
  fill: #fcae91;
  background: #fcae91;
  stroke: #fcae91;
}
.Reds.q2-5 {
  fill: #fb6a4a;
  background: #fb6a4a;
  stroke: #fb6a4a;
}
.Reds.q3-5 {
  fill: #de2d26;
  background: #de2d26;
  stroke: #de2d26;
}
.Reds.q4-5 {
  fill: #a50f15;
  background: #a50f15;
  stroke: #a50f15;
}
.Reds.q0-6 {
  fill: #fee5d9;
  background: #fee5d9;
  stroke: #fee5d9;
}
.Reds.q1-6 {
  fill: #fcbba1;
  background: #fcbba1;
  stroke: #fcbba1;
}
.Reds.q2-6 {
  fill: #fc9272;
  background: #fc9272;
  stroke: #fc9272;
}
.Reds.q3-6 {
  fill: #fb6a4a;
  background: #fb6a4a;
  stroke: #fb6a4a;
}
.Reds.q4-6 {
  fill: #de2d26;
  background: #de2d26;
  stroke: #de2d26;
}
.Reds.q5-6 {
  fill: #a50f15;
  background: #a50f15;
  stroke: #a50f15;
}
.Reds.q0-7 {
  fill: #fee5d9;
  background: #fee5d9;
  stroke: #fee5d9;
}
.Reds.q1-7 {
  fill: #fcbba1;
  background: #fcbba1;
  stroke: #fcbba1;
}
.Reds.q2-7 {
  fill: #fc9272;
  background: #fc9272;
  stroke: #fc9272;
}
.Reds.q3-7 {
  fill: #fb6a4a;
  background: #fb6a4a;
  stroke: #fb6a4a;
}
.Reds.q4-7 {
  fill: #ef3b2c;
  background: #ef3b2c;
  stroke: #ef3b2c;
}
.Reds.q5-7 {
  fill: #cb181d;
  background: #cb181d;
  stroke: #cb181d;
}
.Reds.q6-7 {
  fill: #99000d;
  background: #99000d;
  stroke: #99000d;
}
.Reds.q0-8 {
  fill: #fff5f0;
  background: #fff5f0;
  stroke: #fff5f0;
}
.Reds.q1-8 {
  fill: #fee0d2;
  background: #fee0d2;
  stroke: #fee0d2;
}
.Reds.q2-8 {
  fill: #fcbba1;
  background: #fcbba1;
  stroke: #fcbba1;
}
.Reds.q3-8 {
  fill: #fc9272;
  background: #fc9272;
  stroke: #fc9272;
}
.Reds.q4-8 {
  fill: #fb6a4a;
  background: #fb6a4a;
  stroke: #fb6a4a;
}
.Reds.q5-8 {
  fill: #ef3b2c;
  background: #ef3b2c;
  stroke: #ef3b2c;
}
.Reds.q6-8 {
  fill: #cb181d;
  background: #cb181d;
  stroke: #cb181d;
}
.Reds.q7-8 {
  fill: #99000d;
  background: #99000d;
  stroke: #99000d;
}
.Reds.q0-9 {
  fill: #fff5f0;
  background: #fff5f0;
  stroke: #fff5f0;
}
.Reds.q1-9 {
  fill: #fee0d2;
  background: #fee0d2;
  stroke: #fee0d2;
}
.Reds.q2-9 {
  fill: #fcbba1;
  background: #fcbba1;
  stroke: #fcbba1;
}
.Reds.q3-9 {
  fill: #fc9272;
  background: #fc9272;
  stroke: #fc9272;
}
.Reds.q4-9 {
  fill: #fb6a4a;
  background: #fb6a4a;
  stroke: #fb6a4a;
}
.Reds.q5-9 {
  fill: #ef3b2c;
  background: #ef3b2c;
  stroke: #ef3b2c;
}
.Reds.q6-9 {
  fill: #cb181d;
  background: #cb181d;
  stroke: #cb181d;
}
.Reds.q7-9 {
  fill: #a50f15;
  background: #a50f15;
  stroke: #a50f15;
}
.Reds.q8-9 {
  fill: #67000d;
  background: #67000d;
  stroke: #67000d;
}
.Greys.q0-3 {
  fill: #f0f0f0;
  background: #f0f0f0;
  stroke: #f0f0f0;
}
.Greys.q1-3 {
  fill: #bdbdbd;
  background: #bdbdbd;
  stroke: #bdbdbd;
}
.Greys.q2-3 {
  fill: #636363;
  background: #636363;
  stroke: #636363;
}
.Greys.q0-4 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.Greys.q1-4 {
  fill: #cccccc;
  background: #cccccc;
  stroke: #cccccc;
}
.Greys.q2-4 {
  fill: #969696;
  background: #969696;
  stroke: #969696;
}
.Greys.q3-4 {
  fill: #525252;
  background: #525252;
  stroke: #525252;
}
.Greys.q0-5 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.Greys.q1-5 {
  fill: #cccccc;
  background: #cccccc;
  stroke: #cccccc;
}
.Greys.q2-5 {
  fill: #969696;
  background: #969696;
  stroke: #969696;
}
.Greys.q3-5 {
  fill: #636363;
  background: #636363;
  stroke: #636363;
}
.Greys.q4-5 {
  fill: #252525;
  background: #252525;
  stroke: #252525;
}
.Greys.q0-6 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.Greys.q1-6 {
  fill: #d9d9d9;
  background: #d9d9d9;
  stroke: #d9d9d9;
}
.Greys.q2-6 {
  fill: #bdbdbd;
  background: #bdbdbd;
  stroke: #bdbdbd;
}
.Greys.q3-6 {
  fill: #969696;
  background: #969696;
  stroke: #969696;
}
.Greys.q4-6 {
  fill: #636363;
  background: #636363;
  stroke: #636363;
}
.Greys.q5-6 {
  fill: #252525;
  background: #252525;
  stroke: #252525;
}
.Greys.q0-7 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.Greys.q1-7 {
  fill: #d9d9d9;
  background: #d9d9d9;
  stroke: #d9d9d9;
}
.Greys.q2-7 {
  fill: #bdbdbd;
  background: #bdbdbd;
  stroke: #bdbdbd;
}
.Greys.q3-7 {
  fill: #969696;
  background: #969696;
  stroke: #969696;
}
.Greys.q4-7 {
  fill: #737373;
  background: #737373;
  stroke: #737373;
}
.Greys.q5-7 {
  fill: #525252;
  background: #525252;
  stroke: #525252;
}
.Greys.q6-7 {
  fill: #252525;
  background: #252525;
  stroke: #252525;
}
.Greys.q0-8 {
  fill: #ffffff;
  background: #ffffff;
  stroke: #ffffff;
}
.Greys.q1-8 {
  fill: #f0f0f0;
  background: #f0f0f0;
  stroke: #f0f0f0;
}
.Greys.q2-8 {
  fill: #d9d9d9;
  background: #d9d9d9;
  stroke: #d9d9d9;
}
.Greys.q3-8 {
  fill: #bdbdbd;
  background: #bdbdbd;
  stroke: #bdbdbd;
}
.Greys.q4-8 {
  fill: #969696;
  background: #969696;
  stroke: #969696;
}
.Greys.q5-8 {
  fill: #737373;
  background: #737373;
  stroke: #737373;
}
.Greys.q6-8 {
  fill: #525252;
  background: #525252;
  stroke: #525252;
}
.Greys.q7-8 {
  fill: #252525;
  background: #252525;
  stroke: #252525;
}
.Greys.q0-9 {
  fill: #ffffff;
  background: #ffffff;
  stroke: #ffffff;
}
.Greys.q1-9 {
  fill: #f0f0f0;
  background: #f0f0f0;
  stroke: #f0f0f0;
}
.Greys.q2-9 {
  fill: #d9d9d9;
  background: #d9d9d9;
  stroke: #d9d9d9;
}
.Greys.q3-9 {
  fill: #bdbdbd;
  background: #bdbdbd;
  stroke: #bdbdbd;
}
.Greys.q4-9 {
  fill: #969696;
  background: #969696;
  stroke: #969696;
}
.Greys.q5-9 {
  fill: #737373;
  background: #737373;
  stroke: #737373;
}
.Greys.q6-9 {
  fill: #525252;
  background: #525252;
  stroke: #525252;
}
.Greys.q7-9 {
  fill: #252525;
  background: #252525;
  stroke: #252525;
}
.Greys.q8-9 {
  fill: #000000;
  background: #000000;
  stroke: #000000;
}
.PuOr.q0-3 {
  fill: #f1a340;
  background: #f1a340;
  stroke: #f1a340;
}
.PuOr.q1-3 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PuOr.q2-3 {
  fill: #998ec3;
  background: #998ec3;
  stroke: #998ec3;
}
.PuOr.q0-4 {
  fill: #e66101;
  background: #e66101;
  stroke: #e66101;
}
.PuOr.q1-4 {
  fill: #fdb863;
  background: #fdb863;
  stroke: #fdb863;
}
.PuOr.q2-4 {
  fill: #b2abd2;
  background: #b2abd2;
  stroke: #b2abd2;
}
.PuOr.q3-4 {
  fill: #5e3c99;
  background: #5e3c99;
  stroke: #5e3c99;
}
.PuOr.q0-5 {
  fill: #e66101;
  background: #e66101;
  stroke: #e66101;
}
.PuOr.q1-5 {
  fill: #fdb863;
  background: #fdb863;
  stroke: #fdb863;
}
.PuOr.q2-5 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PuOr.q3-5 {
  fill: #b2abd2;
  background: #b2abd2;
  stroke: #b2abd2;
}
.PuOr.q4-5 {
  fill: #5e3c99;
  background: #5e3c99;
  stroke: #5e3c99;
}
.PuOr.q0-6 {
  fill: #b35806;
  background: #b35806;
  stroke: #b35806;
}
.PuOr.q1-6 {
  fill: #f1a340;
  background: #f1a340;
  stroke: #f1a340;
}
.PuOr.q2-6 {
  fill: #fee0b6;
  background: #fee0b6;
  stroke: #fee0b6;
}
.PuOr.q3-6 {
  fill: #d8daeb;
  background: #d8daeb;
  stroke: #d8daeb;
}
.PuOr.q4-6 {
  fill: #998ec3;
  background: #998ec3;
  stroke: #998ec3;
}
.PuOr.q5-6 {
  fill: #542788;
  background: #542788;
  stroke: #542788;
}
.PuOr.q0-7 {
  fill: #b35806;
  background: #b35806;
  stroke: #b35806;
}
.PuOr.q1-7 {
  fill: #f1a340;
  background: #f1a340;
  stroke: #f1a340;
}
.PuOr.q2-7 {
  fill: #fee0b6;
  background: #fee0b6;
  stroke: #fee0b6;
}
.PuOr.q3-7 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PuOr.q4-7 {
  fill: #d8daeb;
  background: #d8daeb;
  stroke: #d8daeb;
}
.PuOr.q5-7 {
  fill: #998ec3;
  background: #998ec3;
  stroke: #998ec3;
}
.PuOr.q6-7 {
  fill: #542788;
  background: #542788;
  stroke: #542788;
}
.PuOr.q0-8 {
  fill: #b35806;
  background: #b35806;
  stroke: #b35806;
}
.PuOr.q1-8 {
  fill: #e08214;
  background: #e08214;
  stroke: #e08214;
}
.PuOr.q2-8 {
  fill: #fdb863;
  background: #fdb863;
  stroke: #fdb863;
}
.PuOr.q3-8 {
  fill: #fee0b6;
  background: #fee0b6;
  stroke: #fee0b6;
}
.PuOr.q4-8 {
  fill: #d8daeb;
  background: #d8daeb;
  stroke: #d8daeb;
}
.PuOr.q5-8 {
  fill: #b2abd2;
  background: #b2abd2;
  stroke: #b2abd2;
}
.PuOr.q6-8 {
  fill: #8073ac;
  background: #8073ac;
  stroke: #8073ac;
}
.PuOr.q7-8 {
  fill: #542788;
  background: #542788;
  stroke: #542788;
}
.PuOr.q0-9 {
  fill: #b35806;
  background: #b35806;
  stroke: #b35806;
}
.PuOr.q1-9 {
  fill: #e08214;
  background: #e08214;
  stroke: #e08214;
}
.PuOr.q2-9 {
  fill: #fdb863;
  background: #fdb863;
  stroke: #fdb863;
}
.PuOr.q3-9 {
  fill: #fee0b6;
  background: #fee0b6;
  stroke: #fee0b6;
}
.PuOr.q4-9 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PuOr.q5-9 {
  fill: #d8daeb;
  background: #d8daeb;
  stroke: #d8daeb;
}
.PuOr.q6-9 {
  fill: #b2abd2;
  background: #b2abd2;
  stroke: #b2abd2;
}
.PuOr.q7-9 {
  fill: #8073ac;
  background: #8073ac;
  stroke: #8073ac;
}
.PuOr.q8-9 {
  fill: #542788;
  background: #542788;
  stroke: #542788;
}
.PuOr.q0-10 {
  fill: #7f3b08;
  background: #7f3b08;
  stroke: #7f3b08;
}
.PuOr.q1-10 {
  fill: #b35806;
  background: #b35806;
  stroke: #b35806;
}
.PuOr.q2-10 {
  fill: #e08214;
  background: #e08214;
  stroke: #e08214;
}
.PuOr.q3-10 {
  fill: #fdb863;
  background: #fdb863;
  stroke: #fdb863;
}
.PuOr.q4-10 {
  fill: #fee0b6;
  background: #fee0b6;
  stroke: #fee0b6;
}
.PuOr.q5-10 {
  fill: #d8daeb;
  background: #d8daeb;
  stroke: #d8daeb;
}
.PuOr.q6-10 {
  fill: #b2abd2;
  background: #b2abd2;
  stroke: #b2abd2;
}
.PuOr.q7-10 {
  fill: #8073ac;
  background: #8073ac;
  stroke: #8073ac;
}
.PuOr.q8-10 {
  fill: #542788;
  background: #542788;
  stroke: #542788;
}
.PuOr.q9-10 {
  fill: #2d004b;
  background: #2d004b;
  stroke: #2d004b;
}
.PuOr.q0-11 {
  fill: #7f3b08;
  background: #7f3b08;
  stroke: #7f3b08;
}
.PuOr.q1-11 {
  fill: #b35806;
  background: #b35806;
  stroke: #b35806;
}
.PuOr.q2-11 {
  fill: #e08214;
  background: #e08214;
  stroke: #e08214;
}
.PuOr.q3-11 {
  fill: #fdb863;
  background: #fdb863;
  stroke: #fdb863;
}
.PuOr.q4-11 {
  fill: #fee0b6;
  background: #fee0b6;
  stroke: #fee0b6;
}
.PuOr.q5-11 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PuOr.q6-11 {
  fill: #d8daeb;
  background: #d8daeb;
  stroke: #d8daeb;
}
.PuOr.q7-11 {
  fill: #b2abd2;
  background: #b2abd2;
  stroke: #b2abd2;
}
.PuOr.q8-11 {
  fill: #8073ac;
  background: #8073ac;
  stroke: #8073ac;
}
.PuOr.q9-11 {
  fill: #542788;
  background: #542788;
  stroke: #542788;
}
.PuOr.q10-11 {
  fill: #2d004b;
  background: #2d004b;
  stroke: #2d004b;
}
.BrBG.q0-3 {
  fill: #d8b365;
  background: #d8b365;
  stroke: #d8b365;
}
.BrBG.q1-3 {
  fill: #f5f5f5;
  background: #f5f5f5;
  stroke: #f5f5f5;
}
.BrBG.q2-3 {
  fill: #5ab4ac;
  background: #5ab4ac;
  stroke: #5ab4ac;
}
.BrBG.q0-4 {
  fill: #a6611a;
  background: #a6611a;
  stroke: #a6611a;
}
.BrBG.q1-4 {
  fill: #dfc27d;
  background: #dfc27d;
  stroke: #dfc27d;
}
.BrBG.q2-4 {
  fill: #80cdc1;
  background: #80cdc1;
  stroke: #80cdc1;
}
.BrBG.q3-4 {
  fill: #018571;
  background: #018571;
  stroke: #018571;
}
.BrBG.q0-5 {
  fill: #a6611a;
  background: #a6611a;
  stroke: #a6611a;
}
.BrBG.q1-5 {
  fill: #dfc27d;
  background: #dfc27d;
  stroke: #dfc27d;
}
.BrBG.q2-5 {
  fill: #f5f5f5;
  background: #f5f5f5;
  stroke: #f5f5f5;
}
.BrBG.q3-5 {
  fill: #80cdc1;
  background: #80cdc1;
  stroke: #80cdc1;
}
.BrBG.q4-5 {
  fill: #018571;
  background: #018571;
  stroke: #018571;
}
.BrBG.q0-6 {
  fill: #8c510a;
  background: #8c510a;
  stroke: #8c510a;
}
.BrBG.q1-6 {
  fill: #d8b365;
  background: #d8b365;
  stroke: #d8b365;
}
.BrBG.q2-6 {
  fill: #f6e8c3;
  background: #f6e8c3;
  stroke: #f6e8c3;
}
.BrBG.q3-6 {
  fill: #c7eae5;
  background: #c7eae5;
  stroke: #c7eae5;
}
.BrBG.q4-6 {
  fill: #5ab4ac;
  background: #5ab4ac;
  stroke: #5ab4ac;
}
.BrBG.q5-6 {
  fill: #01665e;
  background: #01665e;
  stroke: #01665e;
}
.BrBG.q0-7 {
  fill: #8c510a;
  background: #8c510a;
  stroke: #8c510a;
}
.BrBG.q1-7 {
  fill: #d8b365;
  background: #d8b365;
  stroke: #d8b365;
}
.BrBG.q2-7 {
  fill: #f6e8c3;
  background: #f6e8c3;
  stroke: #f6e8c3;
}
.BrBG.q3-7 {
  fill: #f5f5f5;
  background: #f5f5f5;
  stroke: #f5f5f5;
}
.BrBG.q4-7 {
  fill: #c7eae5;
  background: #c7eae5;
  stroke: #c7eae5;
}
.BrBG.q5-7 {
  fill: #5ab4ac;
  background: #5ab4ac;
  stroke: #5ab4ac;
}
.BrBG.q6-7 {
  fill: #01665e;
  background: #01665e;
  stroke: #01665e;
}
.BrBG.q0-8 {
  fill: #8c510a;
  background: #8c510a;
  stroke: #8c510a;
}
.BrBG.q1-8 {
  fill: #bf812d;
  background: #bf812d;
  stroke: #bf812d;
}
.BrBG.q2-8 {
  fill: #dfc27d;
  background: #dfc27d;
  stroke: #dfc27d;
}
.BrBG.q3-8 {
  fill: #f6e8c3;
  background: #f6e8c3;
  stroke: #f6e8c3;
}
.BrBG.q4-8 {
  fill: #c7eae5;
  background: #c7eae5;
  stroke: #c7eae5;
}
.BrBG.q5-8 {
  fill: #80cdc1;
  background: #80cdc1;
  stroke: #80cdc1;
}
.BrBG.q6-8 {
  fill: #35978f;
  background: #35978f;
  stroke: #35978f;
}
.BrBG.q7-8 {
  fill: #01665e;
  background: #01665e;
  stroke: #01665e;
}
.BrBG.q0-9 {
  fill: #8c510a;
  background: #8c510a;
  stroke: #8c510a;
}
.BrBG.q1-9 {
  fill: #bf812d;
  background: #bf812d;
  stroke: #bf812d;
}
.BrBG.q2-9 {
  fill: #dfc27d;
  background: #dfc27d;
  stroke: #dfc27d;
}
.BrBG.q3-9 {
  fill: #f6e8c3;
  background: #f6e8c3;
  stroke: #f6e8c3;
}
.BrBG.q4-9 {
  fill: #f5f5f5;
  background: #f5f5f5;
  stroke: #f5f5f5;
}
.BrBG.q5-9 {
  fill: #c7eae5;
  background: #c7eae5;
  stroke: #c7eae5;
}
.BrBG.q6-9 {
  fill: #80cdc1;
  background: #80cdc1;
  stroke: #80cdc1;
}
.BrBG.q7-9 {
  fill: #35978f;
  background: #35978f;
  stroke: #35978f;
}
.BrBG.q8-9 {
  fill: #01665e;
  background: #01665e;
  stroke: #01665e;
}
.BrBG.q0-10 {
  fill: #543005;
  background: #543005;
  stroke: #543005;
}
.BrBG.q1-10 {
  fill: #8c510a;
  background: #8c510a;
  stroke: #8c510a;
}
.BrBG.q2-10 {
  fill: #bf812d;
  background: #bf812d;
  stroke: #bf812d;
}
.BrBG.q3-10 {
  fill: #dfc27d;
  background: #dfc27d;
  stroke: #dfc27d;
}
.BrBG.q4-10 {
  fill: #f6e8c3;
  background: #f6e8c3;
  stroke: #f6e8c3;
}
.BrBG.q5-10 {
  fill: #c7eae5;
  background: #c7eae5;
  stroke: #c7eae5;
}
.BrBG.q6-10 {
  fill: #80cdc1;
  background: #80cdc1;
  stroke: #80cdc1;
}
.BrBG.q7-10 {
  fill: #35978f;
  background: #35978f;
  stroke: #35978f;
}
.BrBG.q8-10 {
  fill: #01665e;
  background: #01665e;
  stroke: #01665e;
}
.BrBG.q9-10 {
  fill: #003c30;
  background: #003c30;
  stroke: #003c30;
}
.BrBG.q0-11 {
  fill: #543005;
  background: #543005;
  stroke: #543005;
}
.BrBG.q1-11 {
  fill: #8c510a;
  background: #8c510a;
  stroke: #8c510a;
}
.BrBG.q2-11 {
  fill: #bf812d;
  background: #bf812d;
  stroke: #bf812d;
}
.BrBG.q3-11 {
  fill: #dfc27d;
  background: #dfc27d;
  stroke: #dfc27d;
}
.BrBG.q4-11 {
  fill: #f6e8c3;
  background: #f6e8c3;
  stroke: #f6e8c3;
}
.BrBG.q5-11 {
  fill: #f5f5f5;
  background: #f5f5f5;
  stroke: #f5f5f5;
}
.BrBG.q6-11 {
  fill: #c7eae5;
  background: #c7eae5;
  stroke: #c7eae5;
}
.BrBG.q7-11 {
  fill: #80cdc1;
  background: #80cdc1;
  stroke: #80cdc1;
}
.BrBG.q8-11 {
  fill: #35978f;
  background: #35978f;
  stroke: #35978f;
}
.BrBG.q9-11 {
  fill: #01665e;
  background: #01665e;
  stroke: #01665e;
}
.BrBG.q10-11 {
  fill: #003c30;
  background: #003c30;
  stroke: #003c30;
}
.PRGn.q0-3 {
  fill: #af8dc3;
  background: #af8dc3;
  stroke: #af8dc3;
}
.PRGn.q1-3 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PRGn.q2-3 {
  fill: #7fbf7b;
  background: #7fbf7b;
  stroke: #7fbf7b;
}
.PRGn.q0-4 {
  fill: #7b3294;
  background: #7b3294;
  stroke: #7b3294;
}
.PRGn.q1-4 {
  fill: #c2a5cf;
  background: #c2a5cf;
  stroke: #c2a5cf;
}
.PRGn.q2-4 {
  fill: #a6dba0;
  background: #a6dba0;
  stroke: #a6dba0;
}
.PRGn.q3-4 {
  fill: #008837;
  background: #008837;
  stroke: #008837;
}
.PRGn.q0-5 {
  fill: #7b3294;
  background: #7b3294;
  stroke: #7b3294;
}
.PRGn.q1-5 {
  fill: #c2a5cf;
  background: #c2a5cf;
  stroke: #c2a5cf;
}
.PRGn.q2-5 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PRGn.q3-5 {
  fill: #a6dba0;
  background: #a6dba0;
  stroke: #a6dba0;
}
.PRGn.q4-5 {
  fill: #008837;
  background: #008837;
  stroke: #008837;
}
.PRGn.q0-6 {
  fill: #762a83;
  background: #762a83;
  stroke: #762a83;
}
.PRGn.q1-6 {
  fill: #af8dc3;
  background: #af8dc3;
  stroke: #af8dc3;
}
.PRGn.q2-6 {
  fill: #e7d4e8;
  background: #e7d4e8;
  stroke: #e7d4e8;
}
.PRGn.q3-6 {
  fill: #d9f0d3;
  background: #d9f0d3;
  stroke: #d9f0d3;
}
.PRGn.q4-6 {
  fill: #7fbf7b;
  background: #7fbf7b;
  stroke: #7fbf7b;
}
.PRGn.q5-6 {
  fill: #1b7837;
  background: #1b7837;
  stroke: #1b7837;
}
.PRGn.q0-7 {
  fill: #762a83;
  background: #762a83;
  stroke: #762a83;
}
.PRGn.q1-7 {
  fill: #af8dc3;
  background: #af8dc3;
  stroke: #af8dc3;
}
.PRGn.q2-7 {
  fill: #e7d4e8;
  background: #e7d4e8;
  stroke: #e7d4e8;
}
.PRGn.q3-7 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PRGn.q4-7 {
  fill: #d9f0d3;
  background: #d9f0d3;
  stroke: #d9f0d3;
}
.PRGn.q5-7 {
  fill: #7fbf7b;
  background: #7fbf7b;
  stroke: #7fbf7b;
}
.PRGn.q6-7 {
  fill: #1b7837;
  background: #1b7837;
  stroke: #1b7837;
}
.PRGn.q0-8 {
  fill: #762a83;
  background: #762a83;
  stroke: #762a83;
}
.PRGn.q1-8 {
  fill: #9970ab;
  background: #9970ab;
  stroke: #9970ab;
}
.PRGn.q2-8 {
  fill: #c2a5cf;
  background: #c2a5cf;
  stroke: #c2a5cf;
}
.PRGn.q3-8 {
  fill: #e7d4e8;
  background: #e7d4e8;
  stroke: #e7d4e8;
}
.PRGn.q4-8 {
  fill: #d9f0d3;
  background: #d9f0d3;
  stroke: #d9f0d3;
}
.PRGn.q5-8 {
  fill: #a6dba0;
  background: #a6dba0;
  stroke: #a6dba0;
}
.PRGn.q6-8 {
  fill: #5aae61;
  background: #5aae61;
  stroke: #5aae61;
}
.PRGn.q7-8 {
  fill: #1b7837;
  background: #1b7837;
  stroke: #1b7837;
}
.PRGn.q0-9 {
  fill: #762a83;
  background: #762a83;
  stroke: #762a83;
}
.PRGn.q1-9 {
  fill: #9970ab;
  background: #9970ab;
  stroke: #9970ab;
}
.PRGn.q2-9 {
  fill: #c2a5cf;
  background: #c2a5cf;
  stroke: #c2a5cf;
}
.PRGn.q3-9 {
  fill: #e7d4e8;
  background: #e7d4e8;
  stroke: #e7d4e8;
}
.PRGn.q4-9 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PRGn.q5-9 {
  fill: #d9f0d3;
  background: #d9f0d3;
  stroke: #d9f0d3;
}
.PRGn.q6-9 {
  fill: #a6dba0;
  background: #a6dba0;
  stroke: #a6dba0;
}
.PRGn.q7-9 {
  fill: #5aae61;
  background: #5aae61;
  stroke: #5aae61;
}
.PRGn.q8-9 {
  fill: #1b7837;
  background: #1b7837;
  stroke: #1b7837;
}
.PRGn.q0-10 {
  fill: #40004b;
  background: #40004b;
  stroke: #40004b;
}
.PRGn.q1-10 {
  fill: #762a83;
  background: #762a83;
  stroke: #762a83;
}
.PRGn.q2-10 {
  fill: #9970ab;
  background: #9970ab;
  stroke: #9970ab;
}
.PRGn.q3-10 {
  fill: #c2a5cf;
  background: #c2a5cf;
  stroke: #c2a5cf;
}
.PRGn.q4-10 {
  fill: #e7d4e8;
  background: #e7d4e8;
  stroke: #e7d4e8;
}
.PRGn.q5-10 {
  fill: #d9f0d3;
  background: #d9f0d3;
  stroke: #d9f0d3;
}
.PRGn.q6-10 {
  fill: #a6dba0;
  background: #a6dba0;
  stroke: #a6dba0;
}
.PRGn.q7-10 {
  fill: #5aae61;
  background: #5aae61;
  stroke: #5aae61;
}
.PRGn.q8-10 {
  fill: #1b7837;
  background: #1b7837;
  stroke: #1b7837;
}
.PRGn.q9-10 {
  fill: #00441b;
  background: #00441b;
  stroke: #00441b;
}
.PRGn.q0-11 {
  fill: #40004b;
  background: #40004b;
  stroke: #40004b;
}
.PRGn.q1-11 {
  fill: #762a83;
  background: #762a83;
  stroke: #762a83;
}
.PRGn.q2-11 {
  fill: #9970ab;
  background: #9970ab;
  stroke: #9970ab;
}
.PRGn.q3-11 {
  fill: #c2a5cf;
  background: #c2a5cf;
  stroke: #c2a5cf;
}
.PRGn.q4-11 {
  fill: #e7d4e8;
  background: #e7d4e8;
  stroke: #e7d4e8;
}
.PRGn.q5-11 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PRGn.q6-11 {
  fill: #d9f0d3;
  background: #d9f0d3;
  stroke: #d9f0d3;
}
.PRGn.q7-11 {
  fill: #a6dba0;
  background: #a6dba0;
  stroke: #a6dba0;
}
.PRGn.q8-11 {
  fill: #5aae61;
  background: #5aae61;
  stroke: #5aae61;
}
.PRGn.q9-11 {
  fill: #1b7837;
  background: #1b7837;
  stroke: #1b7837;
}
.PRGn.q10-11 {
  fill: #00441b;
  background: #00441b;
  stroke: #00441b;
}
.PiYG.q0-3 {
  fill: #e9a3c9;
  background: #e9a3c9;
  stroke: #e9a3c9;
}
.PiYG.q1-3 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PiYG.q2-3 {
  fill: #a1d76a;
  background: #a1d76a;
  stroke: #a1d76a;
}
.PiYG.q0-4 {
  fill: #d01c8b;
  background: #d01c8b;
  stroke: #d01c8b;
}
.PiYG.q1-4 {
  fill: #f1b6da;
  background: #f1b6da;
  stroke: #f1b6da;
}
.PiYG.q2-4 {
  fill: #b8e186;
  background: #b8e186;
  stroke: #b8e186;
}
.PiYG.q3-4 {
  fill: #4dac26;
  background: #4dac26;
  stroke: #4dac26;
}
.PiYG.q0-5 {
  fill: #d01c8b;
  background: #d01c8b;
  stroke: #d01c8b;
}
.PiYG.q1-5 {
  fill: #f1b6da;
  background: #f1b6da;
  stroke: #f1b6da;
}
.PiYG.q2-5 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PiYG.q3-5 {
  fill: #b8e186;
  background: #b8e186;
  stroke: #b8e186;
}
.PiYG.q4-5 {
  fill: #4dac26;
  background: #4dac26;
  stroke: #4dac26;
}
.PiYG.q0-6 {
  fill: #c51b7d;
  background: #c51b7d;
  stroke: #c51b7d;
}
.PiYG.q1-6 {
  fill: #e9a3c9;
  background: #e9a3c9;
  stroke: #e9a3c9;
}
.PiYG.q2-6 {
  fill: #fde0ef;
  background: #fde0ef;
  stroke: #fde0ef;
}
.PiYG.q3-6 {
  fill: #e6f5d0;
  background: #e6f5d0;
  stroke: #e6f5d0;
}
.PiYG.q4-6 {
  fill: #a1d76a;
  background: #a1d76a;
  stroke: #a1d76a;
}
.PiYG.q5-6 {
  fill: #4d9221;
  background: #4d9221;
  stroke: #4d9221;
}
.PiYG.q0-7 {
  fill: #c51b7d;
  background: #c51b7d;
  stroke: #c51b7d;
}
.PiYG.q1-7 {
  fill: #e9a3c9;
  background: #e9a3c9;
  stroke: #e9a3c9;
}
.PiYG.q2-7 {
  fill: #fde0ef;
  background: #fde0ef;
  stroke: #fde0ef;
}
.PiYG.q3-7 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PiYG.q4-7 {
  fill: #e6f5d0;
  background: #e6f5d0;
  stroke: #e6f5d0;
}
.PiYG.q5-7 {
  fill: #a1d76a;
  background: #a1d76a;
  stroke: #a1d76a;
}
.PiYG.q6-7 {
  fill: #4d9221;
  background: #4d9221;
  stroke: #4d9221;
}
.PiYG.q0-8 {
  fill: #c51b7d;
  background: #c51b7d;
  stroke: #c51b7d;
}
.PiYG.q1-8 {
  fill: #de77ae;
  background: #de77ae;
  stroke: #de77ae;
}
.PiYG.q2-8 {
  fill: #f1b6da;
  background: #f1b6da;
  stroke: #f1b6da;
}
.PiYG.q3-8 {
  fill: #fde0ef;
  background: #fde0ef;
  stroke: #fde0ef;
}
.PiYG.q4-8 {
  fill: #e6f5d0;
  background: #e6f5d0;
  stroke: #e6f5d0;
}
.PiYG.q5-8 {
  fill: #b8e186;
  background: #b8e186;
  stroke: #b8e186;
}
.PiYG.q6-8 {
  fill: #7fbc41;
  background: #7fbc41;
  stroke: #7fbc41;
}
.PiYG.q7-8 {
  fill: #4d9221;
  background: #4d9221;
  stroke: #4d9221;
}
.PiYG.q0-9 {
  fill: #c51b7d;
  background: #c51b7d;
  stroke: #c51b7d;
}
.PiYG.q1-9 {
  fill: #de77ae;
  background: #de77ae;
  stroke: #de77ae;
}
.PiYG.q2-9 {
  fill: #f1b6da;
  background: #f1b6da;
  stroke: #f1b6da;
}
.PiYG.q3-9 {
  fill: #fde0ef;
  background: #fde0ef;
  stroke: #fde0ef;
}
.PiYG.q4-9 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PiYG.q5-9 {
  fill: #e6f5d0;
  background: #e6f5d0;
  stroke: #e6f5d0;
}
.PiYG.q6-9 {
  fill: #b8e186;
  background: #b8e186;
  stroke: #b8e186;
}
.PiYG.q7-9 {
  fill: #7fbc41;
  background: #7fbc41;
  stroke: #7fbc41;
}
.PiYG.q8-9 {
  fill: #4d9221;
  background: #4d9221;
  stroke: #4d9221;
}
.PiYG.q0-10 {
  fill: #8e0152;
  background: #8e0152;
  stroke: #8e0152;
}
.PiYG.q1-10 {
  fill: #c51b7d;
  background: #c51b7d;
  stroke: #c51b7d;
}
.PiYG.q2-10 {
  fill: #de77ae;
  background: #de77ae;
  stroke: #de77ae;
}
.PiYG.q3-10 {
  fill: #f1b6da;
  background: #f1b6da;
  stroke: #f1b6da;
}
.PiYG.q4-10 {
  fill: #fde0ef;
  background: #fde0ef;
  stroke: #fde0ef;
}
.PiYG.q5-10 {
  fill: #e6f5d0;
  background: #e6f5d0;
  stroke: #e6f5d0;
}
.PiYG.q6-10 {
  fill: #b8e186;
  background: #b8e186;
  stroke: #b8e186;
}
.PiYG.q7-10 {
  fill: #7fbc41;
  background: #7fbc41;
  stroke: #7fbc41;
}
.PiYG.q8-10 {
  fill: #4d9221;
  background: #4d9221;
  stroke: #4d9221;
}
.PiYG.q9-10 {
  fill: #276419;
  background: #276419;
  stroke: #276419;
}
.PiYG.q0-11 {
  fill: #8e0152;
  background: #8e0152;
  stroke: #8e0152;
}
.PiYG.q1-11 {
  fill: #c51b7d;
  background: #c51b7d;
  stroke: #c51b7d;
}
.PiYG.q2-11 {
  fill: #de77ae;
  background: #de77ae;
  stroke: #de77ae;
}
.PiYG.q3-11 {
  fill: #f1b6da;
  background: #f1b6da;
  stroke: #f1b6da;
}
.PiYG.q4-11 {
  fill: #fde0ef;
  background: #fde0ef;
  stroke: #fde0ef;
}
.PiYG.q5-11 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.PiYG.q6-11 {
  fill: #e6f5d0;
  background: #e6f5d0;
  stroke: #e6f5d0;
}
.PiYG.q7-11 {
  fill: #b8e186;
  background: #b8e186;
  stroke: #b8e186;
}
.PiYG.q8-11 {
  fill: #7fbc41;
  background: #7fbc41;
  stroke: #7fbc41;
}
.PiYG.q9-11 {
  fill: #4d9221;
  background: #4d9221;
  stroke: #4d9221;
}
.PiYG.q10-11 {
  fill: #276419;
  background: #276419;
  stroke: #276419;
}
.RdBu.q0-3 {
  fill: #ef8a62;
  background: #ef8a62;
  stroke: #ef8a62;
}
.RdBu.q1-3 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.RdBu.q2-3 {
  fill: #67a9cf;
  background: #67a9cf;
  stroke: #67a9cf;
}
.RdBu.q0-4 {
  fill: #ca0020;
  background: #ca0020;
  stroke: #ca0020;
}
.RdBu.q1-4 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdBu.q2-4 {
  fill: #92c5de;
  background: #92c5de;
  stroke: #92c5de;
}
.RdBu.q3-4 {
  fill: #0571b0;
  background: #0571b0;
  stroke: #0571b0;
}
.RdBu.q0-5 {
  fill: #ca0020;
  background: #ca0020;
  stroke: #ca0020;
}
.RdBu.q1-5 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdBu.q2-5 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.RdBu.q3-5 {
  fill: #92c5de;
  background: #92c5de;
  stroke: #92c5de;
}
.RdBu.q4-5 {
  fill: #0571b0;
  background: #0571b0;
  stroke: #0571b0;
}
.RdBu.q0-6 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdBu.q1-6 {
  fill: #ef8a62;
  background: #ef8a62;
  stroke: #ef8a62;
}
.RdBu.q2-6 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdBu.q3-6 {
  fill: #d1e5f0;
  background: #d1e5f0;
  stroke: #d1e5f0;
}
.RdBu.q4-6 {
  fill: #67a9cf;
  background: #67a9cf;
  stroke: #67a9cf;
}
.RdBu.q5-6 {
  fill: #2166ac;
  background: #2166ac;
  stroke: #2166ac;
}
.RdBu.q0-7 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdBu.q1-7 {
  fill: #ef8a62;
  background: #ef8a62;
  stroke: #ef8a62;
}
.RdBu.q2-7 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdBu.q3-7 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.RdBu.q4-7 {
  fill: #d1e5f0;
  background: #d1e5f0;
  stroke: #d1e5f0;
}
.RdBu.q5-7 {
  fill: #67a9cf;
  background: #67a9cf;
  stroke: #67a9cf;
}
.RdBu.q6-7 {
  fill: #2166ac;
  background: #2166ac;
  stroke: #2166ac;
}
.RdBu.q0-8 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdBu.q1-8 {
  fill: #d6604d;
  background: #d6604d;
  stroke: #d6604d;
}
.RdBu.q2-8 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdBu.q3-8 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdBu.q4-8 {
  fill: #d1e5f0;
  background: #d1e5f0;
  stroke: #d1e5f0;
}
.RdBu.q5-8 {
  fill: #92c5de;
  background: #92c5de;
  stroke: #92c5de;
}
.RdBu.q6-8 {
  fill: #4393c3;
  background: #4393c3;
  stroke: #4393c3;
}
.RdBu.q7-8 {
  fill: #2166ac;
  background: #2166ac;
  stroke: #2166ac;
}
.RdBu.q0-9 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdBu.q1-9 {
  fill: #d6604d;
  background: #d6604d;
  stroke: #d6604d;
}
.RdBu.q2-9 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdBu.q3-9 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdBu.q4-9 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.RdBu.q5-9 {
  fill: #d1e5f0;
  background: #d1e5f0;
  stroke: #d1e5f0;
}
.RdBu.q6-9 {
  fill: #92c5de;
  background: #92c5de;
  stroke: #92c5de;
}
.RdBu.q7-9 {
  fill: #4393c3;
  background: #4393c3;
  stroke: #4393c3;
}
.RdBu.q8-9 {
  fill: #2166ac;
  background: #2166ac;
  stroke: #2166ac;
}
.RdBu.q0-10 {
  fill: #67001f;
  background: #67001f;
  stroke: #67001f;
}
.RdBu.q1-10 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdBu.q2-10 {
  fill: #d6604d;
  background: #d6604d;
  stroke: #d6604d;
}
.RdBu.q3-10 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdBu.q4-10 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdBu.q5-10 {
  fill: #d1e5f0;
  background: #d1e5f0;
  stroke: #d1e5f0;
}
.RdBu.q6-10 {
  fill: #92c5de;
  background: #92c5de;
  stroke: #92c5de;
}
.RdBu.q7-10 {
  fill: #4393c3;
  background: #4393c3;
  stroke: #4393c3;
}
.RdBu.q8-10 {
  fill: #2166ac;
  background: #2166ac;
  stroke: #2166ac;
}
.RdBu.q9-10 {
  fill: #053061;
  background: #053061;
  stroke: #053061;
}
.RdBu.q0-11 {
  fill: #67001f;
  background: #67001f;
  stroke: #67001f;
}
.RdBu.q1-11 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdBu.q2-11 {
  fill: #d6604d;
  background: #d6604d;
  stroke: #d6604d;
}
.RdBu.q3-11 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdBu.q4-11 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdBu.q5-11 {
  fill: #f7f7f7;
  background: #f7f7f7;
  stroke: #f7f7f7;
}
.RdBu.q6-11 {
  fill: #d1e5f0;
  background: #d1e5f0;
  stroke: #d1e5f0;
}
.RdBu.q7-11 {
  fill: #92c5de;
  background: #92c5de;
  stroke: #92c5de;
}
.RdBu.q8-11 {
  fill: #4393c3;
  background: #4393c3;
  stroke: #4393c3;
}
.RdBu.q9-11 {
  fill: #2166ac;
  background: #2166ac;
  stroke: #2166ac;
}
.RdBu.q10-11 {
  fill: #053061;
  background: #053061;
  stroke: #053061;
}
.RdGy.q0-3 {
  fill: #ef8a62;
  background: #ef8a62;
  stroke: #ef8a62;
}
.RdGy.q1-3 {
  fill: #ffffff;
  background: #ffffff;
  stroke: #ffffff;
}
.RdGy.q2-3 {
  fill: #999999;
  background: #999999;
  stroke: #999999;
}
.RdGy.q0-4 {
  fill: #ca0020;
  background: #ca0020;
  stroke: #ca0020;
}
.RdGy.q1-4 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdGy.q2-4 {
  fill: #bababa;
  background: #bababa;
  stroke: #bababa;
}
.RdGy.q3-4 {
  fill: #404040;
  background: #404040;
  stroke: #404040;
}
.RdGy.q0-5 {
  fill: #ca0020;
  background: #ca0020;
  stroke: #ca0020;
}
.RdGy.q1-5 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdGy.q2-5 {
  fill: #ffffff;
  background: #ffffff;
  stroke: #ffffff;
}
.RdGy.q3-5 {
  fill: #bababa;
  background: #bababa;
  stroke: #bababa;
}
.RdGy.q4-5 {
  fill: #404040;
  background: #404040;
  stroke: #404040;
}
.RdGy.q0-6 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdGy.q1-6 {
  fill: #ef8a62;
  background: #ef8a62;
  stroke: #ef8a62;
}
.RdGy.q2-6 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdGy.q3-6 {
  fill: #e0e0e0;
  background: #e0e0e0;
  stroke: #e0e0e0;
}
.RdGy.q4-6 {
  fill: #999999;
  background: #999999;
  stroke: #999999;
}
.RdGy.q5-6 {
  fill: #4d4d4d;
  background: #4d4d4d;
  stroke: #4d4d4d;
}
.RdGy.q0-7 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdGy.q1-7 {
  fill: #ef8a62;
  background: #ef8a62;
  stroke: #ef8a62;
}
.RdGy.q2-7 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdGy.q3-7 {
  fill: #ffffff;
  background: #ffffff;
  stroke: #ffffff;
}
.RdGy.q4-7 {
  fill: #e0e0e0;
  background: #e0e0e0;
  stroke: #e0e0e0;
}
.RdGy.q5-7 {
  fill: #999999;
  background: #999999;
  stroke: #999999;
}
.RdGy.q6-7 {
  fill: #4d4d4d;
  background: #4d4d4d;
  stroke: #4d4d4d;
}
.RdGy.q0-8 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdGy.q1-8 {
  fill: #d6604d;
  background: #d6604d;
  stroke: #d6604d;
}
.RdGy.q2-8 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdGy.q3-8 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdGy.q4-8 {
  fill: #e0e0e0;
  background: #e0e0e0;
  stroke: #e0e0e0;
}
.RdGy.q5-8 {
  fill: #bababa;
  background: #bababa;
  stroke: #bababa;
}
.RdGy.q6-8 {
  fill: #878787;
  background: #878787;
  stroke: #878787;
}
.RdGy.q7-8 {
  fill: #4d4d4d;
  background: #4d4d4d;
  stroke: #4d4d4d;
}
.RdGy.q0-9 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdGy.q1-9 {
  fill: #d6604d;
  background: #d6604d;
  stroke: #d6604d;
}
.RdGy.q2-9 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdGy.q3-9 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdGy.q4-9 {
  fill: #ffffff;
  background: #ffffff;
  stroke: #ffffff;
}
.RdGy.q5-9 {
  fill: #e0e0e0;
  background: #e0e0e0;
  stroke: #e0e0e0;
}
.RdGy.q6-9 {
  fill: #bababa;
  background: #bababa;
  stroke: #bababa;
}
.RdGy.q7-9 {
  fill: #878787;
  background: #878787;
  stroke: #878787;
}
.RdGy.q8-9 {
  fill: #4d4d4d;
  background: #4d4d4d;
  stroke: #4d4d4d;
}
.RdGy.q0-10 {
  fill: #67001f;
  background: #67001f;
  stroke: #67001f;
}
.RdGy.q1-10 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdGy.q2-10 {
  fill: #d6604d;
  background: #d6604d;
  stroke: #d6604d;
}
.RdGy.q3-10 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdGy.q4-10 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdGy.q5-10 {
  fill: #e0e0e0;
  background: #e0e0e0;
  stroke: #e0e0e0;
}
.RdGy.q6-10 {
  fill: #bababa;
  background: #bababa;
  stroke: #bababa;
}
.RdGy.q7-10 {
  fill: #878787;
  background: #878787;
  stroke: #878787;
}
.RdGy.q8-10 {
  fill: #4d4d4d;
  background: #4d4d4d;
  stroke: #4d4d4d;
}
.RdGy.q9-10 {
  fill: #1a1a1a;
  background: #1a1a1a;
  stroke: #1a1a1a;
}
.RdGy.q0-11 {
  fill: #67001f;
  background: #67001f;
  stroke: #67001f;
}
.RdGy.q1-11 {
  fill: #b2182b;
  background: #b2182b;
  stroke: #b2182b;
}
.RdGy.q2-11 {
  fill: #d6604d;
  background: #d6604d;
  stroke: #d6604d;
}
.RdGy.q3-11 {
  fill: #f4a582;
  background: #f4a582;
  stroke: #f4a582;
}
.RdGy.q4-11 {
  fill: #fddbc7;
  background: #fddbc7;
  stroke: #fddbc7;
}
.RdGy.q5-11 {
  fill: #ffffff;
  background: #ffffff;
  stroke: #ffffff;
}
.RdGy.q6-11 {
  fill: #e0e0e0;
  background: #e0e0e0;
  stroke: #e0e0e0;
}
.RdGy.q7-11 {
  fill: #bababa;
  background: #bababa;
  stroke: #bababa;
}
.RdGy.q8-11 {
  fill: #878787;
  background: #878787;
  stroke: #878787;
}
.RdGy.q9-11 {
  fill: #4d4d4d;
  background: #4d4d4d;
  stroke: #4d4d4d;
}
.RdGy.q10-11 {
  fill: #1a1a1a;
  background: #1a1a1a;
  stroke: #1a1a1a;
}
.RdYlBu.q0-3 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.RdYlBu.q1-3 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.RdYlBu.q2-3 {
  fill: #91bfdb;
  background: #91bfdb;
  stroke: #91bfdb;
}
.RdYlBu.q0-4 {
  fill: #d7191c;
  background: #d7191c;
  stroke: #d7191c;
}
.RdYlBu.q1-4 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlBu.q2-4 {
  fill: #abd9e9;
  background: #abd9e9;
  stroke: #abd9e9;
}
.RdYlBu.q3-4 {
  fill: #2c7bb6;
  background: #2c7bb6;
  stroke: #2c7bb6;
}
.RdYlBu.q0-5 {
  fill: #d7191c;
  background: #d7191c;
  stroke: #d7191c;
}
.RdYlBu.q1-5 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlBu.q2-5 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.RdYlBu.q3-5 {
  fill: #abd9e9;
  background: #abd9e9;
  stroke: #abd9e9;
}
.RdYlBu.q4-5 {
  fill: #2c7bb6;
  background: #2c7bb6;
  stroke: #2c7bb6;
}
.RdYlBu.q0-6 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlBu.q1-6 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.RdYlBu.q2-6 {
  fill: #fee090;
  background: #fee090;
  stroke: #fee090;
}
.RdYlBu.q3-6 {
  fill: #e0f3f8;
  background: #e0f3f8;
  stroke: #e0f3f8;
}
.RdYlBu.q4-6 {
  fill: #91bfdb;
  background: #91bfdb;
  stroke: #91bfdb;
}
.RdYlBu.q5-6 {
  fill: #4575b4;
  background: #4575b4;
  stroke: #4575b4;
}
.RdYlBu.q0-7 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlBu.q1-7 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.RdYlBu.q2-7 {
  fill: #fee090;
  background: #fee090;
  stroke: #fee090;
}
.RdYlBu.q3-7 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.RdYlBu.q4-7 {
  fill: #e0f3f8;
  background: #e0f3f8;
  stroke: #e0f3f8;
}
.RdYlBu.q5-7 {
  fill: #91bfdb;
  background: #91bfdb;
  stroke: #91bfdb;
}
.RdYlBu.q6-7 {
  fill: #4575b4;
  background: #4575b4;
  stroke: #4575b4;
}
.RdYlBu.q0-8 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlBu.q1-8 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.RdYlBu.q2-8 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlBu.q3-8 {
  fill: #fee090;
  background: #fee090;
  stroke: #fee090;
}
.RdYlBu.q4-8 {
  fill: #e0f3f8;
  background: #e0f3f8;
  stroke: #e0f3f8;
}
.RdYlBu.q5-8 {
  fill: #abd9e9;
  background: #abd9e9;
  stroke: #abd9e9;
}
.RdYlBu.q6-8 {
  fill: #74add1;
  background: #74add1;
  stroke: #74add1;
}
.RdYlBu.q7-8 {
  fill: #4575b4;
  background: #4575b4;
  stroke: #4575b4;
}
.RdYlBu.q0-9 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlBu.q1-9 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.RdYlBu.q2-9 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlBu.q3-9 {
  fill: #fee090;
  background: #fee090;
  stroke: #fee090;
}
.RdYlBu.q4-9 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.RdYlBu.q5-9 {
  fill: #e0f3f8;
  background: #e0f3f8;
  stroke: #e0f3f8;
}
.RdYlBu.q6-9 {
  fill: #abd9e9;
  background: #abd9e9;
  stroke: #abd9e9;
}
.RdYlBu.q7-9 {
  fill: #74add1;
  background: #74add1;
  stroke: #74add1;
}
.RdYlBu.q8-9 {
  fill: #4575b4;
  background: #4575b4;
  stroke: #4575b4;
}
.RdYlBu.q0-10 {
  fill: #a50026;
  background: #a50026;
  stroke: #a50026;
}
.RdYlBu.q1-10 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlBu.q2-10 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.RdYlBu.q3-10 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlBu.q4-10 {
  fill: #fee090;
  background: #fee090;
  stroke: #fee090;
}
.RdYlBu.q5-10 {
  fill: #e0f3f8;
  background: #e0f3f8;
  stroke: #e0f3f8;
}
.RdYlBu.q6-10 {
  fill: #abd9e9;
  background: #abd9e9;
  stroke: #abd9e9;
}
.RdYlBu.q7-10 {
  fill: #74add1;
  background: #74add1;
  stroke: #74add1;
}
.RdYlBu.q8-10 {
  fill: #4575b4;
  background: #4575b4;
  stroke: #4575b4;
}
.RdYlBu.q9-10 {
  fill: #313695;
  background: #313695;
  stroke: #313695;
}
.RdYlBu.q0-11 {
  fill: #a50026;
  background: #a50026;
  stroke: #a50026;
}
.RdYlBu.q1-11 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlBu.q2-11 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.RdYlBu.q3-11 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlBu.q4-11 {
  fill: #fee090;
  background: #fee090;
  stroke: #fee090;
}
.RdYlBu.q5-11 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.RdYlBu.q6-11 {
  fill: #e0f3f8;
  background: #e0f3f8;
  stroke: #e0f3f8;
}
.RdYlBu.q7-11 {
  fill: #abd9e9;
  background: #abd9e9;
  stroke: #abd9e9;
}
.RdYlBu.q8-11 {
  fill: #74add1;
  background: #74add1;
  stroke: #74add1;
}
.RdYlBu.q9-11 {
  fill: #4575b4;
  background: #4575b4;
  stroke: #4575b4;
}
.RdYlBu.q10-11 {
  fill: #313695;
  background: #313695;
  stroke: #313695;
}
.Spectral.q0-3 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.Spectral.q1-3 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.Spectral.q2-3 {
  fill: #99d594;
  background: #99d594;
  stroke: #99d594;
}
.Spectral.q0-4 {
  fill: #d7191c;
  background: #d7191c;
  stroke: #d7191c;
}
.Spectral.q1-4 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.Spectral.q2-4 {
  fill: #abdda4;
  background: #abdda4;
  stroke: #abdda4;
}
.Spectral.q3-4 {
  fill: #2b83ba;
  background: #2b83ba;
  stroke: #2b83ba;
}
.Spectral.q0-5 {
  fill: #d7191c;
  background: #d7191c;
  stroke: #d7191c;
}
.Spectral.q1-5 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.Spectral.q2-5 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.Spectral.q3-5 {
  fill: #abdda4;
  background: #abdda4;
  stroke: #abdda4;
}
.Spectral.q4-5 {
  fill: #2b83ba;
  background: #2b83ba;
  stroke: #2b83ba;
}
.Spectral.q0-6 {
  fill: #d53e4f;
  background: #d53e4f;
  stroke: #d53e4f;
}
.Spectral.q1-6 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.Spectral.q2-6 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.Spectral.q3-6 {
  fill: #e6f598;
  background: #e6f598;
  stroke: #e6f598;
}
.Spectral.q4-6 {
  fill: #99d594;
  background: #99d594;
  stroke: #99d594;
}
.Spectral.q5-6 {
  fill: #3288bd;
  background: #3288bd;
  stroke: #3288bd;
}
.Spectral.q0-7 {
  fill: #d53e4f;
  background: #d53e4f;
  stroke: #d53e4f;
}
.Spectral.q1-7 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.Spectral.q2-7 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.Spectral.q3-7 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.Spectral.q4-7 {
  fill: #e6f598;
  background: #e6f598;
  stroke: #e6f598;
}
.Spectral.q5-7 {
  fill: #99d594;
  background: #99d594;
  stroke: #99d594;
}
.Spectral.q6-7 {
  fill: #3288bd;
  background: #3288bd;
  stroke: #3288bd;
}
.Spectral.q0-8 {
  fill: #d53e4f;
  background: #d53e4f;
  stroke: #d53e4f;
}
.Spectral.q1-8 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.Spectral.q2-8 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.Spectral.q3-8 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.Spectral.q4-8 {
  fill: #e6f598;
  background: #e6f598;
  stroke: #e6f598;
}
.Spectral.q5-8 {
  fill: #abdda4;
  background: #abdda4;
  stroke: #abdda4;
}
.Spectral.q6-8 {
  fill: #66c2a5;
  background: #66c2a5;
  stroke: #66c2a5;
}
.Spectral.q7-8 {
  fill: #3288bd;
  background: #3288bd;
  stroke: #3288bd;
}
.Spectral.q0-9 {
  fill: #d53e4f;
  background: #d53e4f;
  stroke: #d53e4f;
}
.Spectral.q1-9 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.Spectral.q2-9 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.Spectral.q3-9 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.Spectral.q4-9 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.Spectral.q5-9 {
  fill: #e6f598;
  background: #e6f598;
  stroke: #e6f598;
}
.Spectral.q6-9 {
  fill: #abdda4;
  background: #abdda4;
  stroke: #abdda4;
}
.Spectral.q7-9 {
  fill: #66c2a5;
  background: #66c2a5;
  stroke: #66c2a5;
}
.Spectral.q8-9 {
  fill: #3288bd;
  background: #3288bd;
  stroke: #3288bd;
}
.Spectral.q0-10 {
  fill: #9e0142;
  background: #9e0142;
  stroke: #9e0142;
}
.Spectral.q1-10 {
  fill: #d53e4f;
  background: #d53e4f;
  stroke: #d53e4f;
}
.Spectral.q2-10 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.Spectral.q3-10 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.Spectral.q4-10 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.Spectral.q5-10 {
  fill: #e6f598;
  background: #e6f598;
  stroke: #e6f598;
}
.Spectral.q6-10 {
  fill: #abdda4;
  background: #abdda4;
  stroke: #abdda4;
}
.Spectral.q7-10 {
  fill: #66c2a5;
  background: #66c2a5;
  stroke: #66c2a5;
}
.Spectral.q8-10 {
  fill: #3288bd;
  background: #3288bd;
  stroke: #3288bd;
}
.Spectral.q9-10 {
  fill: #5e4fa2;
  background: #5e4fa2;
  stroke: #5e4fa2;
}
.Spectral.q0-11 {
  fill: #9e0142;
  background: #9e0142;
  stroke: #9e0142;
}
.Spectral.q1-11 {
  fill: #d53e4f;
  background: #d53e4f;
  stroke: #d53e4f;
}
.Spectral.q2-11 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.Spectral.q3-11 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.Spectral.q4-11 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.Spectral.q5-11 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.Spectral.q6-11 {
  fill: #e6f598;
  background: #e6f598;
  stroke: #e6f598;
}
.Spectral.q7-11 {
  fill: #abdda4;
  background: #abdda4;
  stroke: #abdda4;
}
.Spectral.q8-11 {
  fill: #66c2a5;
  background: #66c2a5;
  stroke: #66c2a5;
}
.Spectral.q9-11 {
  fill: #3288bd;
  background: #3288bd;
  stroke: #3288bd;
}
.Spectral.q10-11 {
  fill: #5e4fa2;
  background: #5e4fa2;
  stroke: #5e4fa2;
}
.RdYlGn.q0-3 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.RdYlGn.q1-3 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.RdYlGn.q2-3 {
  fill: #91cf60;
  background: #91cf60;
  stroke: #91cf60;
}
.RdYlGn.q0-4 {
  fill: #d7191c;
  background: #d7191c;
  stroke: #d7191c;
}
.RdYlGn.q1-4 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlGn.q2-4 {
  fill: #a6d96a;
  background: #a6d96a;
  stroke: #a6d96a;
}
.RdYlGn.q3-4 {
  fill: #1a9641;
  background: #1a9641;
  stroke: #1a9641;
}
.RdYlGn.q0-5 {
  fill: #d7191c;
  background: #d7191c;
  stroke: #d7191c;
}
.RdYlGn.q1-5 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlGn.q2-5 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.RdYlGn.q3-5 {
  fill: #a6d96a;
  background: #a6d96a;
  stroke: #a6d96a;
}
.RdYlGn.q4-5 {
  fill: #1a9641;
  background: #1a9641;
  stroke: #1a9641;
}
.RdYlGn.q0-6 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlGn.q1-6 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.RdYlGn.q2-6 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.RdYlGn.q3-6 {
  fill: #d9ef8b;
  background: #d9ef8b;
  stroke: #d9ef8b;
}
.RdYlGn.q4-6 {
  fill: #91cf60;
  background: #91cf60;
  stroke: #91cf60;
}
.RdYlGn.q5-6 {
  fill: #1a9850;
  background: #1a9850;
  stroke: #1a9850;
}
.RdYlGn.q0-7 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlGn.q1-7 {
  fill: #fc8d59;
  background: #fc8d59;
  stroke: #fc8d59;
}
.RdYlGn.q2-7 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.RdYlGn.q3-7 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.RdYlGn.q4-7 {
  fill: #d9ef8b;
  background: #d9ef8b;
  stroke: #d9ef8b;
}
.RdYlGn.q5-7 {
  fill: #91cf60;
  background: #91cf60;
  stroke: #91cf60;
}
.RdYlGn.q6-7 {
  fill: #1a9850;
  background: #1a9850;
  stroke: #1a9850;
}
.RdYlGn.q0-8 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlGn.q1-8 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.RdYlGn.q2-8 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlGn.q3-8 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.RdYlGn.q4-8 {
  fill: #d9ef8b;
  background: #d9ef8b;
  stroke: #d9ef8b;
}
.RdYlGn.q5-8 {
  fill: #a6d96a;
  background: #a6d96a;
  stroke: #a6d96a;
}
.RdYlGn.q6-8 {
  fill: #66bd63;
  background: #66bd63;
  stroke: #66bd63;
}
.RdYlGn.q7-8 {
  fill: #1a9850;
  background: #1a9850;
  stroke: #1a9850;
}
.RdYlGn.q0-9 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlGn.q1-9 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.RdYlGn.q2-9 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlGn.q3-9 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.RdYlGn.q4-9 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.RdYlGn.q5-9 {
  fill: #d9ef8b;
  background: #d9ef8b;
  stroke: #d9ef8b;
}
.RdYlGn.q6-9 {
  fill: #a6d96a;
  background: #a6d96a;
  stroke: #a6d96a;
}
.RdYlGn.q7-9 {
  fill: #66bd63;
  background: #66bd63;
  stroke: #66bd63;
}
.RdYlGn.q8-9 {
  fill: #1a9850;
  background: #1a9850;
  stroke: #1a9850;
}
.RdYlGn.q0-10 {
  fill: #a50026;
  background: #a50026;
  stroke: #a50026;
}
.RdYlGn.q1-10 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlGn.q2-10 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.RdYlGn.q3-10 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlGn.q4-10 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.RdYlGn.q5-10 {
  fill: #d9ef8b;
  background: #d9ef8b;
  stroke: #d9ef8b;
}
.RdYlGn.q6-10 {
  fill: #a6d96a;
  background: #a6d96a;
  stroke: #a6d96a;
}
.RdYlGn.q7-10 {
  fill: #66bd63;
  background: #66bd63;
  stroke: #66bd63;
}
.RdYlGn.q8-10 {
  fill: #1a9850;
  background: #1a9850;
  stroke: #1a9850;
}
.RdYlGn.q9-10 {
  fill: #006837;
  background: #006837;
  stroke: #006837;
}
.RdYlGn.q0-11 {
  fill: #a50026;
  background: #a50026;
  stroke: #a50026;
}
.RdYlGn.q1-11 {
  fill: #d73027;
  background: #d73027;
  stroke: #d73027;
}
.RdYlGn.q2-11 {
  fill: #f46d43;
  background: #f46d43;
  stroke: #f46d43;
}
.RdYlGn.q3-11 {
  fill: #fdae61;
  background: #fdae61;
  stroke: #fdae61;
}
.RdYlGn.q4-11 {
  fill: #fee08b;
  background: #fee08b;
  stroke: #fee08b;
}
.RdYlGn.q5-11 {
  fill: #ffffbf;
  background: #ffffbf;
  stroke: #ffffbf;
}
.RdYlGn.q6-11 {
  fill: #d9ef8b;
  background: #d9ef8b;
  stroke: #d9ef8b;
}
.RdYlGn.q7-11 {
  fill: #a6d96a;
  background: #a6d96a;
  stroke: #a6d96a;
}
.RdYlGn.q8-11 {
  fill: #66bd63;
  background: #66bd63;
  stroke: #66bd63;
}
.RdYlGn.q9-11 {
  fill: #1a9850;
  background: #1a9850;
  stroke: #1a9850;
}
.RdYlGn.q10-11 {
  fill: #006837;
  background: #006837;
  stroke: #006837;
}
.Accent.q0-3 {
  fill: #7fc97f;
  background: #7fc97f;
  stroke: #7fc97f;
}
.Accent.q1-3 {
  fill: #beaed4;
  background: #beaed4;
  stroke: #beaed4;
}
.Accent.q2-3 {
  fill: #fdc086;
  background: #fdc086;
  stroke: #fdc086;
}
.Accent.q0-4 {
  fill: #7fc97f;
  background: #7fc97f;
  stroke: #7fc97f;
}
.Accent.q1-4 {
  fill: #beaed4;
  background: #beaed4;
  stroke: #beaed4;
}
.Accent.q2-4 {
  fill: #fdc086;
  background: #fdc086;
  stroke: #fdc086;
}
.Accent.q3-4 {
  fill: #ffff99;
  background: #ffff99;
  stroke: #ffff99;
}
.Accent.q0-5 {
  fill: #7fc97f;
  background: #7fc97f;
  stroke: #7fc97f;
}
.Accent.q1-5 {
  fill: #beaed4;
  background: #beaed4;
  stroke: #beaed4;
}
.Accent.q2-5 {
  fill: #fdc086;
  background: #fdc086;
  stroke: #fdc086;
}
.Accent.q3-5 {
  fill: #ffff99;
  background: #ffff99;
  stroke: #ffff99;
}
.Accent.q4-5 {
  fill: #386cb0;
  background: #386cb0;
  stroke: #386cb0;
}
.Accent.q0-6 {
  fill: #7fc97f;
  background: #7fc97f;
  stroke: #7fc97f;
}
.Accent.q1-6 {
  fill: #beaed4;
  background: #beaed4;
  stroke: #beaed4;
}
.Accent.q2-6 {
  fill: #fdc086;
  background: #fdc086;
  stroke: #fdc086;
}
.Accent.q3-6 {
  fill: #ffff99;
  background: #ffff99;
  stroke: #ffff99;
}
.Accent.q4-6 {
  fill: #386cb0;
  background: #386cb0;
  stroke: #386cb0;
}
.Accent.q5-6 {
  fill: #f0027f;
  background: #f0027f;
  stroke: #f0027f;
}
.Accent.q0-7 {
  fill: #7fc97f;
  background: #7fc97f;
  stroke: #7fc97f;
}
.Accent.q1-7 {
  fill: #beaed4;
  background: #beaed4;
  stroke: #beaed4;
}
.Accent.q2-7 {
  fill: #fdc086;
  background: #fdc086;
  stroke: #fdc086;
}
.Accent.q3-7 {
  fill: #ffff99;
  background: #ffff99;
  stroke: #ffff99;
}
.Accent.q4-7 {
  fill: #386cb0;
  background: #386cb0;
  stroke: #386cb0;
}
.Accent.q5-7 {
  fill: #f0027f;
  background: #f0027f;
  stroke: #f0027f;
}
.Accent.q6-7 {
  fill: #bf5b17;
  background: #bf5b17;
  stroke: #bf5b17;
}
.Accent.q0-8 {
  fill: #7fc97f;
  background: #7fc97f;
  stroke: #7fc97f;
}
.Accent.q1-8 {
  fill: #beaed4;
  background: #beaed4;
  stroke: #beaed4;
}
.Accent.q2-8 {
  fill: #fdc086;
  background: #fdc086;
  stroke: #fdc086;
}
.Accent.q3-8 {
  fill: #ffff99;
  background: #ffff99;
  stroke: #ffff99;
}
.Accent.q4-8 {
  fill: #386cb0;
  background: #386cb0;
  stroke: #386cb0;
}
.Accent.q5-8 {
  fill: #f0027f;
  background: #f0027f;
  stroke: #f0027f;
}
.Accent.q6-8 {
  fill: #bf5b17;
  background: #bf5b17;
  stroke: #bf5b17;
}
.Accent.q7-8 {
  fill: #666666;
  background: #666666;
  stroke: #666666;
}
.Dark2.q0-3 {
  fill: #1b9e77;
  background: #1b9e77;
  stroke: #1b9e77;
}
.Dark2.q1-3 {
  fill: #d95f02;
  background: #d95f02;
  stroke: #d95f02;
}
.Dark2.q2-3 {
  fill: #7570b3;
  background: #7570b3;
  stroke: #7570b3;
}
.Dark2.q0-4 {
  fill: #1b9e77;
  background: #1b9e77;
  stroke: #1b9e77;
}
.Dark2.q1-4 {
  fill: #d95f02;
  background: #d95f02;
  stroke: #d95f02;
}
.Dark2.q2-4 {
  fill: #7570b3;
  background: #7570b3;
  stroke: #7570b3;
}
.Dark2.q3-4 {
  fill: #e7298a;
  background: #e7298a;
  stroke: #e7298a;
}
.Dark2.q0-5 {
  fill: #1b9e77;
  background: #1b9e77;
  stroke: #1b9e77;
}
.Dark2.q1-5 {
  fill: #d95f02;
  background: #d95f02;
  stroke: #d95f02;
}
.Dark2.q2-5 {
  fill: #7570b3;
  background: #7570b3;
  stroke: #7570b3;
}
.Dark2.q3-5 {
  fill: #e7298a;
  background: #e7298a;
  stroke: #e7298a;
}
.Dark2.q4-5 {
  fill: #66a61e;
  background: #66a61e;
  stroke: #66a61e;
}
.Dark2.q0-6 {
  fill: #1b9e77;
  background: #1b9e77;
  stroke: #1b9e77;
}
.Dark2.q1-6 {
  fill: #d95f02;
  background: #d95f02;
  stroke: #d95f02;
}
.Dark2.q2-6 {
  fill: #7570b3;
  background: #7570b3;
  stroke: #7570b3;
}
.Dark2.q3-6 {
  fill: #e7298a;
  background: #e7298a;
  stroke: #e7298a;
}
.Dark2.q4-6 {
  fill: #66a61e;
  background: #66a61e;
  stroke: #66a61e;
}
.Dark2.q5-6 {
  fill: #e6ab02;
  background: #e6ab02;
  stroke: #e6ab02;
}
.Dark2.q0-7 {
  fill: #1b9e77;
  background: #1b9e77;
  stroke: #1b9e77;
}
.Dark2.q1-7 {
  fill: #d95f02;
  background: #d95f02;
  stroke: #d95f02;
}
.Dark2.q2-7 {
  fill: #7570b3;
  background: #7570b3;
  stroke: #7570b3;
}
.Dark2.q3-7 {
  fill: #e7298a;
  background: #e7298a;
  stroke: #e7298a;
}
.Dark2.q4-7 {
  fill: #66a61e;
  background: #66a61e;
  stroke: #66a61e;
}
.Dark2.q5-7 {
  fill: #e6ab02;
  background: #e6ab02;
  stroke: #e6ab02;
}
.Dark2.q6-7 {
  fill: #a6761d;
  background: #a6761d;
  stroke: #a6761d;
}
.Dark2.q0-8 {
  fill: #1b9e77;
  background: #1b9e77;
  stroke: #1b9e77;
}
.Dark2.q1-8 {
  fill: #d95f02;
  background: #d95f02;
  stroke: #d95f02;
}
.Dark2.q2-8 {
  fill: #7570b3;
  background: #7570b3;
  stroke: #7570b3;
}
.Dark2.q3-8 {
  fill: #e7298a;
  background: #e7298a;
  stroke: #e7298a;
}
.Dark2.q4-8 {
  fill: #66a61e;
  background: #66a61e;
  stroke: #66a61e;
}
.Dark2.q5-8 {
  fill: #e6ab02;
  background: #e6ab02;
  stroke: #e6ab02;
}
.Dark2.q6-8 {
  fill: #a6761d;
  background: #a6761d;
  stroke: #a6761d;
}
.Dark2.q7-8 {
  fill: #666666;
  background: #666666;
  stroke: #666666;
}
.Paired.q0-3 {
  fill: #a6cee3;
  background: #a6cee3;
  stroke: #a6cee3;
}
.Paired.q1-3 {
  fill: #1f78b4;
  background: #1f78b4;
  stroke: #1f78b4;
}
.Paired.q2-3 {
  fill: #b2df8a;
  background: #b2df8a;
  stroke: #b2df8a;
}
.Paired.q0-4 {
  fill: #a6cee3;
  background: #a6cee3;
  stroke: #a6cee3;
}
.Paired.q1-4 {
  fill: #1f78b4;
  background: #1f78b4;
  stroke: #1f78b4;
}
.Paired.q2-4 {
  fill: #b2df8a;
  background: #b2df8a;
  stroke: #b2df8a;
}
.Paired.q3-4 {
  fill: #33a02c;
  background: #33a02c;
  stroke: #33a02c;
}
.Paired.q0-5 {
  fill: #a6cee3;
  background: #a6cee3;
  stroke: #a6cee3;
}
.Paired.q1-5 {
  fill: #1f78b4;
  background: #1f78b4;
  stroke: #1f78b4;
}
.Paired.q2-5 {
  fill: #b2df8a;
  background: #b2df8a;
  stroke: #b2df8a;
}
.Paired.q3-5 {
  fill: #33a02c;
  background: #33a02c;
  stroke: #33a02c;
}
.Paired.q4-5 {
  fill: #fb9a99;
  background: #fb9a99;
  stroke: #fb9a99;
}
.Paired.q0-6 {
  fill: #a6cee3;
  background: #a6cee3;
  stroke: #a6cee3;
}
.Paired.q1-6 {
  fill: #1f78b4;
  background: #1f78b4;
  stroke: #1f78b4;
}
.Paired.q2-6 {
  fill: #b2df8a;
  background: #b2df8a;
  stroke: #b2df8a;
}
.Paired.q3-6 {
  fill: #33a02c;
  background: #33a02c;
  stroke: #33a02c;
}
.Paired.q4-6 {
  fill: #fb9a99;
  background: #fb9a99;
  stroke: #fb9a99;
}
.Paired.q5-6 {
  fill: #e31a1c;
  background: #e31a1c;
  stroke: #e31a1c;
}
.Paired.q0-7 {
  fill: #a6cee3;
  background: #a6cee3;
  stroke: #a6cee3;
}
.Paired.q1-7 {
  fill: #1f78b4;
  background: #1f78b4;
  stroke: #1f78b4;
}
.Paired.q2-7 {
  fill: #b2df8a;
  background: #b2df8a;
  stroke: #b2df8a;
}
.Paired.q3-7 {
  fill: #33a02c;
  background: #33a02c;
  stroke: #33a02c;
}
.Paired.q4-7 {
  fill: #fb9a99;
  background: #fb9a99;
  stroke: #fb9a99;
}
.Paired.q5-7 {
  fill: #e31a1c;
  background: #e31a1c;
  stroke: #e31a1c;
}
.Paired.q6-7 {
  fill: #fdbf6f;
  background: #fdbf6f;
  stroke: #fdbf6f;
}
.Paired.q0-8 {
  fill: #a6cee3;
  background: #a6cee3;
  stroke: #a6cee3;
}
.Paired.q1-8 {
  fill: #1f78b4;
  background: #1f78b4;
  stroke: #1f78b4;
}
.Paired.q2-8 {
  fill: #b2df8a;
  background: #b2df8a;
  stroke: #b2df8a;
}
.Paired.q3-8 {
  fill: #33a02c;
  background: #33a02c;
  stroke: #33a02c;
}
.Paired.q4-8 {
  fill: #fb9a99;
  background: #fb9a99;
  stroke: #fb9a99;
}
.Paired.q5-8 {
  fill: #e31a1c;
  background: #e31a1c;
  stroke: #e31a1c;
}
.Paired.q6-8 {
  fill: #fdbf6f;
  background: #fdbf6f;
  stroke: #fdbf6f;
}
.Paired.q7-8 {
  fill: #ff7f00;
  background: #ff7f00;
  stroke: #ff7f00;
}
.Paired.q0-9 {
  fill: #a6cee3;
  background: #a6cee3;
  stroke: #a6cee3;
}
.Paired.q1-9 {
  fill: #1f78b4;
  background: #1f78b4;
  stroke: #1f78b4;
}
.Paired.q2-9 {
  fill: #b2df8a;
  background: #b2df8a;
  stroke: #b2df8a;
}
.Paired.q3-9 {
  fill: #33a02c;
  background: #33a02c;
  stroke: #33a02c;
}
.Paired.q4-9 {
  fill: #fb9a99;
  background: #fb9a99;
  stroke: #fb9a99;
}
.Paired.q5-9 {
  fill: #e31a1c;
  background: #e31a1c;
  stroke: #e31a1c;
}
.Paired.q6-9 {
  fill: #fdbf6f;
  background: #fdbf6f;
  stroke: #fdbf6f;
}
.Paired.q7-9 {
  fill: #ff7f00;
  background: #ff7f00;
  stroke: #ff7f00;
}
.Paired.q8-9 {
  fill: #cab2d6;
  background: #cab2d6;
  stroke: #cab2d6;
}
.Paired.q0-10 {
  fill: #a6cee3;
  background: #a6cee3;
  stroke: #a6cee3;
}
.Paired.q1-10 {
  fill: #1f78b4;
  background: #1f78b4;
  stroke: #1f78b4;
}
.Paired.q2-10 {
  fill: #b2df8a;
  background: #b2df8a;
  stroke: #b2df8a;
}
.Paired.q3-10 {
  fill: #33a02c;
  background: #33a02c;
  stroke: #33a02c;
}
.Paired.q4-10 {
  fill: #fb9a99;
  background: #fb9a99;
  stroke: #fb9a99;
}
.Paired.q5-10 {
  fill: #e31a1c;
  background: #e31a1c;
  stroke: #e31a1c;
}
.Paired.q6-10 {
  fill: #fdbf6f;
  background: #fdbf6f;
  stroke: #fdbf6f;
}
.Paired.q7-10 {
  fill: #ff7f00;
  background: #ff7f00;
  stroke: #ff7f00;
}
.Paired.q8-10 {
  fill: #cab2d6;
  background: #cab2d6;
  stroke: #cab2d6;
}
.Paired.q9-10 {
  fill: #6a3d9a;
  background: #6a3d9a;
  stroke: #6a3d9a;
}
.Paired.q0-11 {
  fill: #a6cee3;
  background: #a6cee3;
  stroke: #a6cee3;
}
.Paired.q1-11 {
  fill: #1f78b4;
  background: #1f78b4;
  stroke: #1f78b4;
}
.Paired.q2-11 {
  fill: #b2df8a;
  background: #b2df8a;
  stroke: #b2df8a;
}
.Paired.q3-11 {
  fill: #33a02c;
  background: #33a02c;
  stroke: #33a02c;
}
.Paired.q4-11 {
  fill: #fb9a99;
  background: #fb9a99;
  stroke: #fb9a99;
}
.Paired.q5-11 {
  fill: #e31a1c;
  background: #e31a1c;
  stroke: #e31a1c;
}
.Paired.q6-11 {
  fill: #fdbf6f;
  background: #fdbf6f;
  stroke: #fdbf6f;
}
.Paired.q7-11 {
  fill: #ff7f00;
  background: #ff7f00;
  stroke: #ff7f00;
}
.Paired.q8-11 {
  fill: #cab2d6;
  background: #cab2d6;
  stroke: #cab2d6;
}
.Paired.q9-11 {
  fill: #6a3d9a;
  background: #6a3d9a;
  stroke: #6a3d9a;
}
.Paired.q10-11 {
  fill: #ffff99;
  background: #ffff99;
  stroke: #ffff99;
}
.Paired.q0-12 {
  fill: #a6cee3;
  background: #a6cee3;
  stroke: #a6cee3;
}
.Paired.q1-12 {
  fill: #1f78b4;
  background: #1f78b4;
  stroke: #1f78b4;
}
.Paired.q2-12 {
  fill: #b2df8a;
  background: #b2df8a;
  stroke: #b2df8a;
}
.Paired.q3-12 {
  fill: #33a02c;
  background: #33a02c;
  stroke: #33a02c;
}
.Paired.q4-12 {
  fill: #fb9a99;
  background: #fb9a99;
  stroke: #fb9a99;
}
.Paired.q5-12 {
  fill: #e31a1c;
  background: #e31a1c;
  stroke: #e31a1c;
}
.Paired.q6-12 {
  fill: #fdbf6f;
  background: #fdbf6f;
  stroke: #fdbf6f;
}
.Paired.q7-12 {
  fill: #ff7f00;
  background: #ff7f00;
  stroke: #ff7f00;
}
.Paired.q8-12 {
  fill: #cab2d6;
  background: #cab2d6;
  stroke: #cab2d6;
}
.Paired.q9-12 {
  fill: #6a3d9a;
  background: #6a3d9a;
  stroke: #6a3d9a;
}
.Paired.q10-12 {
  fill: #ffff99;
  background: #ffff99;
  stroke: #ffff99;
}
.Paired.q11-12 {
  fill: #b15928;
  background: #b15928;
  stroke: #b15928;
}
.Pastel1.q0-3 {
  fill: #fbb4ae;
  background: #fbb4ae;
  stroke: #fbb4ae;
}
.Pastel1.q1-3 {
  fill: #b3cde3;
  background: #b3cde3;
  stroke: #b3cde3;
}
.Pastel1.q2-3 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.Pastel1.q0-4 {
  fill: #fbb4ae;
  background: #fbb4ae;
  stroke: #fbb4ae;
}
.Pastel1.q1-4 {
  fill: #b3cde3;
  background: #b3cde3;
  stroke: #b3cde3;
}
.Pastel1.q2-4 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.Pastel1.q3-4 {
  fill: #decbe4;
  background: #decbe4;
  stroke: #decbe4;
}
.Pastel1.q0-5 {
  fill: #fbb4ae;
  background: #fbb4ae;
  stroke: #fbb4ae;
}
.Pastel1.q1-5 {
  fill: #b3cde3;
  background: #b3cde3;
  stroke: #b3cde3;
}
.Pastel1.q2-5 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.Pastel1.q3-5 {
  fill: #decbe4;
  background: #decbe4;
  stroke: #decbe4;
}
.Pastel1.q4-5 {
  fill: #fed9a6;
  background: #fed9a6;
  stroke: #fed9a6;
}
.Pastel1.q0-6 {
  fill: #fbb4ae;
  background: #fbb4ae;
  stroke: #fbb4ae;
}
.Pastel1.q1-6 {
  fill: #b3cde3;
  background: #b3cde3;
  stroke: #b3cde3;
}
.Pastel1.q2-6 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.Pastel1.q3-6 {
  fill: #decbe4;
  background: #decbe4;
  stroke: #decbe4;
}
.Pastel1.q4-6 {
  fill: #fed9a6;
  background: #fed9a6;
  stroke: #fed9a6;
}
.Pastel1.q5-6 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.Pastel1.q0-7 {
  fill: #fbb4ae;
  background: #fbb4ae;
  stroke: #fbb4ae;
}
.Pastel1.q1-7 {
  fill: #b3cde3;
  background: #b3cde3;
  stroke: #b3cde3;
}
.Pastel1.q2-7 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.Pastel1.q3-7 {
  fill: #decbe4;
  background: #decbe4;
  stroke: #decbe4;
}
.Pastel1.q4-7 {
  fill: #fed9a6;
  background: #fed9a6;
  stroke: #fed9a6;
}
.Pastel1.q5-7 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.Pastel1.q6-7 {
  fill: #e5d8bd;
  background: #e5d8bd;
  stroke: #e5d8bd;
}
.Pastel1.q0-8 {
  fill: #fbb4ae;
  background: #fbb4ae;
  stroke: #fbb4ae;
}
.Pastel1.q1-8 {
  fill: #b3cde3;
  background: #b3cde3;
  stroke: #b3cde3;
}
.Pastel1.q2-8 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.Pastel1.q3-8 {
  fill: #decbe4;
  background: #decbe4;
  stroke: #decbe4;
}
.Pastel1.q4-8 {
  fill: #fed9a6;
  background: #fed9a6;
  stroke: #fed9a6;
}
.Pastel1.q5-8 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.Pastel1.q6-8 {
  fill: #e5d8bd;
  background: #e5d8bd;
  stroke: #e5d8bd;
}
.Pastel1.q7-8 {
  fill: #fddaec;
  background: #fddaec;
  stroke: #fddaec;
}
.Pastel1.q0-9 {
  fill: #fbb4ae;
  background: #fbb4ae;
  stroke: #fbb4ae;
}
.Pastel1.q1-9 {
  fill: #b3cde3;
  background: #b3cde3;
  stroke: #b3cde3;
}
.Pastel1.q2-9 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.Pastel1.q3-9 {
  fill: #decbe4;
  background: #decbe4;
  stroke: #decbe4;
}
.Pastel1.q4-9 {
  fill: #fed9a6;
  background: #fed9a6;
  stroke: #fed9a6;
}
.Pastel1.q5-9 {
  fill: #ffffcc;
  background: #ffffcc;
  stroke: #ffffcc;
}
.Pastel1.q6-9 {
  fill: #e5d8bd;
  background: #e5d8bd;
  stroke: #e5d8bd;
}
.Pastel1.q7-9 {
  fill: #fddaec;
  background: #fddaec;
  stroke: #fddaec;
}
.Pastel1.q8-9 {
  fill: #f2f2f2;
  background: #f2f2f2;
  stroke: #f2f2f2;
}
.Pastel2.q0-3 {
  fill: #b3e2cd;
  background: #b3e2cd;
  stroke: #b3e2cd;
}
.Pastel2.q1-3 {
  fill: #fdcdac;
  background: #fdcdac;
  stroke: #fdcdac;
}
.Pastel2.q2-3 {
  fill: #cbd5e8;
  background: #cbd5e8;
  stroke: #cbd5e8;
}
.Pastel2.q0-4 {
  fill: #b3e2cd;
  background: #b3e2cd;
  stroke: #b3e2cd;
}
.Pastel2.q1-4 {
  fill: #fdcdac;
  background: #fdcdac;
  stroke: #fdcdac;
}
.Pastel2.q2-4 {
  fill: #cbd5e8;
  background: #cbd5e8;
  stroke: #cbd5e8;
}
.Pastel2.q3-4 {
  fill: #f4cae4;
  background: #f4cae4;
  stroke: #f4cae4;
}
.Pastel2.q0-5 {
  fill: #b3e2cd;
  background: #b3e2cd;
  stroke: #b3e2cd;
}
.Pastel2.q1-5 {
  fill: #fdcdac;
  background: #fdcdac;
  stroke: #fdcdac;
}
.Pastel2.q2-5 {
  fill: #cbd5e8;
  background: #cbd5e8;
  stroke: #cbd5e8;
}
.Pastel2.q3-5 {
  fill: #f4cae4;
  background: #f4cae4;
  stroke: #f4cae4;
}
.Pastel2.q4-5 {
  fill: #e6f5c9;
  background: #e6f5c9;
  stroke: #e6f5c9;
}
.Pastel2.q0-6 {
  fill: #b3e2cd;
  background: #b3e2cd;
  stroke: #b3e2cd;
}
.Pastel2.q1-6 {
  fill: #fdcdac;
  background: #fdcdac;
  stroke: #fdcdac;
}
.Pastel2.q2-6 {
  fill: #cbd5e8;
  background: #cbd5e8;
  stroke: #cbd5e8;
}
.Pastel2.q3-6 {
  fill: #f4cae4;
  background: #f4cae4;
  stroke: #f4cae4;
}
.Pastel2.q4-6 {
  fill: #e6f5c9;
  background: #e6f5c9;
  stroke: #e6f5c9;
}
.Pastel2.q5-6 {
  fill: #fff2ae;
  background: #fff2ae;
  stroke: #fff2ae;
}
.Pastel2.q0-7 {
  fill: #b3e2cd;
  background: #b3e2cd;
  stroke: #b3e2cd;
}
.Pastel2.q1-7 {
  fill: #fdcdac;
  background: #fdcdac;
  stroke: #fdcdac;
}
.Pastel2.q2-7 {
  fill: #cbd5e8;
  background: #cbd5e8;
  stroke: #cbd5e8;
}
.Pastel2.q3-7 {
  fill: #f4cae4;
  background: #f4cae4;
  stroke: #f4cae4;
}
.Pastel2.q4-7 {
  fill: #e6f5c9;
  background: #e6f5c9;
  stroke: #e6f5c9;
}
.Pastel2.q5-7 {
  fill: #fff2ae;
  background: #fff2ae;
  stroke: #fff2ae;
}
.Pastel2.q6-7 {
  fill: #f1e2cc;
  background: #f1e2cc;
  stroke: #f1e2cc;
}
.Pastel2.q0-8 {
  fill: #b3e2cd;
  background: #b3e2cd;
  stroke: #b3e2cd;
}
.Pastel2.q1-8 {
  fill: #fdcdac;
  background: #fdcdac;
  stroke: #fdcdac;
}
.Pastel2.q2-8 {
  fill: #cbd5e8;
  background: #cbd5e8;
  stroke: #cbd5e8;
}
.Pastel2.q3-8 {
  fill: #f4cae4;
  background: #f4cae4;
  stroke: #f4cae4;
}
.Pastel2.q4-8 {
  fill: #e6f5c9;
  background: #e6f5c9;
  stroke: #e6f5c9;
}
.Pastel2.q5-8 {
  fill: #fff2ae;
  background: #fff2ae;
  stroke: #fff2ae;
}
.Pastel2.q6-8 {
  fill: #f1e2cc;
  background: #f1e2cc;
  stroke: #f1e2cc;
}
.Pastel2.q7-8 {
  fill: #cccccc;
  background: #cccccc;
  stroke: #cccccc;
}
.Set1.q0-3 {
  fill: #e41a1c;
  background: #e41a1c;
  stroke: #e41a1c;
}
.Set1.q1-3 {
  fill: #377eb8;
  background: #377eb8;
  stroke: #377eb8;
}
.Set1.q2-3 {
  fill: #4daf4a;
  background: #4daf4a;
  stroke: #4daf4a;
}
.Set1.q0-4 {
  fill: #e41a1c;
  background: #e41a1c;
  stroke: #e41a1c;
}
.Set1.q1-4 {
  fill: #377eb8;
  background: #377eb8;
  stroke: #377eb8;
}
.Set1.q2-4 {
  fill: #4daf4a;
  background: #4daf4a;
  stroke: #4daf4a;
}
.Set1.q3-4 {
  fill: #984ea3;
  background: #984ea3;
  stroke: #984ea3;
}
.Set1.q0-5 {
  fill: #e41a1c;
  background: #e41a1c;
  stroke: #e41a1c;
}
.Set1.q1-5 {
  fill: #377eb8;
  background: #377eb8;
  stroke: #377eb8;
}
.Set1.q2-5 {
  fill: #4daf4a;
  background: #4daf4a;
  stroke: #4daf4a;
}
.Set1.q3-5 {
  fill: #984ea3;
  background: #984ea3;
  stroke: #984ea3;
}
.Set1.q4-5 {
  fill: #ff7f00;
  background: #ff7f00;
  stroke: #ff7f00;
}
.Set1.q0-6 {
  fill: #e41a1c;
  background: #e41a1c;
  stroke: #e41a1c;
}
.Set1.q1-6 {
  fill: #377eb8;
  background: #377eb8;
  stroke: #377eb8;
}
.Set1.q2-6 {
  fill: #4daf4a;
  background: #4daf4a;
  stroke: #4daf4a;
}
.Set1.q3-6 {
  fill: #984ea3;
  background: #984ea3;
  stroke: #984ea3;
}
.Set1.q4-6 {
  fill: #ff7f00;
  background: #ff7f00;
  stroke: #ff7f00;
}
.Set1.q5-6 {
  fill: #ffff33;
  background: #ffff33;
  stroke: #ffff33;
}
.Set1.q0-7 {
  fill: #e41a1c;
  background: #e41a1c;
  stroke: #e41a1c;
}
.Set1.q1-7 {
  fill: #377eb8;
  background: #377eb8;
  stroke: #377eb8;
}
.Set1.q2-7 {
  fill: #4daf4a;
  background: #4daf4a;
  stroke: #4daf4a;
}
.Set1.q3-7 {
  fill: #984ea3;
  background: #984ea3;
  stroke: #984ea3;
}
.Set1.q4-7 {
  fill: #ff7f00;
  background: #ff7f00;
  stroke: #ff7f00;
}
.Set1.q5-7 {
  fill: #ffff33;
  background: #ffff33;
  stroke: #ffff33;
}
.Set1.q6-7 {
  fill: #a65628;
  background: #a65628;
  stroke: #a65628;
}
.Set1.q0-8 {
  fill: #e41a1c;
  background: #e41a1c;
  stroke: #e41a1c;
}
.Set1.q1-8 {
  fill: #377eb8;
  background: #377eb8;
  stroke: #377eb8;
}
.Set1.q2-8 {
  fill: #4daf4a;
  background: #4daf4a;
  stroke: #4daf4a;
}
.Set1.q3-8 {
  fill: #984ea3;
  background: #984ea3;
  stroke: #984ea3;
}
.Set1.q4-8 {
  fill: #ff7f00;
  background: #ff7f00;
  stroke: #ff7f00;
}
.Set1.q5-8 {
  fill: #ffff33;
  background: #ffff33;
  stroke: #ffff33;
}
.Set1.q6-8 {
  fill: #a65628;
  background: #a65628;
  stroke: #a65628;
}
.Set1.q7-8 {
  fill: #f781bf;
  background: #f781bf;
  stroke: #f781bf;
}
.Set1.q0-9 {
  fill: #e41a1c;
  background: #e41a1c;
  stroke: #e41a1c;
}
.Set1.q1-9 {
  fill: #377eb8;
  background: #377eb8;
  stroke: #377eb8;
}
.Set1.q2-9 {
  fill: #4daf4a;
  background: #4daf4a;
  stroke: #4daf4a;
}
.Set1.q3-9 {
  fill: #984ea3;
  background: #984ea3;
  stroke: #984ea3;
}
.Set1.q4-9 {
  fill: #ff7f00;
  background: #ff7f00;
  stroke: #ff7f00;
}
.Set1.q5-9 {
  fill: #ffff33;
  background: #ffff33;
  stroke: #ffff33;
}
.Set1.q6-9 {
  fill: #a65628;
  background: #a65628;
  stroke: #a65628;
}
.Set1.q7-9 {
  fill: #f781bf;
  background: #f781bf;
  stroke: #f781bf;
}
.Set1.q8-9 {
  fill: #999999;
  background: #999999;
  stroke: #999999;
}
.Set2.q0-3 {
  fill: #66c2a5;
  background: #66c2a5;
  stroke: #66c2a5;
}
.Set2.q1-3 {
  fill: #fc8d62;
  background: #fc8d62;
  stroke: #fc8d62;
}
.Set2.q2-3 {
  fill: #8da0cb;
  background: #8da0cb;
  stroke: #8da0cb;
}
.Set2.q0-4 {
  fill: #66c2a5;
  background: #66c2a5;
  stroke: #66c2a5;
}
.Set2.q1-4 {
  fill: #fc8d62;
  background: #fc8d62;
  stroke: #fc8d62;
}
.Set2.q2-4 {
  fill: #8da0cb;
  background: #8da0cb;
  stroke: #8da0cb;
}
.Set2.q3-4 {
  fill: #e78ac3;
  background: #e78ac3;
  stroke: #e78ac3;
}
.Set2.q0-5 {
  fill: #66c2a5;
  background: #66c2a5;
  stroke: #66c2a5;
}
.Set2.q1-5 {
  fill: #fc8d62;
  background: #fc8d62;
  stroke: #fc8d62;
}
.Set2.q2-5 {
  fill: #8da0cb;
  background: #8da0cb;
  stroke: #8da0cb;
}
.Set2.q3-5 {
  fill: #e78ac3;
  background: #e78ac3;
  stroke: #e78ac3;
}
.Set2.q4-5 {
  fill: #a6d854;
  background: #a6d854;
  stroke: #a6d854;
}
.Set2.q0-6 {
  fill: #66c2a5;
  background: #66c2a5;
  stroke: #66c2a5;
}
.Set2.q1-6 {
  fill: #fc8d62;
  background: #fc8d62;
  stroke: #fc8d62;
}
.Set2.q2-6 {
  fill: #8da0cb;
  background: #8da0cb;
  stroke: #8da0cb;
}
.Set2.q3-6 {
  fill: #e78ac3;
  background: #e78ac3;
  stroke: #e78ac3;
}
.Set2.q4-6 {
  fill: #a6d854;
  background: #a6d854;
  stroke: #a6d854;
}
.Set2.q5-6 {
  fill: #ffd92f;
  background: #ffd92f;
  stroke: #ffd92f;
}
.Set2.q0-7 {
  fill: #66c2a5;
  background: #66c2a5;
  stroke: #66c2a5;
}
.Set2.q1-7 {
  fill: #fc8d62;
  background: #fc8d62;
  stroke: #fc8d62;
}
.Set2.q2-7 {
  fill: #8da0cb;
  background: #8da0cb;
  stroke: #8da0cb;
}
.Set2.q3-7 {
  fill: #e78ac3;
  background: #e78ac3;
  stroke: #e78ac3;
}
.Set2.q4-7 {
  fill: #a6d854;
  background: #a6d854;
  stroke: #a6d854;
}
.Set2.q5-7 {
  fill: #ffd92f;
  background: #ffd92f;
  stroke: #ffd92f;
}
.Set2.q6-7 {
  fill: #e5c494;
  background: #e5c494;
  stroke: #e5c494;
}
.Set2.q0-8 {
  fill: #66c2a5;
  background: #66c2a5;
  stroke: #66c2a5;
}
.Set2.q1-8 {
  fill: #fc8d62;
  background: #fc8d62;
  stroke: #fc8d62;
}
.Set2.q2-8 {
  fill: #8da0cb;
  background: #8da0cb;
  stroke: #8da0cb;
}
.Set2.q3-8 {
  fill: #e78ac3;
  background: #e78ac3;
  stroke: #e78ac3;
}
.Set2.q4-8 {
  fill: #a6d854;
  background: #a6d854;
  stroke: #a6d854;
}
.Set2.q5-8 {
  fill: #ffd92f;
  background: #ffd92f;
  stroke: #ffd92f;
}
.Set2.q6-8 {
  fill: #e5c494;
  background: #e5c494;
  stroke: #e5c494;
}
.Set2.q7-8 {
  fill: #b3b3b3;
  background: #b3b3b3;
  stroke: #b3b3b3;
}
.Set3.q0-3 {
  fill: #8dd3c7;
  background: #8dd3c7;
  stroke: #8dd3c7;
}
.Set3.q1-3 {
  fill: #ffffb3;
  background: #ffffb3;
  stroke: #ffffb3;
}
.Set3.q2-3 {
  fill: #bebada;
  background: #bebada;
  stroke: #bebada;
}
.Set3.q0-4 {
  fill: #8dd3c7;
  background: #8dd3c7;
  stroke: #8dd3c7;
}
.Set3.q1-4 {
  fill: #ffffb3;
  background: #ffffb3;
  stroke: #ffffb3;
}
.Set3.q2-4 {
  fill: #bebada;
  background: #bebada;
  stroke: #bebada;
}
.Set3.q3-4 {
  fill: #fb8072;
  background: #fb8072;
  stroke: #fb8072;
}
.Set3.q0-5 {
  fill: #8dd3c7;
  background: #8dd3c7;
  stroke: #8dd3c7;
}
.Set3.q1-5 {
  fill: #ffffb3;
  background: #ffffb3;
  stroke: #ffffb3;
}
.Set3.q2-5 {
  fill: #bebada;
  background: #bebada;
  stroke: #bebada;
}
.Set3.q3-5 {
  fill: #fb8072;
  background: #fb8072;
  stroke: #fb8072;
}
.Set3.q4-5 {
  fill: #80b1d3;
  background: #80b1d3;
  stroke: #80b1d3;
}
.Set3.q0-6 {
  fill: #8dd3c7;
  background: #8dd3c7;
  stroke: #8dd3c7;
}
.Set3.q1-6 {
  fill: #ffffb3;
  background: #ffffb3;
  stroke: #ffffb3;
}
.Set3.q2-6 {
  fill: #bebada;
  background: #bebada;
  stroke: #bebada;
}
.Set3.q3-6 {
  fill: #fb8072;
  background: #fb8072;
  stroke: #fb8072;
}
.Set3.q4-6 {
  fill: #80b1d3;
  background: #80b1d3;
  stroke: #80b1d3;
}
.Set3.q5-6 {
  fill: #fdb462;
  background: #fdb462;
  stroke: #fdb462;
}
.Set3.q0-7 {
  fill: #8dd3c7;
  background: #8dd3c7;
  stroke: #8dd3c7;
}
.Set3.q1-7 {
  fill: #ffffb3;
  background: #ffffb3;
  stroke: #ffffb3;
}
.Set3.q2-7 {
  fill: #bebada;
  background: #bebada;
  stroke: #bebada;
}
.Set3.q3-7 {
  fill: #fb8072;
  background: #fb8072;
  stroke: #fb8072;
}
.Set3.q4-7 {
  fill: #80b1d3;
  background: #80b1d3;
  stroke: #80b1d3;
}
.Set3.q5-7 {
  fill: #fdb462;
  background: #fdb462;
  stroke: #fdb462;
}
.Set3.q6-7 {
  fill: #b3de69;
  background: #b3de69;
  stroke: #b3de69;
}
.Set3.q0-8 {
  fill: #8dd3c7;
  background: #8dd3c7;
  stroke: #8dd3c7;
}
.Set3.q1-8 {
  fill: #ffffb3;
  background: #ffffb3;
  stroke: #ffffb3;
}
.Set3.q2-8 {
  fill: #bebada;
  background: #bebada;
  stroke: #bebada;
}
.Set3.q3-8 {
  fill: #fb8072;
  background: #fb8072;
  stroke: #fb8072;
}
.Set3.q4-8 {
  fill: #80b1d3;
  background: #80b1d3;
  stroke: #80b1d3;
}
.Set3.q5-8 {
  fill: #fdb462;
  background: #fdb462;
  stroke: #fdb462;
}
.Set3.q6-8 {
  fill: #b3de69;
  background: #b3de69;
  stroke: #b3de69;
}
.Set3.q7-8 {
  fill: #fccde5;
  background: #fccde5;
  stroke: #fccde5;
}
.Set3.q0-9 {
  fill: #8dd3c7;
  background: #8dd3c7;
  stroke: #8dd3c7;
}
.Set3.q1-9 {
  fill: #ffffb3;
  background: #ffffb3;
  stroke: #ffffb3;
}
.Set3.q2-9 {
  fill: #bebada;
  background: #bebada;
  stroke: #bebada;
}
.Set3.q3-9 {
  fill: #fb8072;
  background: #fb8072;
  stroke: #fb8072;
}
.Set3.q4-9 {
  fill: #80b1d3;
  background: #80b1d3;
  stroke: #80b1d3;
}
.Set3.q5-9 {
  fill: #fdb462;
  background: #fdb462;
  stroke: #fdb462;
}
.Set3.q6-9 {
  fill: #b3de69;
  background: #b3de69;
  stroke: #b3de69;
}
.Set3.q7-9 {
  fill: #fccde5;
  background: #fccde5;
  stroke: #fccde5;
}
.Set3.q8-9 {
  fill: #d9d9d9;
  background: #d9d9d9;
  stroke: #d9d9d9;
}
.Set3.q0-10 {
  fill: #8dd3c7;
  background: #8dd3c7;
  stroke: #8dd3c7;
}
.Set3.q1-10 {
  fill: #ffffb3;
  background: #ffffb3;
  stroke: #ffffb3;
}
.Set3.q2-10 {
  fill: #bebada;
  background: #bebada;
  stroke: #bebada;
}
.Set3.q3-10 {
  fill: #fb8072;
  background: #fb8072;
  stroke: #fb8072;
}
.Set3.q4-10 {
  fill: #80b1d3;
  background: #80b1d3;
  stroke: #80b1d3;
}
.Set3.q5-10 {
  fill: #fdb462;
  background: #fdb462;
  stroke: #fdb462;
}
.Set3.q6-10 {
  fill: #b3de69;
  background: #b3de69;
  stroke: #b3de69;
}
.Set3.q7-10 {
  fill: #fccde5;
  background: #fccde5;
  stroke: #fccde5;
}
.Set3.q8-10 {
  fill: #d9d9d9;
  background: #d9d9d9;
  stroke: #d9d9d9;
}
.Set3.q9-10 {
  fill: #bc80bd;
  background: #bc80bd;
  stroke: #bc80bd;
}
.Set3.q0-11 {
  fill: #8dd3c7;
  background: #8dd3c7;
  stroke: #8dd3c7;
}
.Set3.q1-11 {
  fill: #ffffb3;
  background: #ffffb3;
  stroke: #ffffb3;
}
.Set3.q2-11 {
  fill: #bebada;
  background: #bebada;
  stroke: #bebada;
}
.Set3.q3-11 {
  fill: #fb8072;
  background: #fb8072;
  stroke: #fb8072;
}
.Set3.q4-11 {
  fill: #80b1d3;
  background: #80b1d3;
  stroke: #80b1d3;
}
.Set3.q5-11 {
  fill: #fdb462;
  background: #fdb462;
  stroke: #fdb462;
}
.Set3.q6-11 {
  fill: #b3de69;
  background: #b3de69;
  stroke: #b3de69;
}
.Set3.q7-11 {
  fill: #fccde5;
  background: #fccde5;
  stroke: #fccde5;
}
.Set3.q8-11 {
  fill: #d9d9d9;
  background: #d9d9d9;
  stroke: #d9d9d9;
}
.Set3.q9-11 {
  fill: #bc80bd;
  background: #bc80bd;
  stroke: #bc80bd;
}
.Set3.q10-11 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.Set3.q0-12 {
  fill: #8dd3c7;
  background: #8dd3c7;
  stroke: #8dd3c7;
}
.Set3.q1-12 {
  fill: #ffffb3;
  background: #ffffb3;
  stroke: #ffffb3;
}
.Set3.q2-12 {
  fill: #bebada;
  background: #bebada;
  stroke: #bebada;
}
.Set3.q3-12 {
  fill: #fb8072;
  background: #fb8072;
  stroke: #fb8072;
}
.Set3.q4-12 {
  fill: #80b1d3;
  background: #80b1d3;
  stroke: #80b1d3;
}
.Set3.q5-12 {
  fill: #fdb462;
  background: #fdb462;
  stroke: #fdb462;
}
.Set3.q6-12 {
  fill: #b3de69;
  background: #b3de69;
  stroke: #b3de69;
}
.Set3.q7-12 {
  fill: #fccde5;
  background: #fccde5;
  stroke: #fccde5;
}
.Set3.q8-12 {
  fill: #d9d9d9;
  background: #d9d9d9;
  stroke: #d9d9d9;
}
.Set3.q9-12 {
  fill: #bc80bd;
  background: #bc80bd;
  stroke: #bc80bd;
}
.Set3.q10-12 {
  fill: #ccebc5;
  background: #ccebc5;
  stroke: #ccebc5;
}
.Set3.q11-12 {
  fill: #ffed6f;
  background: #ffed6f;
  stroke: #ffed6f;
}

